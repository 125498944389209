import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import AdminSideNav from "../../component/AdminSideNav";

const Admindashbord = () => {
  const [cookies, ,] = useCookies([]);
  const token = cookies.token;
  const [dashbordData, setdashbordData] = useState([]);
  const navigate = useNavigate();

  const [sDate, setsDate] = useState();
  const [eDate, seteDate] = useState();
  const [chartData, setchartData] = useState([]);
  const [chartData2, setchartData2] = useState([]);
  const [income, setincome] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("TODAY");
  const [memberList, setmemberList] = useState([]);

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getDadhbordData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/dashbord/statuscount`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setdashbordData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getMemberList = () => {
    const data = {};
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/members/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setmemberList(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getRevenueforCharts = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/dashbord/revenuecount/chart`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setchartData2(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
          setchartData2([]);
        }
      });
  };

  useEffect(() => {
    if (token === undefined) {
      navigate("/admin/login");
    } else {
      if (token) {
        const data = {
          dateRange: selectedStatus,
        };
        getDadhbordData(data);
        getMemberList();

        const datas = {};
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/admin/payment/totel`,
            datas,
            config
          )
          .then((res) => {
            if (res.data.status === "success") {
              setincome(res.data.resualt);
            }
            if (res.data.status === "error") {
              toast.error(res.data.message);
            }
          });
      }
    }
  }, [token, navigate, selectedStatus, sDate, eDate]);

  let order = [];

  let label = [];

  chartData.forEach((element) => {
    order.push(element.placed_count);
    label.push(element.order_date);
  });

  const options = {
    chart: {
      type: "column",
    },
    accessibility: {
      description: false,
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: label,
      crosshair: true,
      accessibility: {
        description: "Data",
      },
    },

    yAxis: {
      min: 0,
      title: {
        text: "Count Of Orders",
      },
    },
    title: {
      text: "Total Orders",
      align: "center",
    },

    series: [
      {
        name: "Order",
        data: order,
      },
    ],
  };

  const getData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/dashbord/ordercount/chart`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setchartData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
          setchartData([]);
        }
      });
  };

  useEffect(() => {
    var currentDate = new Date();
    var sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    var start = sevenDaysAgo.toISOString().split("T")[0];
    var end = currentDate.toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);

    if (start && end) {
      const data = {
        startDate: start,
        endDate: end,
      };

      getData(data);
      getRevenueforCharts(data);
    }
  }, []);

  const startDate = (e) => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];

    if (e.target.value > new Date().toISOString().split("T")[0]) {
      setsDate(start);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value > eDate) {
      seteDate(start);
      toast.error("Start date should be before end date");
      return;
    }
    setsDate(e.target.value);
  };

  const endDate = (e) => {
    const end = new Date().toISOString().split("T")[0];
    if (e.target.value > end) {
      seteDate(end);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value < sDate) {
      seteDate(end);
      toast.error("End date should be after start date");
      return;
    }
    seteDate(e.target.value);
  };

  const handelDateSelect = (e) => {
    e.preventDefault();

    const data = {
      startDate: sDate,
      endDate: eDate,
    };

    getData(data);
    getRevenueforCharts(data);
  };

  const this7days = (e) => {
    e.preventDefault();
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];
    const end = new Date().toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  };

  const last7days = (e) => {
    e.preventDefault();
    var currentDate = new Date();
    var lastDate = new Date();
    currentDate.setDate(currentDate.getDate() - 14);
    lastDate.setDate(lastDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];
    const end = lastDate.toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  };

  const this30days = (e) => {
    e.preventDefault();
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 30);

    var start = currentDate.toISOString().split("T")[0];
    const end = new Date().toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  };

  const last30days = (e) => {
    e.preventDefault();
    var currentDate = new Date();
    var startDate = new Date();
    var endDate = new Date();

    startDate.setDate(currentDate.getDate() - 60); // 60 days before today
    endDate.setDate(currentDate.getDate() - 30); // 30 days before today

    var start = startDate.toISOString().split("T")[0];
    var end = endDate.toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  let order2 = [];
  let label2 = [];

  chartData2.forEach((element) => {
    order2.push(+element.total_amount);
    label2.push(element.payment_date);
  });

  const options2 = {
    chart: {
      type: "line",
    },
    accessibility: {
      description: false,
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: label2,
      crosshair: true,
      accessibility: {
        description: "Data",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Count of Revenue",
      },
    },
    title: {
      text: "Total Revenue",
      align: "center",
    },
    series: [
      {
        name: "Revenue",
        data: order2,
      },
    ],
  };

  return (
    <>
      <Box sx={{ display: "flex" }} className="bg-main px-0">
        <AdminSideNav />
        <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
          <Container>
            <Row>
              <div className=" py-5 pe-5 rounded-3 my-4 overflow-auto">
                <div className="card p-3 rounded-5 shadow">
                  <div className="card-body">
                    <Row className="g-3">
                      <Col lg={6}>
                        <h5 className="pb-3">Dashboard</h5>
                      </Col>
                      <Col lg={12}>
                        <h5 className="py-2 m-0 text-center border-bottom">
                          Total Order
                        </h5>
                      </Col>
                      <Col lg={12}>
                        <div className="">
                          <Form.Select
                            size="sm"
                            className="filter-select1 m-0 ms-auto"
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          >
                            <option value="TODAY">Today</option>
                            <option value="YESTERDAY">Yesterday</option>
                            <option value="THISWEEK">This Week</option>
                            <option value="THISMONTH">This Month</option>
                            <option value="THISYEAR">This Year</option>
                          </Form.Select>
                        </div>
                      </Col>

                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-1">
                          <div className="card-body p-4">
                            <h5 className="text-center">Placed</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData && dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData &&
                                dashbordData[0].placed_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].placed_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-4">
                          <div className="card-body p-4">
                            <h5 className="text-center">Printing</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData[0].printing_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].printing_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-5">
                          <div className="card-body p-4">
                            <h5 className="text-center">Packed</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData[0].packed_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].packed_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-3">
                          <div className="card-body p-4">
                            <h5 className="text-center">Dispatched</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData[0].dispatched_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].dispatched_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-2">
                          <div className="card-body p-4">
                            <h5 className="text-center">Delivered</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData[0].delivered_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].delivered_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-6">
                          <div className="card-body p-4">
                            <h5 className="text-center">Cancelled</h5>
                            <p className="m-0 text-center fs-3">
                              {dashbordData[0] === undefined ? (
                                <>0</>
                              ) : dashbordData[0].cancelled_count === null ? (
                                <>0</>
                              ) : (
                                dashbordData[0].cancelled_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <h5 className="py-3 m-0 text-center border-bottom">
                          Total Revenue
                        </h5>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-1">
                          <div className="card-body p-4">
                            <h5 className="text-center">Today</h5>
                            <p className="m-0 text-center fs-3">
                              ₹ {income === undefined ? <> 0</> : income.today}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-4">
                          <div className="card-body p-4">
                            <h5 className="text-center">Yesterday</h5>
                            <p className="m-0 text-center fs-3">
                              ₹{" "}
                              {income === undefined ? <>0</> : income.yesterday}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-5">
                          <div className="card-body p-4">
                            <h5 className="text-center">This Week</h5>
                            <p className="m-0 text-center fs-3">
                              ₹{" "}
                              {income === undefined ? <>0</> : income.thisweek}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-3">
                          <div className="card-body p-4">
                            <h5 className="text-center">This Month</h5>
                            <p className="m-0 text-center fs-3">
                              ₹{" "}
                              {income === undefined ? <>0</> : income.thismonth}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-2">
                          <div className="card-body p-4">
                            <h5 className="text-center">This Year</h5>
                            <p className="m-0 text-center fs-3">
                              ₹{" "}
                              {income === undefined ? <>0</> : income.thisyear}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-6">
                          <div className="card-body p-4">
                            <h5 className="text-center">Till Now</h5>
                            <p className="m-0 text-center fs-3">
                              ₹{" "}
                              {income === undefined ? <>0</> : income.networth}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <h5 className="py-3 m-0 text-center border-bottom">
                          Total Members
                        </h5>
                      </Col>
                      <Col lg={4} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-1">
                          <div className="card-body p-4">
                            <h5 className="text-center">Users</h5>
                            <p className="m-0 text-center fs-3">
                              {memberList[0] === undefined ? (
                                <> 0</>
                              ) : (
                                memberList[0].user_count
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-3">
                          <div className="card-body p-4">
                            <h5 className="text-center">Vendors</h5>
                            <p className="m-0 text-center fs-3">
                              {memberList[0] === undefined ? (
                                <>
                                  0 /<span className="text-black"> 0</span>
                                </>
                              ) : (
                                <>
                                  {memberList[0].vendor_count_active} /
                                  <span className="text-dark">
                                    {" "}
                                    {memberList[0].vendor_count_inactive}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={3} sm={6} xs={12}>
                        <div className="card border-0 rounded-4 shadow b-4">
                          <div className="card-body p-4">
                            <h5 className="text-center">Riders</h5>
                            <p className="m-0 text-center fs-3">
                              {memberList[0] === undefined ? (
                                <>
                                  0 /<span className="text-black"> 0</span>
                                </>
                              ) : (
                                <>
                                  {memberList[0].rider_count_active} /{" "}
                                  <span className="text-black">
                                    {" "}
                                    {memberList[0].rider_count_inactive}
                                  </span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12}>
                        <h5 className="py-2 m-0 text-center border-bottom">
                          Order History
                        </h5>
                      </Col>
                      {/* <Col lg={6} className="py-4">
                        <div className="w-50 ms-auto text-end">
                          <div className="w-100 text-start">
                            <Button
                              variant="text"
                              onClick={(e) => this7days(e)}
                            >
                              This 7 Days
                            </Button>
                          </div>
                          <div className="w-100 text-start">
                            <Button
                              variant="text"
                              onClick={(e) => last7days(e)}
                            >
                              Last 7 Days
                            </Button>
                          </div>
                          <div className="w-100 text-start">
                            <Button
                              variant="text"
                              onClick={(e) => this30days(e)}
                            >
                              This 30 Days
                            </Button>
                          </div>
                          <div className="w-100 text-start">
                            <Button
                              variant="text"
                              onClick={(e) => last30days(e)}
                            >
                              Last 30 Days
                            </Button>
                          </div>
                        </div>
                      </Col> */}
                      <Col lg={12} className="py-2">
                        <div>
                          <form
                            className="d-md-flex justify-content-around"
                            onSubmit={(e) => handelDateSelect(e)}
                          >
                            <div className="date-box">
                              <label htmlFor="startDate">Start Date :</label>
                              <input
                                type="date"
                                name="startDate"
                                value={sDate || ""}
                                className="datepicker-input"
                                onChange={(e) => startDate(e)}
                                id=""
                              />
                            </div>

                            <div className="date-box">
                              <label htmlFor="startDate">End Date :</label>
                              <input
                                type="date"
                                name="endDare"
                                value={eDate || ""}
                                onChange={(e) => endDate(e)}
                                id=""
                              />
                            </div>
                            <div className="date-box">
                              <Button
                                variant="contained"
                                className="bg-primary px-4"
                                type="submit"
                              >
                                Search
                              </Button>
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={6} className="p-0">
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                          />
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={6} className="p-0">
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options2}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Admindashbord;
