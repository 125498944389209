import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import MiniDrawer from "../../component/MiniDrawer";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Tooltip } from "@mui/material";

const headers = [
  { label: "Payment Date", key: "date" },
  { label: "Order Amount", key: "order_price" },
  { label: "Gateway Charge", key: "gatewayCharge" },
  { label: "Platform Charge", key: "platformCharge" },
  { label: "Delivery Charges", key: "deliveryCharges" },
  { label: "Total Payment", key: "total_payment" },
];

const VendorPayments = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const token = cookies.token;
  const [orders, setOrders] = useState([]);

  const [len, setlen] = useState(0);
  const [history, sethistory] = useState([]);
  const [sDate, setsDate] = useState();
  const [eDate, seteDate] = useState();

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };
  useEffect(() => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];
    const end = new Date().toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  }, []);

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/payments/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrders(res.data.data);
          setlen(res.data.len);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getOrderhistory = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/payment/history`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          sethistory(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    if (sDate && eDate) {
      const data = {
        sDate,
        eDate,
      };
      getOrderData(data);

      const request = {
        sDate,
        eDate,
      };
      getOrderhistory(request);
    }
  }, [token, navigate, sDate, eDate]);

  const startDate = (e) => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];

    if (e.target.value > new Date().toISOString().split("T")[0]) {
      setsDate(start);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value > eDate) {
      seteDate(start);
      toast.error("Start date should be before end date");
      return;
    }
    setsDate(e.target.value);
  };

  const endDate = (e) => {
    const end = new Date().toISOString().split("T")[0];
    if (e.target.value > end) {
      seteDate(end);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value < sDate) {
      seteDate(end);
      toast.error("End date should be after start date");
      return;
    }
    seteDate(e.target.value);
  };

  return (
    <div className="w-100 bg-main px-0">
      <MiniDrawer />
      <Box className="p-0 ps-4 ps-md-5">
        <Container className="ps-5">
          <Row>
            <div className=" rounded-3">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <h5 className="m-0 pb-3">Payment History</h5>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-1 ms-auto">
                          <div className="m-2 w-100 ms-0 ms-md-auto">
                            {history === undefined ? (
                              <></>
                            ) : history[0]?.order_total_amount === 0 ? (
                              <></>
                            ) : (
                              <>
                                <CSVLink
                                  data={history}
                                  headers={headers}
                                  filename={`xeroxmart-payment-history from ${sDate} to ${eDate}.csv`}
                                  className="text-decoration-none text-white bg-primary px-3 py-2 rounded-1 shadow-sm ms-2"
                                >
                                  Export Payment History
                                </CSVLink>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="w-100 text-center mb-3">
                    <form>
                      <p>Select Date Range</p>
                      <input
                        type="date"
                        name="startDate"
                        value={sDate || ""}
                        className="daterangePick w-100 w-md-25"
                        onChange={(e) => startDate(e)}
                        id=""
                      />

                      <input
                        type="date"
                        name="endDare"
                        value={eDate || ""}
                        className="daterangePick w-100 w-md-25"
                        onChange={(e) => endDate(e)}
                        id=""
                      />
                    </form>
                  </div>
                  {orders[0] === undefined ? (
                    <h6 className="m-0 text-center">No History Found</h6>
                  ) : (
                    <div className="overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="ps-3">Payment Date</th>
                            <th className="text-end">Order Amount</th>
                            <th className="text-end">Gateway Charge</th>
                            <th className="text-end">Platform Charge</th>
                            <th className="text-end">Delivery Charges</th>
                            <th className="text-end">Total Payment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((val, ind) => (
                            <tr key={ind}>
                              <td>{ind + 1}</td>
                              <td className="ps-3">{val.date}</td>
                              <td className="text-end">₹ {val.order_price}</td>
                              <td className="text-end">
                                ₹ {val.gatewayCharge}
                              </td>
                              <td className="text-end">
                                ₹ {val.platformCharge}
                              </td>
                              <td className="text-end">
                                ₹ {val.deliveryCharges}
                              </td>
                              <td className="text-end">
                                ₹ {val.total_payment}
                              </td>
                              <td className="text-center">
                                <Tooltip title="View Details">
                                  <RemoveRedEyeRoundedIcon
                                    onClick={() =>
                                      navigate(`/vendor/payments/${val.date}`)
                                    }
                                  />
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </div>
  );
};

export default VendorPayments;
