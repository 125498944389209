import { Button } from "@mui/material";
import React from "react";

const Loadtest = () => {
  const testingFunc = (e) => {
    e.preventDefault();

    for (let index = 0; index < 1000; index++) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        user_mobile: "9033701080",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("http://localhost:4000/", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }
  };
  return (
    <>
      <Button variant="contained" onClick={(e) => testingFunc(e)}>
        Run func
      </Button>
    </>
  );
};

export default Loadtest;
