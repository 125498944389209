import React, { useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useCookies } from "react-cookie";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminUpdatePassword = () => {
  const [cookies, ,] = useCookies([]);
  const navigate = useNavigate();
  const params = useParams();
  const rider_id = params.id;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newPasswordErrMessage, setNewPasswordErrMessage] = useState("");
  const [passwordErrMessage, setPasswordErrMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setNewPasswordErrMessage("Password should have minimum 8 characters");
      return;
    } else {
      setNewPasswordErrMessage("");
    }
    if (confirmPassword.length < 8) {
      setPasswordErrMessage("Password should have minimum 8 characters");
      return;
    } else {
      setPasswordErrMessage("");
    }

    if (password !== confirmPassword) {
      setPasswordErrMessage("Passwords do not match");
      setNewPasswordErrMessage("Passwords do not match");
      return;
    } else {
      setPasswordErrMessage("");
      setNewPasswordErrMessage("");
    }

    const data = {
      rider_id: rider_id,
      newPassword: password,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/rider/update/password`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Password updated successfully");
          navigate("/admin/rider/list");
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }} className="bg-main px-0">
        <AdminSideNav />
        <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
          <Container>
            <Row>
              <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
                <div className="card p-3 rounded-5 shadow">
                  <div className="card-body">
                    <div>
                      <IconButton
                        aria-label="go back"
                        size="large"
                        color="warning"
                        onClick={() => navigate("/admin/rider/list")}
                      >
                        <KeyboardBackspaceIcon />
                      </IconButton>
                    </div>

                    <h6 className="text-center fs-5 pb-1">
                      Please Enter New Password for Rider
                    </h6>
                    <form onSubmit={handleSubmit}>
                      <Row className="g-3">
                        <Col lg={6}>
                          <label className="fs-xs" htmlFor="newpassword">
                            New Password *
                          </label>
                          <TextField
                            placeholder="New Password"
                            variant="standard"
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!newPasswordErrMessage}
                            helperText={newPasswordErrMessage}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                          />
                        </Col>
                        <Col lg={6}>
                          <label className="fs-xs" htmlFor="confirmpassword">
                            Confirm Password *
                          </label>
                          <TextField
                            placeholder="Confirm Password"
                            variant="standard"
                            fullWidth
                            type={showPassword2 ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!!passwordErrMessage}
                            helperText={passwordErrMessage}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword2 ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                          />
                        </Col>
                      </Row>
                      <div className="text-end pt-3">
                        <Button
                          variant="contained"
                          className="px-4 bg-primary me-1"
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          variant="contained"
                          className="px-4 text-black ms-1"
                          type="button"
                          color="inherit"
                          onClick={() => navigate("/admin/rider/list")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminUpdatePassword;
