import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pdficon from "../assets/images/pdf-icon.png";
import CustomizedSteppers from "../component/CustomizedSteppers";
import { toast } from "react-toastify";
import Navigationbar from "../component/Navigationbar";
import { Container } from "react-bootstrap";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import jsPDF from "jspdf";
import logo from "../assets/images/logo.png";
import ReactGA from "react-ga4";

const OrderDetails = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/account/orders/details",
    title: "Order Details page",
  });

  const navigate = useNavigate();
  const params = useParams();
  const [cookies, , removeCookie] = useCookies();
  const [orderData, setOrderData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const [dis, setdis] = useState(false);
  const [stepss, setstepss] = useState();
  const [mrp, setmrp] = useState();
  const [date, setdate] = useState();
  const [price, setprice] = useState();

  const oId = params.id;
  const token = cookies.token;

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    if (oId) {
      const data = {
        orderid: oId,
      };

      getOrderData(data);
    }
  }, [token, oId]);

  const getOrderData = async (data) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/order/detail`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrderData(res.data.data);
          setaddressData(res.data.data[0].address_detail);

          let orderDate = "";

          if (res.data.data[0].order_delivery_type === "STORE") {
            orderDate =
              res.data.data[0].event_data[3]?.event_created_at.split(" ")[0];
          } else {
            orderDate =
              res.data.data[0].event_data[3]?.event_created_at.split(" ")[0];
          }

          setdate(orderDate);

          const currentStatus = res.data.data[0]?.order_status;
          const steps = [];

          if (res.data.data[0].order_delivery_type === "STORE") {
            if (res.data.data[0].order_status === "CANCELLED") {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.data[0].event_data[0]?.event_created_at,
                },
                {
                  step: `CANCELLED`,
                  date: res.data.data[0].event_data[1]?.event_created_at,
                }
              );
            } else {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.data[0].event_data[0]?.event_created_at,
                },
                {
                  step: `PRINTING`,
                  date: res.data.data[0].event_data[1]?.event_created_at,
                },
                {
                  step: `PACKED`,
                  date: res.data.data[0].event_data[2]?.event_created_at,
                },
                {
                  step: `PICKEDUP`,
                  date: res.data.data[0].event_data[3]?.event_created_at,
                }
              );
            }
          } else {
            if (res.data.data[0].order_status === "CANCELLED") {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.data[0].event_data[0]?.event_created_at,
                },
                {
                  step: `CANCELLED`,
                  date: res.data.data[0].event_data[1]?.event_created_at,
                }
              );
            } else {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.data[0].event_data[0]?.event_created_at,
                },
                {
                  step: `PRINTING`,
                  date: res.data.data[0].event_data[1]?.event_created_at,
                },
                {
                  step: `PACKED`,
                  date: res.data.data[0].event_data[2]?.event_created_at,
                },
                {
                  step: `DISPATCHED`,
                  date: res.data.data[0].event_data[3]?.event_created_at,
                },
                {
                  step: `DELIVERED`,
                  date: res.data.data[0].event_data[4]?.event_created_at,
                }
              );
            }
          }

          setstepss(steps);

          const stepIndex = steps.findIndex(
            (step) => step.step === currentStatus
          );
          if (stepIndex !== -1) {
            setActiveStep(stepIndex);
          }
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
          navigate("/account/orders");
        }
      });
  };

  const handelLogout = (e) => {
    e.preventDefault();
    setdis(true);
    toast(<Msg />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const Msg = () => (
    <>
      <div className="toast-body">
        Are you sure want to Logout ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={(e) => setFixDelete(e)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => setCancelDelete(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  const setFixDelete = (e) => {
    e.preventDefault();
    removeCookie("token");
    removeCookie("authRole");
    navigate("/");
  };

  const setCancelDelete = (e) => {
    setAutoCloseToast(100);
    setdis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const Msg2 = () => (
    <>
      <div className="toast-body">
        Are you sure want to Cancel Order ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={(e) => setFixDelete2(e)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => setCancelDelete2(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  const setFixDelete2 = (e) => {
    e.preventDefault();
    const data = {
      // userId: dData.user_id,
      orderId: oId,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/order/cancel`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          const data = {
            orderid: oId,
            // userid: dData.user_id,
          };
          getOrderData(data);
          setAutoCloseToast(100);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const setCancelDelete2 = (e) => {
    setAutoCloseToast(100);
    setdis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const handelCancelOrder = (e) => {
    e.preventDefault();

    setdis(true);
    toast(<Msg2 />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    if (orderData[0] !== undefined) {
      const totalamount =
        orderData[0].order_total_amount - orderData[0].order_handling_charges;

      setmrp(totalamount - orderData[0].order_delivery_charges);
      const mrp = totalamount - orderData[0].order_delivery_charges;

      const orderPrice =
        mrp / orderData[0].order_pages / orderData[0].order_copies_count;
      setprice(orderPrice);
    }
  }, [orderData]);

  const hancelDownloadPdf = (e) => {
    e.preventDefault();

    const doc = new jsPDF();
    doc.addImage(logo, "PNG", 75, 5, 60, 9.6);

    orderData &&
      orderData.forEach((element) => {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Sold By :", 10, 35);
        doc.setFontSize(13);
        doc.text(`${element.vendor_shop_name},`, 10, 40);
        doc.setFont("helvetica", "normal");
        doc.text(`${element.vendor_shop_address},`, 10, 45);
        doc.text(`${element.pincode_number}.`, 10, 50);

        if (element.order_delivery_type === "HOME") {
          doc.setFontSize(14);
          doc.setFont("helvetica", "bold");
          doc.text("Billing Address :", 200, 35, { align: "right" });
          doc.setFontSize(13);
          doc.text(
            `${addressData[0].address_name} (${addressData[0].address_number})`,
            200,
            40,
            { align: "right" }
          );
          doc.setFont("helvetica", "normal");
          doc.text(`${addressData[0].address_line}`, 200, 45, {
            align: "right",
          });
          doc.text(`${addressData[0].address_landmark}`, 200, 50, {
            align: "right",
          });
          doc.text(`${addressData[0].address_pincode}.`, 200, 55, {
            align: "right",
          });
        }

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Date :", 175, 25, { align: "right" });
        doc.setFont("helvetica", "normal");
        doc.setFontSize(13);
        doc.text(`${date && date}`, 200, 25, { align: "right" });

        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Payment Detail :", 10, 160);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(13);
        doc.text(`Paid Online #${element.order_payment_ref}`, 51, 160);

        doc.setFillColor(163, 162, 162);

        doc.rect(10, 100, 190, 7, "F");
        doc.rect(169, 144, 31, 7, "F");

        doc.setFillColor(0, 0, 0);

        doc.line(10, 100, 200, 100);
        doc.line(10, 107, 200, 107);

        doc.line(10, 100, 10, 151);
        doc.line(29, 100, 29, 130);
        doc.line(139, 100, 139, 130);
        doc.line(154, 100, 154, 130);
        doc.line(169, 100, 169, 151);
        doc.line(200, 100, 200, 151);

        doc.line(10, 130, 200, 130);
        doc.line(10, 137, 200, 137);
        doc.line(10, 144, 200, 144);
        doc.line(10, 151, 200, 151);

        doc.text("SI. NO.", 12, 105);
        doc.text("Description.", 30, 105);
        doc.text("Qty", 140, 105);
        doc.text("Price", 155, 105);
        doc.text("Total Amount", 170, 105);

        doc.setFontSize(12);

        doc.text("1.", 12, 112);
        doc.text(
          `${
            element.order_print_type === "XEROX"
              ? "Black & White"
              : element.order_print_type === "COLOUR"
              ? "Colour"
              : "Sticker"
          } Copy, (${element.order_pages} Page)`,
          31,
          112
        );
        doc.text(`${element.order_copies_count}`, 153, 112, { align: "right" });
        doc.text(`${price && price}`, 168, 112, { align: "right" });
        doc.text(`Rs. ${mrp && mrp}`, 199, 112, { align: "right" });

        doc.setFontSize(13);

        doc.text("Delivery Charge", 11, 135);
        doc.text(`Rs. ${element.order_delivery_charges}`, 199, 135, {
          align: "right",
        });

        doc.text("Handling Charge", 11, 142);
        doc.text(`Rs. ${element.order_handling_charges}`, 199, 142, {
          align: "right",
        });

        doc.text(`Total`, 11, 149);
        doc.text(`Rs. ${element.order_total_amount}/-`, 199, 149, {
          align: "right",
        });
      });

    doc.save(`Invoice ${oId}.pdf`);
  };

  return (
    <>
      <Navigationbar />
      <Container className="p-0 shadow mt-3">
        <Row className="p-0 m-0">
          <Col
            lg={3}
            className="col-md-4 col-sm-12 p-0 m-0 border-end account-manu"
          >
            <div>
              <ul className="ul">
                <li
                  className="li border-bottom "
                  onClick={() => navigate("/account/address")}
                >
                  <Link className="link text-secondary ">My Address</Link>
                </li>
                <li
                  className="li border-bottom ali"
                  onClick={() => navigate("/account/orders")}
                >
                  <Link className="link text-secondary aLink">My Orders</Link>
                </li>
                <li
                  className="li border-bottom"
                  onClick={dis === true ? undefined : (e) => handelLogout(e)}
                >
                  <Link className="text-decoration-none text-secondary">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={9} className="col-md-8 col-sm-12">
            <Row className="g-4 p-4">
              <div className="col-6">
                <button
                  className="goback"
                  onClick={() => navigate("/account/orders")}
                >
                  <ArrowBackIcon />
                </button>
              </div>
              <div className="col-6 text-end">
                {activeStep === 0 ? (
                  <>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      className="fs-xs mx-1 my-1 my-sm-0"
                      onClick={(e) =>
                        handelCancelOrder(e, orderData[0].order_id)
                      }
                      disabled={dis === true ? true : false}
                    >
                      cancel order
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {orderData.length !== 0 ? (
                  orderData && orderData[0].order_status === "PICKEDUP" ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        className=" bg-info fs-xs mx-1 my-1 my-sm-0"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={(e) => hancelDownloadPdf(e)}
                      >
                        download invoice
                      </Button>
                    </>
                  ) : orderData && orderData[0].order_status === "DELIVERED" ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        className=" bg-info fs-xs mx-1 my-1 my-sm-0"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={(e) => hancelDownloadPdf(e)}
                      >
                        download invoice
                      </Button>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>

              {orderData.length !== 0 ? (
                <>
                  <div className="col-12">
                    <h5>Order Summary</h5>
                    <Row className="py-3">
                      <Col xs={1} className="p-0 ps-md-3">
                        <div className="text-end">
                          <img
                            alt=""
                            src={pdficon}
                            className="img-fluid w-100 w-lg-50"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={11}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <p className="m-0" style={{ fontSize: "14px" }}>
                            {orderData && orderData[0].order_print_type} |
                            Single Side |{" "}
                            {orderData && orderData[0].order_orientation_type}
                          </p>
                          <p className="m-0 fs-xs text-secondary">
                            {orderData && orderData[0].order_pages} pc x{" "}
                            {orderData && orderData[0].order_copies_count}
                          </p>
                        </div>
                        <div>
                          <h6 className="m0">
                            ₹{orderData && orderData[0].order_total_amount}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <div className="overflow-auto py-3">
                      <CustomizedSteppers
                        activeStep={activeStep}
                        steps={stepss}
                      />
                    </div>
                    <div className="py-3">
                      <h6>Bill Details</h6>
                      <hr className="my-2" />
                      <Row>
                        <div className="col-6">
                          <p className="m-0">MRP</p>
                        </div>
                        <div className="col-6">
                          <p className="text-end m-0">₹{mrp}</p>
                        </div>
                        <div className="col-6">
                          <p className="m-0">Delivery Charge</p>
                        </div>
                        <div className="col-6">
                          {orderData &&
                          orderData[0].order_delivery_charges === 0 ? (
                            <>
                              <p className="text-end m-0 green">FREE</p>
                            </>
                          ) : (
                            <>
                              <p className="text-end m-0">
                                ₹
                                {orderData &&
                                  orderData[0].order_delivery_charges}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="col-6">
                          <p className="m-0">Handling Charge</p>
                        </div>
                        <div className="col-6">
                          <p className="text-end m-0">
                            ₹{orderData && orderData[0].order_handling_charges}
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="m-0 fw-bold">Bill Total</p>
                        </div>
                        <div className="col-6">
                          <p className="text-end m-0 fw-bold">
                            ₹{orderData && orderData[0].order_total_amount}
                          </p>
                        </div>
                      </Row>
                    </div>

                    {orderData &&
                    orderData[0].order_delivery_type === "STORE" ? (
                      <>
                        <div className="py-3">
                          <h6>Vendor Details</h6>
                          <hr className="my-2" />
                          <Row className="g-2">
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Vendor Name
                              </p>
                              <p className=" m-0 ">
                                {orderData && orderData[0].vendor_name}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Shop Name
                              </p>
                              <p className=" m-0 ">
                                {orderData && orderData[0].vendor_shop_name}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Shop Address {orderData[0].order_status}
                              </p>
                              <p className=" m-0 ">
                                {orderData && orderData[0].vendor_shop_address}
                              </p>
                            </div>
                          </Row>
                        </div>
                        <div className="py-3">
                          <h6>Order Details</h6>
                          <hr className="my-2" />
                          <Row className="g-2">
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Order Id
                              </p>
                              <p className=" m-0 ">
                                #{orderData && orderData[0].order_id}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Payment Detail
                              </p>
                              <p className=" m-0 ">
                                Paid Online{" "}
                                <span className="ps-2 user-select-none">
                                  {" "}
                                  #{orderData && orderData[0].order_payment_ref}
                                </span>
                              </p>
                            </div>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="py-3">
                          <h6>Order Details</h6>
                          <hr className="my-2" />
                          <Row className="g-2">
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Order Id
                              </p>
                              <p className=" m-0 ">
                                #{orderData && orderData[0].order_id}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                Payment Detail
                              </p>
                              <p className=" m-0 ">
                                Paid Online{" "}
                                <span className="ps-2 user-select-none">
                                  {" "}
                                  #{orderData && orderData[0].order_payment_ref}
                                </span>
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-secondary fs-xs m-0">
                                {orderData[0].order_status === "PICKEDUP" ? (
                                  <>Delivered To</>
                                ) : orderData[0].order_status ===
                                  "DELIVERED" ? (
                                  <>Delivered To</>
                                ) : (
                                  <>Deliver To</>
                                )}
                              </p>
                              <p className=" m-0 ">
                                {addressData && addressData.address_line},{" "}
                                {addressData && addressData.address_landmark},{" "}
                                {addressData && addressData.address_pincode}
                              </p>
                            </div>
                          </Row>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetails;
