import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import pdficon from "../assets/images/pdf-icon.png";
import imgicon from "../assets/images/img-icon.png";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "@mui/material";
import bnwimg from "../assets/images/bnw_print.png";
import colorimg from "../assets/images/color_print.png";
import portraitimg from "../assets/images/potrait_print.png";
import landscapeimg from "../assets/images/landscape_print.png";
import printweb from "../assets/images/print-web.png";
import { toast } from "react-toastify";
import Navigationbar from "../component/Navigationbar";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import Footer from "../component/Footer";
import ReactGA from "react-ga4";

const Addpage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/preview",
    title: "Preview page",
  });

  const [cookies, ,] = useCookies();
  const token = cookies.temp_uuid;
  const navigate = useNavigate();

  const [xeroxPrice, setxeroxPrice] = useState();
  const [colourPrice, setcolourPrice] = useState();
  const [stickerPrice, setstickerPrice] = useState();
  const [handlingPrice, sethandlingPrice] = useState();
  const [deliveryPrice, setdeliveryPrice] = useState();
  const [filedata, setfiledata] = useState([]);
  const [numofpages, setnumofpages] = useState(1);
  const [printcolour, setprintcolour] = useState("B&W");
  const [printorientation, setprintorientation] = useState("PORTRAIT");
  const [numofcopy, setnumofcopy] = useState(1);
  const [printcolourrs, setprintcolourrs] = useState();

  const [bnwselect, setbnwselect] = useState(true);
  const [colorselect, setcolorselect] = useState(false);
  const [stickerselect, setstickerselect] = useState(false);
  const [porselect, setporselect] = useState(true);
  const [landselect, setlandselect] = useState(false);
  const [loading, setloading] = useState(false);
  const [stickeroption, setstickeroption] = useState(false);
  const [threebyfour, setthreebyfour] = useState(true);
  const [fourbysix, setfourbysix] = useState(false);
  const [service, setservice] = useState();
  const [dis, setdis] = useState(false);

  const getfiles = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/user/files/get`, data)
      .then((res) => {
        if (res.data.status === "success") {
          setfiledata(res.data.files);
          setnumofpages(res.data.numPages);
          if (res.data.files.length === 0) {
            navigate("/");
            setloading(false);
          }
        }
        if (res.data.status === "error") {
          navigate("/");
        }
      })
      .catch((e) => console.log(e));
  };

  const getVendorService = async (data) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/user/vendor/service`,
        data
      );

      if (res.data.status === "success") {
        if (res.data.data === undefined) {
          navigate("/");
          toast.error("Sorry, No vendor online at this time.");
          return { status: "error" };
        } else {
          let xerox1 = 0;
          let colour1 = 0;
          let sticker1 = 0;

          res.data.data.forEach((element) => {
            if (element.xerox === "Y") {
              xerox1 += 1;
            }
            if (element.colour === "Y") {
              colour1 += 1;
            }
            if (element.sticker === "Y") {
              sticker1 += 1;
            }
          });

          let arr = [xerox1, colour1, sticker1];

          setservice(arr);

          // Set prices
          setxeroxPrice(res.data.data[0].xerox_cost);
          setcolourPrice(res.data.data[0].color_cost);
          setstickerPrice(res.data.data[0].sticker_cost);
          sethandlingPrice(res.data.data[0].handling_cost);
          setdeliveryPrice(res.data.data[0].delivery_cost);
          setprintcolourrs(res.data.data[0].xerox_cost);

          return { status: "success" };
        }
      } else {
        toast.error(res.data.message);
        return { status: "error" };
      }
    } catch (error) {
      console.error("Error fetching vendor service:", error);
      toast.error("An error occurred while fetching vendor service.");
      return { status: "error" };
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("Xeroxmartpincode");
    const myobj = JSON.parse(data);

    if (myobj !== undefined) {
      const data = { pincode: myobj };
      getVendorService(data);
    }

    //  const intervalId = setInterval(async () => {
    //    const data = localStorage.getItem("Xeroxmartpincode");
    //    const myobj = JSON.parse(data);

    //    if (myobj !== undefined) {
    //      const data = { pincode: myobj };
    //      const vendorservice = await getVendorService(data);

    //      if (vendorservice.status === "error") {
    //        clearInterval(intervalId);
    //      }
    //    }
    //  }, 5000);

    //  return () => clearInterval(intervalId);
  }, []);

  window.addEventListener("pincode-updated", (event) => {
    const data = localStorage.getItem("Xeroxmartpincode");
    var myobj = JSON.parse(data);
    if (myobj !== undefined) {
      const data = {
        pincode: myobj,
      };
      getVendorService(data);
    }
  });

  useEffect(() => {
    if (token !== undefined) {
      const data = {
        uuid: token,
      };
      getfiles(data);
    } else {
      navigate("/");
    }

    if (service !== undefined) {
      if (service[0] === 0 && service[1] === 0 && service[2] > 0) {
        setcolorselect(false);
        setbnwselect(false);
        setstickerselect(true);
        setprintcolourrs(stickerPrice);
        setprintcolour("STICKER");
        setprintorientation("3x5");
        setstickeroption(true);
      }

      if (service[0] === 0 && service[1] > 0) {
        setcolorselect(true);
        setbnwselect(false);
        setstickerselect(false);
        setprintcolour("COLOUR");
        setprintcolourrs(colourPrice);
        setprintorientation("PORTRAIT");
        setporselect(true);
        setlandselect(false);
        setprintorientation("PORTRAIT");
        setstickeroption(false);
      }

      if (service[0] === 0 && service[1] === 0 && service[2] === 0) {
        setdis(true);
      } else {
        setdis(false);
      }
    }
  }, [token, navigate, service]);

  const isImageFile = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    if (ext !== "pdf") {
      const data = {
        uuid: token,
        filename: filename,
      };
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/user/files/delete`, data)
        .then((res) => {
          if (res.data.status === "success") {
            const gdata = {
              uuid: token,
            };
            getfiles(gdata);
          }
          toast.error("File should be pdf only");
        })
        .catch((e) => console.log(e));
    }
    return ["pdf"].includes(ext);
  };

  const deletefile = (e, name) => {
    e.preventDefault();
    const data = {
      uuid: token,
      filename: name,
    };
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/user/files/delete`, data)
      .then((res) => {
        if (res.data.status === "success") {
          const gdata = {
            uuid: token,
          };
          getfiles(gdata);
          localStorage.removeItem("XeroxmartCart");
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.log(e));
  };

  // counting

  const handeladdcopy = (e) => {
    e.preventDefault();
    setnumofcopy(numofcopy + 1);
  };

  const handelremovecopy = (e) => {
    e.preventDefault();
    if (numofcopy > 1) {
      setnumofcopy(numofcopy - 1);
    }
  };

  const handelchangeselectbnw = (e) => {
    e.preventDefault();
    setbnwselect(true);
    setcolorselect(false);
    setstickerselect(false);
    setprintcolour("XEROX");
    setprintcolourrs(xeroxPrice);
    setprintorientation("PORTRAIT");
    handelchangeselectprot(e);
    setstickeroption(false);
  };
  const handelchangeselectcolor = (e) => {
    e.preventDefault();
    setcolorselect(true);
    setbnwselect(false);
    setstickerselect(false);
    setprintcolour("COLOUR");
    setprintcolourrs(colourPrice);
    setprintorientation("PORTRAIT");
    handelchangeselectprot(e);
    setstickeroption(false);
  };
  const handelchangeselectsticker = (e) => {
    e.preventDefault();
    setcolorselect(false);
    setbnwselect(false);
    setstickerselect(true);
    setprintcolourrs(stickerPrice);
    setprintcolour("STICKER");
    setprintorientation("3x5");
    setstickeroption(true);
  };
  const handelchangeselectprot = (e) => {
    e.preventDefault();
    setporselect(true);
    setlandselect(false);
    setprintorientation("PORTRAIT");
  };
  const handelchangeselectland = (e) => {
    e.preventDefault();
    setlandselect(true);
    setporselect(false);
    setprintorientation("LANDSCAPE");
  };

  const handelchangese3x4 = (e) => {
    e.preventDefault();
    setthreebyfour(true);
    setfourbysix(false);
    setprintorientation("3x5");
  };

  const handelchangese4x6 = (e) => {
    e.preventDefault();
    setthreebyfour(false);
    setfourbysix(true);
    setprintorientation("4x6");
  };

  const totalRs = numofpages * printcolourrs * numofcopy + handlingPrice;

  const addToCart = (e) => {
    e.preventDefault();
    if (totalRs <= 499950) {
      const cartData = {
        fileName: filedata[0],
        numofpages,
        printcolour: printcolour,
        printorientation,
        numofcopy,
        totalRs,
        handlingPrice,
        deliveryPrice,
      };

      localStorage.setItem("XeroxmartCart", JSON.stringify(cartData));
      window.dispatchEvent(new CustomEvent("count-updated"));
    } else {
      toast.error("You have reached maximum limit of amount.");
    }
  };

  useEffect(() => {
    if (loading === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [loading]);

  return (
    <>
      <Navigationbar />
      <Container fluid className="bg-main-1">
        <Container>
          <h3 className="text-center pt-3">Printout Preview </h3>
          <hr />
          <div>
            <Row className="g-4">
              {filedata.map((val, ind) => (
                <Col
                  lg={1}
                  className="col-md-3 col-sm-4 col-4 mx-auto"
                  key={ind}
                >
                  <div className="position-relative">
                    <img
                      src={isImageFile(val) ? pdficon : imgicon}
                      alt="file icon"
                      className="img-fluid"
                    />
                    <div className="position-absolute top-0 start-100">
                      <DeleteForeverRoundedIcon
                        className="fs-4"
                        onClick={(e) => deletefile(e, val)}
                        title="Delete file"
                      />
                    </div>
                    <p>Name : {val}</p>
                    <p>Pages : {numofpages}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </Container>
      <Container className="p-5">
        <div className="card border-0 shadow rounded-4 p-4">
          <Row className="g-3">
            <Col lg={9} className="col-md-12">
              <Row>
                <Col lg={12} className="col-12">
                  <h5 className="m-0">Print Settings</h5>
                  <p className="fs-xs text-secondary m-0">
                    Same print settings apply to all files
                  </p>
                  <hr />
                </Col>
                <Col lg={12} className="col-12">
                  <Row>
                    <Col xl={10} lg={9} xs={6}>
                      <h5 className="m-0">Choose Number of Copies</h5>
                      <p className="fs-xs text-secondary m-0">
                        Copies of this file you want to print
                      </p>
                    </Col>
                    <Col xl={2} lg={3} xs={6} className="">
                      <div className="num-of-copy-box">
                        <div
                          className=" eXvafM user-select-none"
                          onClick={(e) => handelremovecopy(e)}
                        >
                          -
                        </div>
                        <div className="jksfMQ user-select-none">
                          {numofcopy}
                        </div>
                        <div
                          className=" SZwcA user-select-none"
                          onClick={(e) => handeladdcopy(e)}
                        >
                          +
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                </Col>
                {dis === true ? (
                  <>
                    <p className="m-0 fs-xs rad">Service Not Available</p>
                  </>
                ) : (
                  <>
                    <Col lg={12} className="col-12">
                      <Row>
                        <Col xs={12} className="col-lg-12 col-sm-12">
                          <Row>
                            <Col lg={9} className="col-md-12 col-12 me-auto">
                              <h5 className="m-0">Choose Print Type</h5>
                              <p className="fs-xs text-secondary m-0">
                                Save money with black & white or get color
                                printouts
                              </p>
                              <br />
                            </Col>
                            <Col
                              lg={1}
                              className={
                                service && service[0] > 0
                                  ? "col-md-3 col-sm-3 col-3"
                                  : "d-none"
                              }
                            >
                              <Card
                                className={
                                  bnwselect === true
                                    ? "border-0 shadow-none select-option"
                                    : "border-0 shadow-none"
                                }
                                onClick={(e) => handelchangeselectbnw(e)}
                              >
                                <img
                                  src={bnwimg}
                                  className="img-fluid p-0 p-lg-1"
                                  alt=""
                                />
                              </Card>
                              <p className="text-center pt-2 fs-xs">
                                B&W ₹{xeroxPrice}/page
                              </p>
                            </Col>
                            <Col
                              lg={1}
                              className={
                                service && service[1] > 0
                                  ? "col-md-3 col-sm-3 col-3"
                                  : "d-none"
                              }
                            >
                              <Card
                                className={
                                  colorselect === true
                                    ? "border-0 shadow-none select-option"
                                    : "border-0 shadow-none "
                                }
                                onClick={(e) => handelchangeselectcolor(e)}
                              >
                                <img
                                  src={colorimg}
                                  className="img-fluid p-0 p-lg-1"
                                  alt=""
                                />
                              </Card>
                              <p className="text-center pt-2 fs-xs">
                                Color ₹{colourPrice}/page
                              </p>
                            </Col>
                            <Col
                              lg={1}
                              className={
                                service && service[2] > 0
                                  ? "col-md-3 col-sm-3 col-3"
                                  : "d-none"
                              }
                            >
                              <Card
                                className={
                                  stickerselect === true
                                    ? "border-0 shadow-none select-option text-center mb-3 mb-md-5 mb-lg-0 mb-xl-1"
                                    : "border-0 shadow-none text-center mb-3 mb-md-5 mb-lg-0 mb-xl-1"
                                }
                                onClick={(e) => handelchangeselectsticker(e)}
                              >
                                <ReceiptRoundedIcon className="p-0 fs-1 fs-lg-2" />
                              </Card>
                              <p className="text-center pt-2 fs-xs">
                                Sticker ₹{stickerPrice}/page
                              </p>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                        <Col xs={12} className="col-lg-12 col-sm-12">
                          <Row>
                            <Col lg={10} className="col-6 ms-auto">
                              <h5 className="m-0">
                                {stickeroption === true ? (
                                  <>Choose print size</>
                                ) : (
                                  <>Choose Print Orientation</>
                                )}
                              </h5>
                              <p className="fs-xs text-secondary m-0">
                                {stickeroption === true ? (
                                  <>Direction in which a document is printed</>
                                ) : (
                                  <>
                                    Direction in which a document is displayed
                                    or printed
                                  </>
                                )}
                              </p>
                              <br />
                            </Col>
                            <Col lg={1} className="col-md-3 col-sm-3 col-3">
                              <Card className="border-0 shadow-none ">
                                {stickeroption === true ? (
                                  <>
                                    <div
                                      className={
                                        threebyfour === true
                                          ? "small-img-box select-option p-1"
                                          : "small-img-box p-1"
                                      }
                                      onClick={(e) => handelchangese3x4(e)}
                                    >
                                      <p className="m-0">3X5</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={
                                        porselect === true
                                          ? "small-img-box select-option p-1"
                                          : "small-img-box p-1"
                                      }
                                      onClick={(e) => handelchangeselectprot(e)}
                                    >
                                      <img
                                        src={portraitimg}
                                        className="img-fluid h-100 object-fit-fill"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                )}
                              </Card>
                              <p className="text-center pt-2 fs-xs">
                                {stickeroption === true ? (
                                  <>3X5 size</>
                                ) : (
                                  <>Portrait</>
                                )}
                              </p>
                            </Col>
                            <Col
                              lg={1}
                              className={
                                stickeroption === true
                                  ? "col-md-3 col-sm-3 col-3 "
                                  : "col-md-3 col-sm-3 col-3 px-0"
                              }
                            >
                              <Card className="border-0 shadow-none">
                                {stickeroption === true ? (
                                  <>
                                    <div
                                      className={
                                        fourbysix === true
                                          ? "small-img-box select-option p-1"
                                          : "small-img-box"
                                      }
                                      onClick={(e) => handelchangese4x6(e)}
                                    >
                                      {" "}
                                      <p className="m-0">4X6</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={
                                        landselect === true
                                          ? "small-img-box select-option"
                                          : "small-img-box"
                                      }
                                      onClick={(e) => handelchangeselectland(e)}
                                    >
                                      <img
                                        src={landscapeimg}
                                        className="img-fluid  px-1 py-2 px-md-4 px-sm-2 py-sm-1 px-lg-1 py-lg-1"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                )}
                              </Card>
                              <p className="text-center pt-2 fs-xs">
                                {stickeroption === true ? (
                                  <>4X6 size</>
                                ) : (
                                  <>Landscape</>
                                )}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col>
              <Col>
                <div className="cartbutton-box">
                  <div className="d-flex p-3" style={{ gap: "24px" }}>
                    <img
                      src={printweb}
                      className="img-fluid printweb-img"
                      alt=""
                    />
                    <div>
                      <h6 className="text-secondary">
                        {" "}
                        Total {numofpages} Pages{" "}
                      </h6>
                      <h6>₹ {totalRs - handlingPrice}/-</h6>
                    </div>
                  </div>
                  <div className="p-3">
                    <Button
                      variant="contained"
                      className="addto-cart-btn"
                      onClick={(e) => addToCart(e)}
                      disabled={dis === true ? true : false}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Addpage;
