import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { jwtDecode } from "jwt-decode";
import logo from "../assets/images/logo.png";
import HistoryIcon from "@mui/icons-material/History";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import axios from "axios";
import { Tooltip } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MiniDrawer = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [cookies, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [dis, setDis] = useState(false);
  const [username, setusername] = useState();
  const [name, setname] = useState();
  const [id, setid] = useState();

  const token = cookies.token;
  const authRole = cookies.authRole;
  const reqtoken = cookies.notififcationCookie;

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    if (!token || token === undefined) {
      navigate("/vendor/login");
    } else {
      const decoded = jwtDecode(authRole);
      const role = decoded?.role;
      if (role === "admin") {
        navigate("/admin/dashbord");
      } else if (role === "user") {
        navigate("/");
      }

      const usname = decoded?.shop_name;
      setusername(usname);

      const name = decoded?.name;
      setname(name);
      // const id = decoded?.toc?.id;
      // setid(id);
    }
  }, [authRole, navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Msg = () => (
    <div className="toast-body">
      Are you sure want to Logout?
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => setFixDelete(e)}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={(e) => setCancelDelete(e)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const setFixDelete = (e) => {
    e.preventDefault();
    removeCookie("token");
    removeCookie("authRole");
    navigate("/vendor/login");
    toast.success("Logout successfully");

    if (reqtoken !== undefined && id !== undefined) {
      const data = {
        vendorId: id,
        sessionId: reqtoken,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/vendor/logout`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "error") {
            toast.error(res.data.error);
          }
        });
    }
  };

  const setCancelDelete = (e) => {
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setDis(true);
    toast(<Msg />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // useEffect(() => {
  //   if (Notification.permission !== "granted") {
  //     setDis(true);
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         setDis(false);
  //       } else {
  //         setDis(true);
  //         toast.info("Please allow notifications in your browser settings.");
  //       }
  //     });
  //   }
  // }, [Notification]);

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open} className="bg-primary">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {username && username}
          </Typography>
          <h5 className="m-0 d-none d-md-block ms-auto">
            Hello, {name && name}
          </h5>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={logo} alt="" className="logo-img p-3" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Dashboard" placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={dis === true ? true : false}
                onClick={() => navigate("/vendor/dashbord")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="dashbord"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Orders" placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={dis === true ? true : false}
                onClick={() => navigate("/vendor/orders")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ListAltRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Order History" placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={dis === true ? true : false}
                onClick={() => navigate("/vendor/orders/history")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Order History"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Payment List" placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate("/vendor/payments")}
                disabled={dis === true ? true : false}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Payments"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Logout" placement="right">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                disabled={dis === true ? true : false}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LogoutRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ p: 1 }}>
        <DrawerHeader />
        {/* Here you can add the content */}
      </Box>
    </Box>
  );
};

export default MiniDrawer;
