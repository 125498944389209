import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import homeSlider from "../assets/images/home-slider.jpg";
import Card from "react-bootstrap/Card";
import upload1 from "../assets/images/upload-img.png";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import InvertColorsOutlinedIcon from "@mui/icons-material/InvertColorsOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import CropRotateOutlinedIcon from "@mui/icons-material/CropRotateOutlined";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
// import {
//   faPassport,
//   faBook,
//   faFutbol,
//   faBookOpen,
//   faFileInvoice,
//   faScroll,
// } from "@fortawesome/free-solid-svg-icons";
// import { faIdCard, faFile } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import axios from "axios";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Progressbar from "../component/Progressbar";
import { toast } from "react-toastify";
import Navigationbar from "../component/Navigationbar";
import Footer from "../component/Footer";
import { PDFDocument } from "pdf-lib";
import playstore from "../assets/images/comeing-soon-playstore.png";
import appstore from "../assets/images/comeing-soon-appstore.png";
import ReactGA from "react-ga4";

const Home = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home page",
  });

  const [cookies, setCookie, removeCookie] = useCookies();
  const token = cookies.temp_uuid;
  const navigate = useNavigate();
  // const [file, setFile] = useState([]);
  const [loading, setloading] = useState(false);
  const [fileError, setfileError] = useState("");
  const [vendorWorkStatus, setvendorWorkStatus] = useState("");
  const [dis, setdis] = useState(false);

  const handleFileChange = async (e) => {
    setfileError("");
    if (token !== undefined) {
      removeCookie("temp_uuid");
    }

    let file = e.target.files[0];
    if (file.size > 25e6) {
      setfileError("Please upload a file smaller than 25 MB");
      return;
    }

    if (file) {
      const fileType = file.type;

      // Check if the file is a PDF and password-protected
      if (fileType === "application/pdf") {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const pdfDoc = await PDFDocument.load(arrayBuffer, {
            ignoreEncryption: true,
          });

          if (pdfDoc.isEncrypted) {
            setfileError("Password protected documents are not supported.");
            return;
          }
        } catch (error) {
          toast.error(
            "Could not process the file. It may be password protected."
          );
          return;
        }
      }

      const huuid = uuid();
      setCookie("temp_uuid", huuid, {
        path: "/",
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("uuid", huuid);

      setloading(true);

      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/user/upload/file`, formData)
        .then((res) => {
          if (res.data.status === "success") {
            navigate("/preview");
          } else {
            toast.error(res.data.message);
            setloading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setloading(false);
        });
    }
  };

  useEffect(() => {
    if (loading === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [loading]);

  const getVendorService = (data) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/user/vendor/service`, data)
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data.data === undefined) {
            setvendorWorkStatus("N");
          } else {
            let xerox1 = 0;
            let colour1 = 0;
            let sticker1 = 0;

            res.data.data.forEach((element) => {
              // Increment counters using if statements
              if (element.xerox === "Y") {
                xerox1 += 1;
              }

              if (element.colour === "Y") {
                colour1 += 1;
              }

              if (element.sticker === "Y") {
                sticker1 += 1;
              }
            });

            let arr = [xerox1, colour1, sticker1];

            if (arr[0] === 0 && arr[1] === 0 && arr[2] === 0) {
              setvendorWorkStatus("N");
            } else {
              setvendorWorkStatus("Y");
            }
          }
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    const data = localStorage.getItem("Xeroxmartpincode");
    var myobj = JSON.parse(data);
    if (myobj !== undefined && myobj !== null) {
      const data = {
        pincode: myobj,
      };
      getVendorService(data);
    }

    //  setInterval(async () => {
    //    const data = localStorage.getItem("Xeroxmartpincode");
    //    var myobj = JSON.parse(data);
    //    if (myobj !== undefined && myobj !== null) {
    //      const data = {
    //        pincode: myobj,
    //      };
    //      getVendorService(data);
    //    }
    //  }, 5000);
  }, []);

  window.addEventListener("pincode-updated", (event) => {
    const data = localStorage.getItem("Xeroxmartpincode");
    var myobj = JSON.parse(data);
    if (myobj !== undefined) {
      const data = {
        pincode: myobj,
      };
      getVendorService(data);
    }
  });

  return (
    <>
      {loading === true ? (
        <>
          <Progressbar />
        </>
      ) : (
        <></>
      )}
      <Navigationbar />
      <Container className="position-relative">
        <Row>
          <Col lg={6} className="col-md-6 col-sm-12">
            <div className="p-md-5">
              <Row className="py-5 g-4">
                <Col xs={12} className="pt-4">
                  <Card className="bg-main-1 rounded-4 p-4 border-0">
                    <Card.Body>
                      <Row>
                        <Col xs={12} md={12} lg={3}>
                          <img
                            src={upload1}
                            alt="upload"
                            className="img-fluid p-0 p-sm-3"
                          />
                        </Col>
                        <Col xs={12} md={12} lg={9} className="p-0 p-sm-3">
                          <Card.Title className="card-heading">
                            Upload Your File
                          </Card.Title>
                          <Card.Text className="text-secondary">
                            We support only PDF file
                          </Card.Text>
                        </Col>
                        <p className="text-center fs-xs text-secondary pt-3">
                          Maximum upload file size: 25 MB
                        </p>
                        {loading === false ? (
                          <>
                            <form
                              className="w-100"
                              encType="multipart/form-data"
                            >
                              <input
                                type="file"
                                id="images"
                                accept=".pdf"
                                className="d-none"
                                onChange={handleFileChange}
                                name="file"
                                required
                                disabled={
                                  vendorWorkStatus === "N" ? true : false
                                }
                              />
                              <label
                                htmlFor="images"
                                className={
                                  vendorWorkStatus === "N"
                                    ? "dis-btn"
                                    : "fkpNJn"
                                }
                                id="images"
                              >
                                Upload Your File
                              </label>
                            </form>
                          </>
                        ) : (
                          <>
                            <form encType="multipart/form-data">
                              <Button
                                variant="primary"
                                className="dis-btn"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                />
                                Loading...
                              </Button>
                            </form>
                          </>
                        )}
                        {fileError !== "" ? (
                          <>
                            <p className="m-0 fs-xs rad text-center pt-2">
                              {fileError}
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                        {vendorWorkStatus === "N" ? (
                          <>
                            <p className="m-0 fs-xs rad text-center pt-2">
                              Sorry, currently we're not servicing in this area.
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="col-md-6 col-sm-12">
            <div className="text-center pt-md-5 mt-md-5">
              <img
                src={homeSlider}
                alt=""
                className={
                  vendorWorkStatus === "N"
                    ? "img-fluid dis-img"
                    : "img-fluid hero-heading"
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Container>
          <div className="p-0 pt-5 px-md-5">
            <h1 className="p-4">Why Try Us?</h1>
            <Row className="g-4">
              <Col lg={3} className="col-md-6 col-sm-12">
                <Card
                  className="p-3 rounded-4 border-0 shadow"
                  style={{ width: "90%", margin: "auto" }}
                >
                  <MeetingRoomIcon className="fs-1 m-2 feture-icon" />
                  <Card.Body>
                    <Card.Title className="pt-3">Doorstep Delivery</Card.Title>
                    <Card.Text>Delivering Same Day Printing</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12">
                <Card
                  className="p-3 rounded-4 border-0 shadow"
                  style={{ width: "90%", margin: "auto" }}
                >
                  <VerifiedUserOutlinedIcon className="fs-1 m-2 feture-icon" />
                  <Card.Body>
                    <Card.Title className="pt-3">Safe And Secure</Card.Title>
                    <Card.Text>We delete your files once printed</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12">
                <Card
                  className="p-3 rounded-4 border-0 shadow"
                  style={{ width: "90%", margin: "auto" }}
                >
                  <ArrowDownwardOutlinedIcon className="fs-1 m-2 feture-icon" />
                  <Card.Body>
                    <Card.Title className="pt-3">
                      Affordable Printing
                    </Card.Title>
                    <Card.Text>
                      B&W: ₹3 per page <br /> Colour: ₹10 per page
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12">
                <Card
                  className="p-3 rounded-4 border-0 shadow"
                  style={{ width: "90%", margin: "auto" }}
                >
                  <CreditCardOffOutlinedIcon className="fs-1 m-2 feture-icon" />
                  <Card.Body>
                    <Card.Title className="pt-3">No Minimum Order</Card.Title>
                    <Card.Text>
                      Order as many pages as few as you want
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      {/* how to work part */}

      <Container>
        <div className="px-3 pt-5 px-md-5">
          <h1 className="fw-bold">How Xeroxmart Works</h1>
          <h5>Let us take care of your everyday printing needs</h5>
          <Row className="g-4">
            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <LocalPrintshopOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">
                    Visit Xeroxmart
                  </Card.Title>
                  <Card.Text>Open browser and visit our website</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <FileUploadOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">Upload File</Card.Title>
                  <Card.Text>
                    Upload a file or multiple files to take prints
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <TuneOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">
                    Customise print
                  </Card.Title>
                  <Card.Text>
                    Choose print settings as per your requirement
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <ShoppingCartCheckoutOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">Checkout</Card.Title>
                  <Card.Text>Add prints to cart and place an order</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {/* Customisation options */}
        <div className="px-3 pt-5 px-md-5">
          <h1 className="fw-bold">Customisation options</h1>

          <Row className="g-4">
            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <UploadFileRoundedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">
                    Upload PDF File
                  </Card.Title>
                  <Card.Text>
                    Currently We Support Only PDF File Type
                    {/* Print PDF, JPG, PNG, JPEG, and many more */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <InvertColorsOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">
                    Black & White / Colour
                  </Card.Title>
                  <Card.Text>
                    Save cost with black & white or pick the coloured option
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <DocumentScannerOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">
                    Paper Format Size
                  </Card.Title>
                  <Card.Text>
                    We work only with A4 printing paper of 70 GSM
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} className="col-md-6 col-sm-12 text-center">
              <Card className="p-3 rounded-4 border-0 shadow">
                <div>
                  <CropRotateOutlinedIcon className="service-icon bg-white" />
                </div>
                <Card.Body>
                  <Card.Title className="pt-2 fw-bold">Orientation</Card.Title>
                  <Card.Text>
                    Choose landscape or portrait as per your need
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      {/* <Container fluid className="bg-main">
        <Container>
          <div className="p-5">
            <h1 className="fw-bold">Solution for convenient printing needs!</h1>
            <h5>Flexible Printing applications for multiple needs</h5>
            <Row>
              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faPassport}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Passport, Visa and Travel Documents
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faIdCard}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Aadhar card / ID proofs
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faBook}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Homework / Class Work / Assignments
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faFutbol}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Activity and Hobby Sheets
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      eBooks & Leisure Reading Articles
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faFileInvoice}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Resumes & Work related documents
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faFile}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">
                      Bank & Official Govt. Documents
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={3} className="col-md-6 col-sm-12 text-center">
                <Card className="pt-5 pb-5 px-3 rounded-4 border-0 bg-main">
                  <div>
                    <FontAwesomeIcon
                      icon={faScroll}
                      className="service-icon-1 bg-white"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="pt-2">Invoices & Bills</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Container> */}

      <Container fluid>
        <Container>
          {/* about us */}
          <div className="py-5">
            <Row className="g-3">
              <Col lg={4} className="about-img-box">
                <img
                  src={logo}
                  alt="hero"
                  className="img-fluid about-img p-0 p-lg-4"
                />
                <div className="d-flex">
                  <img
                    src={playstore}
                    alt="hero"
                    className="img-fluid prelonch-img p-4 d-none d-lg-block"
                  />
                  <img
                    src={appstore}
                    alt="hero"
                    className="img-fluid prelonch-img p-4 d-none d-lg-block"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="py-4">
                  <h3 className="" style={{ fontWeight: 500 }}>
                    About Xeroxmart
                  </h3>
                  <h6 style={{ fontWeight: 200, textAlign: "justify" }}>
                    Welcome to Xeroxmart, your go-to solution for all your
                    printing needs with the convenience of doorstep delivery. We
                    specialize in providing high-quality printing services with
                    no minimum order requirement, making us the perfect choice
                    for both personal and business needs. Whether you need
                    documents, flyers, business cards, or customized prints, our
                    wide range of printing options ensures we can meet your
                    specific requirements. At Xeroxmart, we are committed to
                    delivering excellence right to your door, saving you time
                    and effort while ensuring top-notch results. Experience
                    hassle-free printing with us today!
                  </h6>
                </div>
              </Col>
              <Col
                lg={6}
                className="about-img-box text-center d-block d-lg-none"
              >
                <img
                  src={playstore}
                  alt="hero"
                  className="img-fluid prelonch-img w-100"
                />
              </Col>
              <Col
                lg={6}
                className="about-img-box text-center d-block d-lg-none"
              >
                <img
                  src={appstore}
                  alt="hero"
                  className="img-fluid prelonch-img w-100"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
