import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import i1 from "../../assets/images/logo.png";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const VendorLogin = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["token"]);
  const token = cookies.authRole;

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const role = decoded?.role;
        if (role === "vendor") {
          navigate("/vendor/dashbord");
        } else {
          navigate("/");
        }
      } catch (error) {
        navigate("/");
      }
    }
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { username, password };
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/vendor/login`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          toast.success("Login successful");
          setCookie("authRole", res.data.role, { path: "/", maxAge: 86400 });
          setCookie(
            "token",
            res.data.token,
            { path: "/", maxAge: 86400 },
            () => {
              navigate("/vendor/dashbord");
            }
          );
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("An error occurred. Please try again.");
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Row>
          <Col lg={4} className="mx-auto">
            <div className="login-box">
              <div className="login-form">
                <div className="login-logo">
                  <img src={i1} alt="" className="img-fluid" />
                </div>
                <form className="text-center" onSubmit={handleSubmit}>
                  <TextField
                    type="text"
                    variant="standard"
                    fullWidth
                    placeholder="Username"
                    name="username"
                    autoComplete="off"
                    className="mb-3"
                    onChange={(e) => setUsername(e.target.value)}
                    maxLength={150}
                    color="warning"
                    required
                  />

                  <TextField
                    placeholder="Password"
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    required
                    className="mb-3"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    color="warning"
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className="bg-primary w-100 p-2 mt-3"
                    disabled={loading}
                  >
                    Login
                  </Button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

export default VendorLogin;
