import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Container, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Navigationbar from "../component/Navigationbar";
// import MapComponent from "../gmap/Map";
// import SearchLocationInput from "../gmap/GooglePlcasesApi";
import ReactGA from "react-ga4";

const MyAddress = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/account/address",
    title: "Address List page",
  });

  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies();
  const [orderData, setorderData] = useState([]);
  const [addressmodalShow, setaddressModalShow] = useState(false);
  const [addressupdatemodalShow, setaddressupdatemodalShow] = useState(false);
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [dis, setdis] = useState(false);
  const [updateaddressno, setupdateaddressno] = useState();
  const [aid, setaid] = useState("");
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [pincode, setpincode] = useState("");

  //   const hId = params.id;

  const token = cookies.token;
  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    const data = {};
    getAddressData(data);
  }, []);

  const getPincode = () => {
    const data = localStorage.getItem("Xeroxmartpincode");
    var myobj = JSON.parse(data);
    setpincode(myobj);
  };

  const getAddressData = async (data) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/address/get`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setorderData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
          if (res.data.length === 0) {
            setorderData([]);
          }
        }
      });
  };

  const Msg = () => (
    <>
      <div className="toast-body">
        Are you sure want to Logout ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={(e) => setFixDelete(e)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => setCancelDelete(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  const setFixDelete = (e) => {
    e.preventDefault();
    removeCookie("token");
    removeCookie("authRole");
    navigate("/");
  };

  const setCancelDelete = (e) => {
    setAutoCloseToast(100);
    setdis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 200);
  };

  const handelLogout = (e) => {
    e.preventDefault();
    setdis(true);
    toast(<Msg />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const addNewAddress = (e) => {
    e.preventDefault();
    setaddressModalShow(true);
  };

  function MyVerticallyCenteredModalforaddress(props) {
    const [mobileerror, setmobileerror] = useState(false);
    const [pincod, setpincod] = useState("");
    const [isokpin, setisokpin] = useState("");
    const [dis, setdis] = useState(true);
    const [landmark, setlandmark] = useState("");
    const [selectedLocation, setSelectedLocation] = useState({
      lat: 21.1696368,
      lng: 72.8311678,
    });

    useEffect(() => {
      setpincod(pincode);
    }, []);

    useEffect(() => {
      if (pincod !== "" && pincod.length === 6) {
        setdis(false);
      }
    }, [pincod]);

    const verifyotp = () => {
      const data = {
        pincode: pincod,
      };
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/user/verify/pincode`, data)
        .then((res) => {
          if (res.data.status === "success") {
            setisokpin(true);
            setdis(false);
          }
          if (res.data.status === "error") {
            setisokpin(false);
            setdis(true);
          }
        });
    };

    const handeladdAddress = (e) => {
      e.preventDefault();
      const mobileReg = /^[0-9]{10}$/;
      const isvalimobile = mobileReg.test(e.target.usermobile.value);
      const data = {
        address: e.target.address.value,
        landmark: landmark,
        username: e.target.username.value,
        number: e.target.usermobile.value,
        pincode: e.target.pincode.value,
        address_geo_lat: selectedLocation.lat,
        address_geo_long: selectedLocation.lng,
      };

      if (isvalimobile === true && isokpin === true) {
        setmobileerror(false);
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/user/address/add`,
            data,
            config
          )
          .then((res) => {
            if (res.data.status === "success") {
              setaddressModalShow(false);
              const data = {};

              getAddressData(data);
            }
            if (res.data.status === "error") {
              toast.error(res.data.message);
            }
          });
      } else {
        setmobileerror(true);
      }
    };
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container fluid>
            <h6 className="py-2 text-center">Enter Delivery Address</h6>
            <Row>
              {/* <Col className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div>
                  <MapComponent selectedLocation={selectedLocation} />
                </div>
              </Col> */}
              {/* <Col className="col-lg-6 col-md-6 col-sm-12 col-12"> */}
              <Col className="col-12">
                <form onSubmit={(e) => handeladdAddress(e)}>
                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    placeholder="Address Line"
                    inputProps={{ maxLength: 150 }}
                    variant="outlined"
                    name="address"
                    required
                  />

                  {/* <SearchLocationInput
                    setSelectedLocation={setSelectedLocation}
                    setlandmark={setlandmark}
                  /> */}

                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    placeholder="Landmark"
                    variant="outlined"
                    name="landmark"
                    onChange={(e) => setlandmark(e.target.value)}
                    required
                  />

                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    placeholder="Pincode"
                    variant="outlined"
                    name="pincode"
                    value={pincod}
                    inputProps={{ maxLength: 6 }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => verifyotp()}
                    onChange={(e) => setpincod(e.target.value)}
                    required
                  />

                  {isokpin === false ? (
                    <>
                      <p className="fs-xs rad">
                        sorry, we are not providing service in this area
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  <p className="m-0 text-secondary py-3">
                    Enter your details for seamless delivery experience
                  </p>
                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    inputProps={{ maxLength: 150 }}
                    placeholder="Your Name"
                    variant="outlined"
                    name="username"
                    required
                  />

                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    placeholder="Your Phone Number"
                    variant="outlined"
                    name="usermobile"
                    inputProps={{ maxLength: 10 }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                  />
                  {mobileerror === true ? (
                    <>
                      <p className="rad fs-xs">
                        Phone number should be 10 digit number
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  {dis === true ? (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        className="bg-info-dis w-100 p-2"
                        disabled
                      >
                        Save Address
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        type="submit"
                        className="bg-info w-100 p-2"
                      >
                        Save Address
                      </Button>
                    </>
                  )}
                </form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  const editAddress = async (e, adid) => {
    e.preventDefault();

    const filteredData = await orderData.filter((val) => {
      return val.address_id === adid;
    });
    setupdateaddressno(filteredData[0]);
    setaddressupdatemodalShow(true);
  };

  function MyVerticallyCenteredModalforupdateaddress(props) {
    const [address, setaddress] = useState();
    const [landmark, setlandmark] = useState();
    const [pincode, setpincode] = useState("");
    const [user, setuser] = useState();
    const [number, setnumber] = useState();
    const [mobileerror, setmobileerror] = useState(false);
    const [isokpin, setisokpin] = useState("");
    const [dis, setdis] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({
      lat: 21.1696368,
      lng: 72.8311678,
    });

    const verifyotp = () => {
      const data = {
        pincode: pincode,
      };
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/user/verify/pincode`, data)
        .then((res) => {
          if (res.data.status === "success") {
            setisokpin(true);
            setdis(false);
          }
          if (res.data.status === "error") {
            setisokpin(false);
            setdis(true);
          }
        });
    };

    useEffect(() => {
      if (updateaddressno !== undefined) {
        setaddress(updateaddressno && updateaddressno.address_line);
        setlandmark(updateaddressno && updateaddressno.address_landmark);
        setpincode(updateaddressno && updateaddressno.address_pincode);
        setuser(updateaddressno && updateaddressno.address_name);
        setnumber(updateaddressno && updateaddressno.address_number);
        if (
          updateaddressno.address_geo_lat !== undefined &&
          updateaddressno.address_geo_long !== undefined
        ) {
          setSelectedLocation({
            lat: Number(updateaddressno.address_geo_lat),
            lng: Number(updateaddressno.address_geo_long),
          });
        } else {
          setSelectedLocation({
            lat: 21.1696368,
            lng: 72.8311678,
          });
        }
      }
    }, []);

    const handeladdAddress = (e) => {
      e.preventDefault();

      const mobileReg = /^[0-9]{10}$/;
      const isvalimobile = mobileReg.test(e.target.usermobile.value);

      const data = {
        address: address,
        landmark: landmark,
        username: user,
        number: number,
        pincode: pincode,
        addressid: updateaddressno && updateaddressno.address_id,
        address_geo_lat: selectedLocation.lat,
        address_geo_long: selectedLocation.lng,
      };

      if (isvalimobile === true) {
        setmobileerror(false);
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/user/address/update`,
            data,
            config
          )
          .then((res) => {
            if (res.data.status === "success") {
              setaddressModalShow(false);
              toast.success("Address updated successfuly");
              const data = {};
              setaddressupdatemodalShow(false);
              getAddressData(data);
            }
            if (res.data.status === "error") {
              toast.error(res.data.message);
            }
          });
      } else {
        setmobileerror(true);
      }
    };
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container fluid>
            <h6 className="py-2 text-center">Edit Delivery Address</h6>
            <Row>
              {/* <Col xs={6} className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div>
                  <MapComponent selectedLocation={selectedLocation} />
                </div>
              </Col> */}
              {/* <Col xs={6} className="col-lg-6 col-md-6 col-sm-12 col-12"> */}
              <Col xs={6} className="col-12">
                <form onSubmit={(e) => handeladdAddress(e)}>
                  <TextField
                    placeholder="Address Line"
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    inputProps={{ maxLength: 150 }}
                    variant="outlined"
                    name="address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    required
                  />

                  {/* <SearchLocationInput
                    setSelectedLocation={setSelectedLocation}
                    setlandmark={setlandmark}
                    ulandmark={landmark}
                  /> */}

                  <TextField
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    placeholder="Landmark"
                    value={landmark}
                    variant="outlined"
                    name="landmark"
                    onChange={(e) => setlandmark(e.target.value)}
                    required
                  />

                  <TextField
                    className="w-100 my-2"
                    placeholder="Pincode"
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                    name="pincode"
                    inputProps={{ maxLength: 6 }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => verifyotp()}
                    value={pincode}
                    onChange={(e) => setpincode(e.target.value)}
                    required
                  />

                  {isokpin === false ? (
                    <>
                      <p className="fs-xs rad">
                        sorry, we are not providing service in this area
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  <p className="m-0 text-secondary py-3">
                    Enter your details for seamless delivery experience
                  </p>
                  <TextField
                    placeholder="Your Name"
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 150 }}
                    name="username"
                    value={user}
                    onChange={(e) => setuser(e.target.value)}
                    required
                  />

                  <TextField
                    placeholder="Your Phone Number"
                    className="w-100 my-2"
                    autoComplete="off"
                    size="small"
                    variant="outlined"
                    name="usermobile"
                    value={number}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => setnumber(e.target.value)}
                    required
                  />

                  {mobileerror === true ? (
                    <>
                      <p className="rad fs-xs">
                        Phone number should be 10 digit number
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  {dis === true ? (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        className="bg-info-dis w-100 p-2"
                        disabled
                      >
                        Update Address
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        type="submit"
                        className="bg-info w-100 p-2"
                      >
                        Update Address
                      </Button>
                    </>
                  )}
                </form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  // delete address
  const Msg2 = () => (
    <>
      <div className="toast-body">
        Are you sure want to Delete this Address ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={() => setconfirmDelete(true)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => setCancelDelete(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (confirmDelete === true) {
      const data = {
        address_id: aid,
      };

      setFixDeleteAddress(data);
    }
  }, [aid, confirmDelete]);

  const setFixDeleteAddress = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/address/delete`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          const datas = {};
          getAddressData(datas);
          setAutoCloseToast(false);
          setconfirmDelete(false);
          setdis(false);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const handelDeleteAddress = (e, id) => {
    e.preventDefault();
    setaid(id);
    setdis(true);
    toast(<Msg2 />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <>
      <Navigationbar />
      <Container className="p-0 shadow mt-3">
        <Row className="p-0 m-0">
          <Col
            lg={3}
            className="col-md-4 col-sm-12 p-0 m-0 border-end account-manu"
          >
            <div>
              <ul className="ul">
                <li className="li border-bottom ali">
                  <Link className="link text-secondary aLink">My Address</Link>
                </li>
                <li
                  className="li border-bottom "
                  onClick={() => navigate("/account/orders")}
                >
                  <Link className="link text-secondary ">My Orders</Link>
                </li>
                <li
                  className="li border-bottom "
                  onClick={dis === true ? undefined : (e) => handelLogout(e)}
                >
                  <Link className="text-decoration-none text-secondary">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={9} className="col-md-8 col-sm-12">
            <Row className="g-4 py-4">
              <h6 className="pointer " onClick={(e) => addNewAddress(e)}>
                + Add New Address
              </h6>
              <hr className="my-2" />
              {orderData.length === 0 ? (
                <>
                  <p>No address found</p>
                </>
              ) : (
                <>
                  {orderData &&
                    orderData.map((val, ind) => (
                      <Col xs={12} className="py-2 m-0 px-4" key={ind}>
                        <Row>
                          <Col
                            xs={6}
                            md={8}
                            lg={9}
                            className="d-flex align-items-center px-0"
                          >
                            <div className="pe-2">
                              <p className="m-0 fw-bold ">
                                {val.address_name} (
                                <span>{val.address_number}</span>)
                              </p>
                              <p className="m-0 fs-xs text-secondary">
                                {val.address_line}, {val.address_landmark},{" "}
                                {val.address_pincode}
                              </p>
                            </div>
                            <p
                              className="m-0 px-2 text-light bg-success rounded-pill"
                              style={{ fontSize: "8px" }}
                            >
                              {val.order_status}
                            </p>
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            className="d-flex align-items-center justify-content-end px-0"
                          >
                            <div className="">
                              <Button
                                className="mx-1"
                                variant="outlined"
                                size="small"
                                onClick={(e) => editAddress(e, val.address_id)}
                              >
                                <p className="m-0 fs-xs">Edit</p>
                              </Button>
                              <Button
                                className="mx-1"
                                variant="outlined"
                                color="error"
                                size="small"
                                disabled={dis === true ? true : false}
                                onClick={(e) =>
                                  handelDeleteAddress(e, val.address_id)
                                }
                              >
                                <p className="m-0 fs-xs">Delete</p>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <hr className="my-2" />
                      </Col>
                    ))}
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <MyVerticallyCenteredModalforaddress
        show={addressmodalShow}
        onHide={() => setaddressModalShow(false)}
      />
      <MyVerticallyCenteredModalforupdateaddress
        show={addressupdatemodalShow}
        onHide={() => setaddressupdatemodalShow(false)}
      />
    </>
  );
};

export default MyAddress;
