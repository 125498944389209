import React, { useEffect, useState } from "react";
import { Box, Button, createTheme, Switch, ThemeProvider } from "@mui/material";
import MiniDrawer from "../../component/MiniDrawer";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from "react-router-dom";
import requestPermission from "../../component/Webpushnotification";
import Modal from "react-bootstrap/Modal";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const Vendordashbord = () => {
  const [cookies, ,] = useCookies([]);
  const token = cookies.token;
  const authRole = cookies.authRole;
  const [dashbordData, setdashbordData] = useState([]);
  const navigate = useNavigate();

  const [sDate, setsDate] = useState();
  const [eDate, seteDate] = useState();
  const [chartData, setchartData] = useState([]);
  const [chartData2, setchartData2] = useState([]);
  const [income, setincome] = useState([]);
  const [id, setid] = useState();
  const [selectedStatus, setSelectedStatus] = useState("TODAY");
  const [vendorWorkStatus, setvendorWorkStatus] = useState();
  const [dis, setDis] = useState(false);
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getDadhbordData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/dashbord/statuscount`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setdashbordData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getRevenueforCharts = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/dashbord/revenuecount/chart`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setchartData2(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getVendorWorkingStatus = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/workingstatus`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setvendorWorkStatus(res.data.data[0].vendor_working_status);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    if (authRole === undefined) {
      navigate("/vendor/login");
    } else {
      if (authRole) {
        const decoded = jwtDecode(authRole);

        const data = {
          dateRange: selectedStatus,
        };
        getDadhbordData(data);

        const result = {};
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/vendor/payment/totel`,
            result,
            config
          )
          .then((res) => {
            if (res.data.status === "success") {
              setincome(res.data.resualt);
            }
            if (res.data.status === "error") {
              toast.error(res.data.message);
            }
          });
        getVendorWorkingStatus(result);
      }
    }
  }, [token, navigate, selectedStatus]);

  let order = [];

  chartData.forEach((element) => {
    order.push(element.placed_count);
  });

  let label = [];

  chartData.forEach((element) => {
    let date = new Date(element.order_date);
    label.push(element.order_date);
  });

  const options = {
    chart: {
      type: "column",
    },
    accessibility: {
      description: false,
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: label,
      crosshair: true,
      accessibility: {
        description: "Data",
      },
    },

    yAxis: {
      min: 0,
      title: {
        text: "Count of orders",
      },
    },
    title: {
      text: "Total Orders",
      align: "center",
    },

    series: [
      {
        name: "Order",
        data: order,
      },
    ],
  };

  const getData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/dashbord/ordercount/chart`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setchartData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];
    const end = new Date().toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);

    const data = {
      startDate: start,
      endDate: end,
    };

    getData(data);
    getRevenueforCharts(data);
  }, [seteDate, id]);

  const startDate = (e) => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];

    if (e.target.value > new Date().toISOString().split("T")[0]) {
      setsDate(start);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value > eDate) {
      seteDate(start);
      toast.error("Start date should be before end date");
      return;
    }
    setsDate(e.target.value);
  };

  const endDate = (e) => {
    const end = new Date().toISOString().split("T")[0];
    if (e.target.value > end) {
      seteDate(end);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value < sDate) {
      seteDate(end);
      toast.error("End date should be after start date");
      return;
    }
    seteDate(e.target.value);
  };

  const handelDateSelect = (e) => {
    e.preventDefault();

    const data = {
      startDate: sDate,
      endDate: eDate,
    };

    getData(data);
    getRevenueforCharts(data);
  };

  // const this7days = (e) => {
  //   e.preventDefault();
  //   var currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() - 7);

  //   var start = currentDate.toISOString().split("T")[0];
  //   const end = new Date().toISOString().split("T")[0];

  //   setsDate(start);
  //   seteDate(end);
  // };

  // const last7days = (e) => {
  //   e.preventDefault();
  //   var currentDate = new Date();
  //   var lastDate = new Date();
  //   currentDate.setDate(currentDate.getDate() - 14);
  //   lastDate.setDate(lastDate.getDate() - 7);

  //   var start = currentDate.toISOString().split("T")[0];
  //   const end = lastDate.toISOString().split("T")[0];

  //   setsDate(start);
  //   seteDate(end);
  // };

  // const this30days = (e) => {
  //   e.preventDefault();
  //   var currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() - 30);

  //   var start = currentDate.toISOString().split("T")[0];
  //   const end = new Date().toISOString().split("T")[0];

  //   setsDate(start);
  //   seteDate(end);
  // };

  // const last30days = (e) => {
  //   e.preventDefault();
  //   var currentDate = new Date();
  //   var lastDate = new Date();
  //   currentDate.setDate(currentDate.getDate() - 60);
  //   lastDate.setDate(lastDate.getDate() - 30);

  //   var start = currentDate.toISOString().split("T")[0];
  //   const end = lastDate.toISOString().split("T")[0];

  //   setsDate(start);
  //   seteDate(end);
  // };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  let order2 = [];
  let label2 = [];

  chartData2.forEach((element) => {
    order2.push(+element.total_amount);
    label2.push(element.payment_date);
  });

  const options2 = {
    chart: {
      type: "line",
    },
    accessibility: {
      description: false,
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: label2,
      crosshair: true,
      accessibility: {
        description: "Data",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Count of Revenue",
      },
    },
    title: {
      text: "Total Revenue",
      align: "center",
    },
    series: [
      {
        name: "Revenue",
        data: order2,
      },
    ],
  };

  useEffect(() => {
    // const user = "VENDOR";
    // if ("serviceWorker" in navigator && "PushManager" in window) {
    //   requestPermission(user, config);
    // }
  }, []);

  const updateWorkStatus = (e) => {
    e.preventDefault();
    const data = {
      vendorWorkStatus: vendorWorkStatus === "Y" ? "N" : "Y",
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/update/workingstatus`,
        data,
        config
      )
      .then((res) => {
        setDis(false);
        if (res.data.status === "success") {
          toast.success("Your working status is updated successfuly");
          const resualt = {
            vendorId: id,
          };
          getVendorWorkingStatus(resualt);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const Msg = () => (
    <div className="toast-body">
      {vendorWorkStatus === "Y" ? (
        <>Are you sure want to go Offline ?</>
      ) : (
        <>Are you sure want to go Online ?</>
      )}
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => setFixDelete(e)}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={(e) => setCancelUpdate(e)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const setFixDelete = (e) => {
    e.preventDefault();
    updateWorkStatus(e);
  };

  const setCancelUpdate = (e) => {
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const updateVendorWorrkStatus = (e) => {
    e.preventDefault();
    setDis(true);
    toast(<Msg />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // srvice model
  function MyVerticallyCenteredModal(props) {
    const [xerox, setXerox] = useState();
    const [colour, setColour] = useState();
    const [sticker, setSticker] = useState();

    const getVendorServices = (data) => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/vendor/services`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            setXerox(res.data.data[0].xerox);
            setColour(res.data.data[0].colour);
            setSticker(res.data.data[0].sticker);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };
    useEffect(() => {
      if (modalShow === true) {
        const data = {};
        getVendorServices(data);
      }
    }, []);

    const handelUpdateService = (e) => {
      e.preventDefault();
      const data = {
        xerox,
        colour,
        sticker,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/vendor/update/services`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("Service updated successfully");
            const resualt = {};
            getVendorServices(resualt);
            getVendorWorkingStatus(resualt);
            setModalShow(false);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };

    return (
      <ThemeProvider theme={theme}>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Your Service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => handelUpdateService(e)}>
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Black & White</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={xerox}
                      checked={xerox === "Y" ? true : false}
                      color="warning"
                      onChange={() => setXerox(xerox === "Y" ? "N" : "Y")}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Colour</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={colour}
                      checked={colour === "Y" ? true : false}
                      color="warning"
                      onChange={() => setColour(colour === "Y" ? "N" : "Y")}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Sticker</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={sticker}
                      checked={sticker === "Y" ? true : false}
                      color="warning"
                      onChange={() => setSticker(sticker === "Y" ? "N" : "Y")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 text-end">
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  className="me-2"
                >
                  Update
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className=" w-100 bg-main px-0">
          <MiniDrawer />
          <Box component="main" className="p-0 ps-4 ps-md-5">
            <Container className="ps-5">
              <Row>
                <div className="rounded-3">
                  <div className="card p-3 rounded-5 shadow">
                    <div className="card-body">
                      <Row className="g-3">
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <h5 className="m-0 pb-3">Dashboard</h5>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <div className="pb-md-2 text-md-end">
                            <Button
                              variant="contained"
                              color="warning"
                              className="m-2"
                              onClick={() => setModalShow(true)}
                            >
                              Select Service
                            </Button>
                            {vendorWorkStatus === "Y" ? (
                              <>
                                <Button
                                  variant="contained"
                                  color="error"
                                  className="m-2"
                                  onClick={(e) => updateVendorWorrkStatus(e)}
                                  disabled={dis === true ? true : false}
                                >
                                  Go Offline
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  color="success"
                                  className="m-2"
                                  onClick={(e) => updateVendorWorrkStatus(e)}
                                  disabled={dis === true ? true : false}
                                >
                                  Go Online
                                </Button>
                              </>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <h5 className="py-2 m-0 text-center border-bottom">
                            Total Order
                          </h5>
                          <div className="mt-2 ms-auto w-50 w-md-25">
                            <Form.Select
                              size="sm"
                              className="filter-select m-0 w-100"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                            >
                              <option value="TODAY">Today</option>
                              <option value="YESTERDAY">Yesterday</option>
                              <option value="THISWEEK">This Week</option>
                              <option value="THISMONTH">This Month</option>
                              <option value="THISYEAR">This Year</option>
                            </Form.Select>
                          </div>
                        </Col>

                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-1">
                            <div className="card-body p-4">
                              <h5 className="text-center">Placed</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData &&
                                dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData &&
                                  dashbordData[0].placed_count === null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].placed_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-4">
                            <div className="card-body p-4">
                              <h5 className="text-center">Printing</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData[0].printing_count === null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].printing_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-5">
                            <div className="card-body p-4">
                              <h5 className="text-center">Packed</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData[0].packed_count === null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].packed_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-3">
                            <div className="card-body p-4">
                              <h5 className="text-center">Dispatched</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData[0].dispatched_count ===
                                  null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].dispatched_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-2">
                            <div className="card-body p-4">
                              <h5 className="text-center">Delivered</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData[0].delivered_count === null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].delivered_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-6">
                            <div className="card-body p-4">
                              <h5 className="text-center">Cancelled</h5>
                              <p className="m-0 text-center fs-3">
                                {dashbordData[0] === undefined ? (
                                  <>0</>
                                ) : dashbordData[0].cancelled_count === null ? (
                                  <>0</>
                                ) : (
                                  dashbordData[0].cancelled_count
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <h5 className="py-3 m-0 text-center border-bottom">
                            Total Revenue
                          </h5>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-1">
                            <div className="card-body p-4">
                              <h5 className="text-center">Today</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? <> 0</> : income.today}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-4">
                            <div className="card-body p-4">
                              <h5 className="text-center">Yesterday</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? (
                                  <> 0</>
                                ) : (
                                  income.yesterday
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-5">
                            <div className="card-body p-4">
                              <h5 className="text-center">This Week</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? (
                                  <>0</>
                                ) : (
                                  income.thisweek
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-3">
                            <div className="card-body p-4">
                              <h5 className="text-center">This Month</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? (
                                  <>0</>
                                ) : (
                                  income.thismonth
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-2">
                            <div className="card-body p-4">
                              <h5 className="text-center">This Year</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? (
                                  <>0</>
                                ) : (
                                  income.thisyear
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={12}>
                          <div className="card border-0 rounded-4 shadow b-6">
                            <div className="card-body p-4">
                              <h5 className="text-center">Till Now</h5>
                              <p className="m-0 text-center fs-3">
                                ₹{" "}
                                {income === undefined ? (
                                  <> 0</>
                                ) : (
                                  income.networth
                                )}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col lg={12}>
                          <h5 className="py-2 m-0 text-center border-bottom">
                            Order History
                          </h5>
                        </Col>
                        {/* <Col lg={6} md={6} sm={12} xs={12} className="py-4">
                        <div className="w-100 text-center text-md-end">
                          <Button variant="text" onClick={(e) => this7days(e)}>
                            This 7 Days
                          </Button>
                        </div>
                        <div className="w-100 text-center text-md-end">
                          <Button variant="text" onClick={(e) => last7days(e)}>
                            Last 7 Days
                          </Button>
                        </div>
                        <div className="w-100 text-center text-md-end">
                          <Button variant="text" onClick={(e) => this30days(e)}>
                            This 30 Days
                          </Button>
                        </div>
                        <div className="w-100 text-center text-md-end">
                          <Button variant="text" onClick={(e) => last30days(e)}>
                            Last 30 Days
                          </Button>
                        </div>
                      </Col> */}
                        <Col lg={12} className="py-2">
                          <div>
                            <form
                              className="d-md-flex justify-content-around"
                              onSubmit={(e) => handelDateSelect(e)}
                            >
                              <div className="date-box">
                                <label htmlFor="startDate">Start Date :</label>
                                <input
                                  type="date"
                                  name="startDate"
                                  value={sDate || ""}
                                  className="datepicker-input w-100 w-md-50"
                                  onChange={(e) => startDate(e)}
                                  id=""
                                />
                              </div>

                              <div className="date-box">
                                <label htmlFor="startDate">End Date :</label>
                                <input
                                  type="date"
                                  name="endDare"
                                  value={eDate || ""}
                                  className="datepicker-input w-100 w-md-50"
                                  onChange={(e) => endDate(e)}
                                  id=""
                                />
                              </div>
                              <div className="date-box">
                                <Button
                                  variant="contained"
                                  className="bg-primary px-4"
                                  type="submit"
                                >
                                  Search
                                </Button>
                              </div>
                            </form>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={6} className="p-0">
                          <div>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} className="p-0">
                          <div>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options2}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Box>
        </div>
      </ThemeProvider>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Vendordashbord;
