import React from "react";
import i1 from "../assets/images/logo.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Error404 = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "*",
    title: "Error 404 page",
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="center-box bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-10 col-12 mx-auto">
              <div className="login-logo">
                <img src={i1} alt="" className="img-fluid" />
              </div>
              <div className="text-center">
                <h1>Error 404</h1>
                <h5>Page you are looking for could not be found.</h5>
              </div>
              <div className="text-center pt-5">
                <Button
                  variant="contained"
                  className="me-2 bg-primary px-4"
                  onClick={() => navigate("/")}
                >
                  go home
                </Button>
                <Button
                  variant="contained"
                  className="ms-2 bg-info px-4"
                  onClick={() => navigate(-1)}
                >
                  go back
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
