import React, { useEffect, useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  createTheme,
  IconButton,
  Switch,
  ThemeProvider,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Select from "react-select";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminUpdateRider = () => {
  const [cookies, ,] = useCookies([]);
  const navigate = useNavigate();
  const params = useParams();

  const rider_id = params.id;

  const [username, setusername] = useState("");
  const [name, setname] = useState("");
  const [pincode, setpincode] = useState("");

  const [formErr, setformErr] = useState(false);
  const [usernameErrMessage, setusernameErrMessage] = useState("");
  const [nameErrMessage, setNameErrMessage] = useState("");
  const [pincodeData, setpincodeData] = useState();
  const [pincodeErrMessage, setPincodeErrMessage] = useState("");

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getData = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/get/pincode`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setpincodeData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rider_id !== undefined) {
      const data = {
        rider_id: rider_id,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/rider/details`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            setusername(res.data.data[0].rider_username);
            setname(res.data.data[0].rider_name);
            setpincode(res.data.data[0].rider_pincode_id);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    }
  }, [rider_id]);

  const validateForm = () => {
    let isValid = true;
    setusernameErrMessage("");
    setNameErrMessage("");

    const invalidCharRegex = /[+=!@#$%^&*(){}\[\]\.,?/”“‘-]/;

    if (!username) {
      setusernameErrMessage("Username is required");
      isValid = false;
    } else if (/\s/.test(username)) {
      setusernameErrMessage("Username must not contain spaces");
      isValid = false;
    } else if (invalidCharRegex.test(username)) {
      setusernameErrMessage("Username must not contain special characters");
      isValid = false;
    } else if (username.length <= 3) {
      setusernameErrMessage("Username shoule have a minimum of 4 characters");
      isValid = false;
    } else {
      setusernameErrMessage("");
    }

    if (name.trim() === "") {
      setNameErrMessage("Name is required");
      isValid = false;
    } else if (name.length <= 2) {
      setNameErrMessage("Name should have a minimum of 3 characters");
      isValid = false;
    } else if (invalidCharRegex.test(name)) {
      setNameErrMessage("Name must not contain special characters");
      isValid = false;
    } else {
      setNameErrMessage("");
    }

    if (pincode.value === "NONE") {
      setPincodeErrMessage("Please Select pincode");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErr(false);

    if (validateForm()) {
      const data = {
        rider_id: rider_id,
        rider_username: username,
        rider_name: name,
        rider_pincode: pincode,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/rider/update`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("Rider Updated Successfully");
            navigate("/admin/rider/list");
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
          if (res.data.status === "error") {
            setusernameErrMessage(res.data.message);
          }
        });
    }
  };

  let options = [];

  pincodeData &&
    pincodeData.forEach((element) => {
      options.push({
        value: element.pincode_id,
        label: element.pincode_number,
      });
    });

  useEffect(() => {
    if (typeof pincode === "string" && pincode) {
      const pincodeArray = pincode.split(",");

      const pin =
        pincodeData &&
        pincodeData
          .filter((element) =>
            pincodeArray.includes(element.pincode_id.toString())
          )
          .map((element) => ({
            value: element.pincode_id,
            label: element.pincode_number,
          }));

      if (pin && pin.length > 0) {
        setpincode(pin);
      }
    }
  }, [pincodeData, pincode]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }} className="bg-main px-0">
          <AdminSideNav />
          <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
            <Container>
              <Row>
                <div className=" py-5 pe-5 rounded-3 my-4 overflow-auto">
                  <div className="card p-3 rounded-5 shadow">
                    <div className="card-body">
                      <div>
                        <IconButton
                          aria-label="goback"
                          size="large"
                          color="warning"
                          onClick={() => navigate("/admin/rider/list")}
                        >
                          <KeyboardBackspaceIcon />
                        </IconButton>
                      </div>

                      <h6 className="text-center fs-5 pb-1">
                        Please Enter Details Of Rider
                      </h6>
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <Row className="g-3">
                          <Col lg={6}>
                            <label htmlFor="username">Username *</label>
                            <input
                              placeholder="Username"
                              className="w-100 mb-4 admin-login-input"
                              autoComplete="off"
                              name="username"
                              value={username}
                              onChange={(e) => setusername(e.target.value)}
                              required
                            />
                            {usernameErrMessage && (
                              <p className="text-danger fs-xs">
                                {usernameErrMessage}
                              </p>
                            )}
                          </Col>

                          <Col lg={6}>
                            <label htmlFor="name">Name *</label>
                            <input
                              placeholder="Name"
                              className="w-100 mb-4 admin-login-input"
                              name="name"
                              value={name}
                              autoComplete="off"
                              onChange={(e) => setname(e.target.value)}
                              required
                            />
                            {nameErrMessage && (
                              <p className="text-danger fs-xs">
                                {nameErrMessage}
                              </p>
                            )}
                          </Col>
                          <Col lg={6}>
                            <label htmlFor="shopaddress" className="fs-xs">
                              Pincode *
                            </label>
                            <Select
                              className="w-100 mb-4"
                              value={pincode}
                              isMulti
                              onChange={(e) => {
                                setPincodeErrMessage("");
                                setpincode(e);
                              }}
                              options={options && options}
                            />
                            {pincodeErrMessage && (
                              <p className="text-danger fs-xs">
                                {pincodeErrMessage}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <div className="text-end">
                          <Button
                            variant="contained"
                            className="px-4 bg-primary me-1"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            className="px-4 text-black ms-1"
                            type="button"
                            color="inherit"
                            onClick={() => navigate("/admin/rider/list")}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminUpdateRider;
