import React, { useEffect, useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  IconButton,
  Switch,
  Tooltip,
  Pagination,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useCookies } from "react-cookie";
import { Col, Container, Row } from "react-bootstrap";

const AdminRiderList = () => {
  const [cookies] = useCookies([]);
  const navigate = useNavigate();
  const [rider, setRider] = useState([]);
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [dis, setDis] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [selectResult, setSelectResult] = useState(10);
  const [len, setLen] = useState(0);

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getRiderList = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/rider/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setRider(res.data.data);
          setLen(res.data.len);
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const handleUpdateRiderStates = (id, status) => {
    const data = {
      rider_id: id,
      rider_status: status === "Y" ? "N" : "Y",
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/rider/status/update`,
        data,
        config
      )
      .then((res) => {
        setDis(false);
        if (res.data.status === "success") {
          getRiderList({ limit: selectResult, skip: 0 });
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const handleDeleteUser = (e, id) => {
    e.preventDefault();
    const data = { rider_id: id };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/rider/delete`,
        data,
        config
      )
      .then((res) => {
        setDis(false);
        if (res.data.status === "success") {
          getRiderList({ limit: selectResult, skip: 0 });
          toast.success("Rider deleted successfully");
        } else {
          toast.error(res.data.message);
        }
      });
  };

  const Msg = ({ id }) => (
    <div className="toast-body">
      Are you sure you want to delete rider?
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleDeleteUser(e, id);
          }}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={() => setAutoCloseToast(100)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const Msg2 = ({ id, status }) => (
    <div className="toast-body">
      Are you sure you want to update rider status?
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleUpdateRiderStates(id, status);
          }}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={() => setAutoCloseToast(100)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    getRiderList({ limit: selectResult, skip: 0 });
  }, [selectResult]);

  useEffect(() => {
    if (rider.length > 0) {
      const resultPerPage = Math.ceil(len / selectResult);
      setPageNum(resultPerPage);
    }
  }, [rider, selectResult, len]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
    getRiderList({ limit: selectResult, skip: selectResult * (value - 1) });
  };

  return (
    <Box sx={{ display: "flex" }} className="bg-main px-0">
      <AdminSideNav />
      <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
        <Container>
          <Row>
            <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5 className="pb-3">Rider List</h5>
                    <Button
                      variant="contained"
                      className="bg-primary"
                      onClick={() => navigate("/admin/rider/new")}
                      startIcon={<AddBusinessRoundedIcon />}
                    >
                      Add New Rider
                    </Button>
                  </div>
                  <Row className="g-3">
                    {len === 0 ? (
                      <h6 className="m-0 text-center">No Rider Found</h6>
                    ) : (
                      <Col lg={12} className="pt-3">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Username</th>
                              <th>Status</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rider.map((val, ind) => (
                              <tr key={val.rider_id}>
                                <td>{ind + 1}</td>
                                <td>{val.rider_name}</td>
                                <td>{val.rider_username}</td>
                                <td>
                                  <Switch
                                    disabled={dis}
                                    checked={val.rider_status === "Y"}
                                    onChange={(e) => {
                                      setDis(true);
                                      toast(
                                        <Msg2
                                          id={val.rider_id}
                                          status={val.rider_status}
                                        />,
                                        {
                                          position: "top-center",
                                          autoClose: autoCloseToast,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "light",
                                        }
                                      );
                                    }}
                                  />
                                </td>
                                <td className="text-center">
                                  <Tooltip title="Update">
                                    <IconButton
                                      aria-label="edit"
                                      color="success"
                                      className="text-success me-1"
                                      onClick={() =>
                                        navigate(
                                          `/admin/rider/update/${val.rider_id}`
                                        )
                                      }
                                      disabled={dis}
                                    >
                                      <EditRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Change Password">
                                    <IconButton
                                      aria-label="update password"
                                      color="inherit"
                                      className="text-dark me-1"
                                      onClick={() =>
                                        navigate(
                                          `/admin/rider/update/password/${val.rider_id}`
                                        )
                                      }
                                      disabled={dis}
                                    >
                                      <LockResetIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      color="error"
                                      className="text-danger ms-1"
                                      onClick={(e) => {
                                        setDis(true);
                                        toast(<Msg id={val.rider_id} />, {
                                          position: "top-center",
                                          autoClose: autoCloseToast,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "light",
                                        });
                                      }}
                                      disabled={dis}
                                    >
                                      <DeleteRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                  </Row>
                  {len > 0 && (
                    <Row>
                      <div className="col-12 col-sm-3 px-2">
                        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                          <label
                            htmlFor="select-input"
                            className="form-label fs-xs px-1"
                          >
                            Rows per page
                          </label>
                          <select
                            className="form-select w-50 form-select-sm px-1"
                            id="select-input"
                            onChange={(e) => {
                              setSelectResult(parseInt(e.target.value, 10));
                              setActivePage(1);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-9 overflow-auto">
                        <div className="text-end px-2">
                          <Pagination
                            count={pageNum}
                            page={activePage}
                            onChange={handlePageChange}
                            showFirstButton
                            showLastButton
                          />
                        </div>
                      </div>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminRiderList;
