import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import logo from "../assets/images/logo.png";
import ReactGA from "react-ga4";

const Terms = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/terms",
    title: "Terms and Conditions page",
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="container shadow px-md-5">
        <div className="mt-5 pt-2">
          <IconButton aria-label="Go Back" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
        <div className="mx-auto text-center">
          <img src={logo} alt="hero" className="img-fluid w-25 mx-auto" />
        </div>
        <h3 className="text-center pt-4"> Terms and Conditions </h3>
        <p className="pt-3 text-secondary">Effective Date: 15/July/2024</p>
        <p className="pt-2 fs-xs">
          Welcome to Xeroxmart. These Terms and Conditions (“Terms”) govern your
          use of our website and services. By accessing or using the Xeroxmart
          website and our services, you agree to be bound by these Terms. If you
          do not agree to these Terms, do not use our website or services.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">1. Use of Our Services</b> <br />
          <span className="fs-6 fw-bold"> Eligibility</span> <br /> You must be
          at least 14 years old to use our services. By using our services, you
          represent and warrant that you have the right, authority, and capacity
          to enter into these Terms and to abide by all of the terms and
          conditions set forth herein.
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Account Registration </span> <br /> To
          access certain features of our services, you may be required to
          register for an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete. You are
          responsible for safeguarding your password and for all activities that
          occur under your account. You agree to notify Xeroxmart immediately of
          any unauthorized use of your account.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">2. Orders and Payment</b> <br />{" "}
          <span className="fs-6 fw-bold"> Order Placement</span> <br /> When you
          place an order with Xeroxmart, you agree to provide current, complete,
          and accurate purchase and account information. All orders are subject
          to acceptance and availability. We reserve the right to refuse
          service, terminate accounts, remove or edit content, or cancel orders
          at our sole discretion.
        </p>
        <p className="pt-1 fs-xs">
          Pricing and Payment All prices are listed in India Rupees. Prices are
          subject to change without notice. We accept payment by Razorpay. You
          agree to pay all charges incurred by your account at the prices in
          effect when such charges are incurred.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">3. Service Use</b> <br />
          <span className="fs-6 fw-bold"> Prohibited </span> <br /> Uses You may
          use our services only for lawful purposes and in accordance with these
          Terms. You agree not to use our services:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            To impersonate or attempt to impersonate Xeroxmart, a Xeroxmart
            employee, another user, or any other person or entity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the services, or which, as determined by us, may
            harm Xeroxmart or users of the services, or expose them to
            liability.
          </li>
        </ul>
        <p className="pt-1 fs-xs">
          <b className="fs-5"> 4. Intellectual Property</b> <br />
          <span className="fs-6 fw-bold"> Ownership</span> <br /> The content on
          the Xeroxmart website, including but not limited to text, graphics,
          logos, images, and software, is the property of Xeroxmart or its
          content suppliers and protected by copyright, trademark, and other
          laws. You may not use, reproduce, or distribute any content from the
          Xeroxmart website without our express written permission.
        </p>

        <p className="pt-1 fs-xs">
          <b className="fs-5">5. Limitation of Liability</b> <br /> To the
          fullest extent permitted by applicable law, Xeroxmart will not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from:
        </p>
        <ul>
          <li>Your use of or inability to use our services.</li>
          <li>
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein.
          </li>
          <li>
            Any interruption or cessation of transmission to or from our
            services.
          </li>
          <li>
            Any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our services by any third party.
          </li>
          <li>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the services.
          </li>
        </ul>
        <p className="pt-1 fs-xs">
          <b className="fs-5">6. Indemnification</b> <br /> You agree to
          indemnify, defend, and hold harmless Xeroxmart, its officers,
          directors, employees, agents, licensors, and suppliers from and
          against all claims, losses, liabilities, expenses, damages, and costs,
          including, without limitation, reasonable attorneys' fees, arising
          from or relating in any way to your use of the services, your conduct
          in connection with the services, or any violation of these Terms, any
          law, or the rights of any third party.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">7. Modifications to the Services and Terms</b>{" "}
          <br /> We reserve the right to modify or discontinue, temporarily or
          permanently, the services (or any part thereof) with or without
          notice. We may also update these Terms from time to time. If we make
          changes, we will notify you by revising the date at the top of the
          Terms and, in some cases, we may provide additional notice (such as
          adding a statement to our homepage or sending you a notification).
          Your continued use of the services after the effective date of such
          changes constitutes your acceptance of such changes.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">8. Governing Law</b> <br /> These Terms and any
          dispute or claim arising out of, or related to them, shall be governed
          by and construed in accordance with the internal laws of Gujarat
          Jurisdication without giving effect to any choice or conflict of law
          provision or rule.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">9. Contact Information</b> <br /> If you have any
          questions about these Terms, please contact us at:
        </p>
        <p className="pt-1 fs-xs">
          <span className="fw-bold fs-6">Xeroxmart</span> <br /> 104, Crystal
          Plaza, Punagam, Surat - 395010 <br /> Email: contact@xeroxmart. <br />{" "}
          shop Phone: +919510901080
        </p>
        <br />
      </div>
      <br />
    </>
  );
};

export default Terms;
