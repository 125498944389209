import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link, useNavigate } from "react-router-dom";
import pdficon from "../assets/images/pdf-icon.png";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import Navigationbar from "../component/Navigationbar";
import { Container } from "react-bootstrap";
import ReactGA from "react-ga4";

const MyOrders = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/account/orders",
    title: "Order List page",
  });

  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies();
  const [orderData, setOrderData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [selectResult, setSelectResult] = useState(10);
  const [len, setLen] = useState(0);
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [dis, setDis] = useState(false);

  const token = cookies.token;

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    if (token !== undefined) {
      // Add any required setup here
    }
  }, [token]);

  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
    };
    getOrderData(data);
  }, [selectResult]);

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/order/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrderData(res.data.data);
          setLen(res.data.length);
          setDis(false);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  // Pagination logic
  useEffect(() => {
    const resultPerPage = len / selectResult;
    const roundedValue = Math.ceil(resultPerPage);
    setPageNum(roundedValue);
  }, [orderData, selectResult, len]);

  const handlePageChange = (event, page) => {
    setActivePage(page);
    if (token) {
      const data = {
        limit: selectResult,
        skip: selectResult * (page - 1),
      };
      getOrderData(data);
    }
  };

  const handleRowsPerPageChange = (e) => {
    setSelectResult(parseInt(e.target.value, 10));
    setActivePage(1);
  };

  const Msg = () => (
    <>
      <div className="toast-body">
        Are you sure want to Logout ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={(e) => handleLogout(e)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => cancelLogout(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  const handleLogout = (e) => {
    e.preventDefault();
    removeCookie("token");
    removeCookie("authRole");
    navigate("/");
  };

  const cancelLogout = (e) => {
    e.preventDefault();
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const handleCancelOrder = (e, id) => {
    e.preventDefault();
    setDis(true);
    toast(<Msg2 id={id} />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const Msg2 = ({ id }) => (
    <>
      <div className="toast-body">
        Are you sure want to Cancel Order ?
        <div className="mt-2 pt-2 border-top">
          <button
            onClick={(e) => confirmCancelOrder(e, id)}
            type="button"
            className="btn btn-primary border-0 bg-info btn-sm me-1"
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-1"
            onClick={(e) => cancelCancelOrder(e)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  const confirmCancelOrder = (e, id) => {
    e.preventDefault();
    const data = {
      orderId: id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/user/order/cancel`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          const data = {
            limit: selectResult,
            skip: selectResult * (activePage - 1),
          };
          getOrderData(data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const cancelCancelOrder = (e) => {
    e.preventDefault();
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  return (
    <>
      <Navigationbar />
      <Container className="p-0 shadow mt-3">
        <Row className="p-0 m-0">
          <Col
            lg={3}
            className="col-md-4 col-sm-12 p-0 m-0 border-end account-manu"
          >
            <div>
              <ul className="ul">
                <li
                  className="li border-bottom "
                  onClick={() => navigate("/account/address")}
                >
                  <Link className="link text-secondary ">My Address</Link>
                </li>
                <li className="li border-bottom ali">
                  <Link className="link text-secondary aLink">My Orders</Link>
                </li>
                <li
                  className="li border-bottom"
                  onClick={dis === true ? undefined : (e) => handleLogout(e)}
                >
                  <Link className="text-decoration-none text-secondary">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={9} className="col-md-8 col-sm-12">
            <Row className="g-4 pt-5 ">
              {orderData.length === 0 ? (
                <p>No orders Found</p>
              ) : (
                orderData.map((val, ind) => (
                  <Col xs={12} className="py-2 m-0 px-4" key={ind}>
                    <Row>
                      <Col xs={1} className="p-0 px-md-2 px-xl-4">
                        <div className="text-end">
                          <img
                            alt=""
                            src={pdficon}
                            className="img-fluid w-100 "
                          />
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        xl={6}
                        lg={6}
                        md={4}
                        className="d-flex align-items-center px-md-0"
                      >
                        <div className="pe-2">
                          <p className="m-0 fw-bold ">
                            #{val.order_id} .{" "}
                            <span>₹{val.order_total_amount}</span>
                          </p>
                          <p className="m-0 fs-xs text-secondary ">
                            Placed on {val.order_created_at}
                          </p>
                        </div>
                        <p
                          className={
                            val.order_status === "DELIVERED"
                              ? "m-0 px-2 text-light bg-success rounded-pill"
                              : val.order_status === "CANCELLED"
                              ? "m-0 px-2  bg-danger bg text-light rounded-pill"
                              : val.order_status === "PICKEDUP"
                              ? "m-0 px-2 text-light bg-success rounded-pill"
                              : "m-0 px-2  bg-light text-secondary rounded-pill"
                          }
                          style={{ fontSize: "8px" }}
                        >
                          {val.order_status}
                        </p>
                      </Col>
                      <Col
                        xs={5}
                        xl={5}
                        lg={5}
                        md={7}
                        className="d-flex align-items-center justify-content-end px-md-0"
                      >
                        <div className="w-100 text-end">
                          {val.order_status === "PLACED" && (
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              className="fs-xs mx-1 my-1 my-sm-0"
                              onClick={(e) =>
                                handleCancelOrder(e, val.order_id)
                              }
                              disabled={dis}
                            >
                              Cancel Order
                            </Button>
                          )}
                          <Button
                            variant="outlined"
                            size="small"
                            className="fs-xs mx-1 my-1 my-sm-0"
                            onClick={() => navigate(`details/${val.order_id}`)}
                          >
                            View Details
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-2" />
                  </Col>
                ))
              )}
            </Row>
            {orderData.length > 0 && (
              <div className="row">
                <div className="col-12 col-sm-3 col-12 px-2">
                  <div className="d-flex justify-content-sm-start justify-content-center align-items-center">
                    <label
                      htmlFor="select-input"
                      className="form-label fs-xs px-1"
                    >
                      Rows per page
                    </label>
                    <select
                      className="form-select w-50 form-select-sm px-1"
                      id="select-input"
                      onChange={handleRowsPerPageChange}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-9 col-12 overflow-auto">
                  <div className="text-center px-2">
                    <Pagination
                      count={pageNum}
                      page={activePage}
                      onChange={handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyOrders;
