import React, { useEffect, useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import { useCookies } from "react-cookie";
import Select from "react-select";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminAddNewVendor = () => {
  const [cookies, ,] = useCookies([]);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [pincode, setpincode] = useState({ label: "None", value: "NONE" });

  const [formErr, setFormErr] = useState(false);
  const [usernameErrMessage, setUsernameErrMessage] = useState("");
  const [passwordErrMessage, setPasswordErrMessage] = useState("");
  const [confirmPasswordErrMessage, setConfirmPasswordErrMessage] =
    useState("");
  const [pincodeErrMessage, setPincodeErrMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [pincodeData, setpincodeData] = useState();
  const [nameErrMessage, setNameErrMessage] = useState("");

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getData = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/pincode/list`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setpincodeData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const validateForm = () => {
    let isValid = true;
    setUsernameErrMessage("");
    setPasswordErrMessage("");
    setConfirmPasswordErrMessage("");
    setNameErrMessage("");

    const invalidCharRegex = /[+=!@#$%^&*(){}\[\]\.,?/”“‘-]/;

    if (username.trim() === "") {
      setUsernameErrMessage("Username is required");
      isValid = false;
    } else if (/\s/.test(username)) {
      setUsernameErrMessage("Username must not contain spaces");
      isValid = false;
    } else if (invalidCharRegex.test(username)) {
      setUsernameErrMessage("Username must not contain special characters");
      isValid = false;
    } else if (username.length <= 3) {
      setUsernameErrMessage("Username should have a minimum of 4 characters");
      isValid = false;
    } else {
      setUsernameErrMessage("");
    }

    if (name.trim() === "") {
      setNameErrMessage("Name is required");
      isValid = false;
    } else if (name.length <= 2) {
      setNameErrMessage("Name should have a minimum of 3 characters");
      isValid = false;
    } else if (invalidCharRegex.test(name)) {
      setNameErrMessage("Name must not contain special characters");
    } else {
      setNameErrMessage("");
    }

    if (password.length < 8) {
      setPasswordErrMessage("Password should have a minimum of 8 characters");
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordErrMessage("Passwords do not match");
      isValid = false;
    }
    if (pincode.value === "NONE") {
      setPincodeErrMessage("Please Select pincode");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErr(false);
    if (!validateForm()) {
      setFormErr(true);
      return;
    }
    const data = {
      vendor_username: username,
      vendor_password: password,
      vendor_name: name,
      vendor_shop_name: shopName,
      vendor_shop_address: shopAddress,
      vendor_service_type: serviceType,
      vendor_pincode: pincode.value,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/new`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Vendor Added Successfully");
          navigate("/admin/vendor/list");
        } else {
          toast.error(res.data.message);
          if (res.data.status === "error") {
            setUsernameErrMessage(res.data.message);
          }
        }
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  let options = [{ value: "NONE", label: "None" }];

  pincodeData &&
    pincodeData.forEach((element) => {
      options.push({
        value: element.pincode_id,
        label: element.pincode_number,
      });
    });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }} className="bg-main px-0">
        <AdminSideNav />
        <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
          <Container>
            <Row>
              <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
                <div className="card p-3 rounded-5 shadow">
                  <div className="card-body">
                    <div>
                      <IconButton
                        aria-label="goback"
                        size="large"
                        color="warning"
                        onClick={() => navigate("/admin/vendor/list")}
                      >
                        <KeyboardBackspaceIcon />
                      </IconButton>
                    </div>

                    <h6 className="text-center fs-5 pb-1">
                      Please Enter Vendor Details
                    </h6>
                    <form onSubmit={handleSubmit}>
                      <Row className="g-3">
                        <Col lg={6}>
                          <label htmlFor="name" className="fs-xs">
                            Name *
                          </label>
                          <input
                            placeholder="Name"
                            className="w-100 mb-4 admin-login-input"
                            name="name"
                            autoComplete="off"
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                          {nameErrMessage && (
                            <p className="text-danger fs-xs">
                              {nameErrMessage}
                            </p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="username" className="fs-xs">
                            Username *
                          </label>
                          <input
                            placeholder="Username"
                            className="w-100 mb-4 admin-login-input"
                            autoComplete="off"
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                          {usernameErrMessage && (
                            <p className="text-danger fs-xs">
                              {usernameErrMessage}
                            </p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="password" className="fs-xs">
                            Password *
                          </label>

                          <TextField
                            placeholder="Password"
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            required
                            className="mb-4 mt-1"
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                          />
                          {passwordErrMessage && (
                            <p className="text-danger fs-xs">
                              {passwordErrMessage}
                            </p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="confirmPassword" className="fs-xs">
                            Confirm Password *
                          </label>

                          <TextField
                            placeholder="Confirm Password"
                            variant="standard"
                            fullWidth
                            autoComplete="off"
                            type={showPassword2 ? "text" : "password"}
                            value={confirmPassword}
                            required
                            className="mb-4 mt-1"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword2}
                                  >
                                    {showPassword2 ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                          />
                          {confirmPasswordErrMessage && (
                            <p className="text-danger fs-xs">
                              {confirmPasswordErrMessage}
                            </p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="shopname" className="fs-xs">
                            Shop Name *
                          </label>
                          <input
                            placeholder="Shop Name"
                            className="w-100 mb-4 admin-login-input"
                            name="shopname"
                            autoComplete="off"
                            onChange={(e) => setShopName(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="shopaddress" className="fs-xs">
                            Shop Address *
                          </label>
                          <input
                            placeholder="Shop Address"
                            className="w-100 mb-4 admin-login-input"
                            name="shopaddress"
                            autoComplete="off"
                            onChange={(e) => setShopAddress(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="shopaddress" className="fs-xs">
                            Service Type *
                          </label>
                          <TextField
                            color="warning"
                            select
                            helperText="Please select service type"
                            defaultValue={"PICKUP"}
                            variant="standard"
                            className="w-100 mb-4"
                            onChange={(e) => setserviceType(e.target.value)}
                          >
                            <MenuItem value="PICKUP">PICKUP</MenuItem>
                            <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                            <MenuItem value="BOTH">BOTH</MenuItem>
                          </TextField>
                        </Col>
                        <Col lg={6}>
                          <label htmlFor="shopaddress" className="fs-xs">
                            Pincode *
                          </label>
                          <Select
                            className="w-100 mb-4"
                            value={pincode}
                            onChange={(e) => {
                              setPincodeErrMessage("");
                              setpincode(e);
                            }}
                            options={options && options}
                          />
                          {pincodeErrMessage && (
                            <p className="text-danger fs-xs">
                              {pincodeErrMessage}
                            </p>
                          )}
                        </Col>
                      </Row>
                      <div className="text-end">
                        <Button
                          variant="contained"
                          className="px-4 bg-primary me-1"
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          variant="contained"
                          className="px-4 text-black ms-1"
                          type="button"
                          color="inherit"
                          onClick={() => navigate("/admin/vendor/list")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminAddNewVendor;
