import {
  Box,
  Button,
  createTheme,
  IconButton,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminSideNav from "../../component/AdminSideNav";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const Adminpincode = () => {
  const [cookies, ,] = useCookies([]);
  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const theme = createTheme({
    palette: {
      warning: {
        main: "#133b62",
      },
    },
  });

  const [pincodeData, setpincodeData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [id, setid] = useState();

  const getData = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/get/pincode`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setpincodeData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // add pincde model
  function MyVerticallyCenteredModal(props) {
    const [pincode, setpincode] = useState();
    const [BandW, setBandW] = useState();
    const [colour, setcolour] = useState();
    const [sticker, setsticker] = useState();
    const [handling, sethandling] = useState();
    const [delivery, setdelivery] = useState();

    const [pincodeErrMessage, setpincodeErrMessage] = useState("");
    const [BandWErrMessage, setBandWErrMessage] = useState("");
    const [colourErrMessage, setcolourErrMessage] = useState("");
    const [stickerErrMessage, setstickerErrMessage] = useState("");
    const [handlingErrMessage, sethandlingErrMessage] = useState("");
    const [deliveryErrMessage, setdeliveryErrMessage] = useState("");

    const handelSubmit = (e) => {
      e.preventDefault();

      if (pincode === undefined) {
        setpincodeErrMessage("Pincode is required");
        return;
      } else if (pincode.length < 6) {
        setpincodeErrMessage("Pincode should have a minimum of 6 numbers");
        return;
      } else {
        setpincodeErrMessage("");
      }

      if (BandW === undefined) {
        setBandWErrMessage("Pincode is required");
        return;
      } else {
        setBandWErrMessage("");
      }

      if (colour === undefined) {
        setcolourErrMessage("Pincode is required");
        return;
      } else {
        setcolourErrMessage("");
      }

      if (sticker === undefined) {
        setstickerErrMessage("Pincode is required");
        return;
      } else {
        setstickerErrMessage("");
      }

      if (handling === undefined) {
        sethandlingErrMessage("Pincode is required");
        return;
      } else {
        sethandlingErrMessage("");
      }

      if (delivery === undefined) {
        setdeliveryErrMessage("Pincode is required");
        return;
      } else {
        setdeliveryErrMessage("");
      }

      const data = {
        pincode,
        xerox_cost: BandW,
        colour_cost: colour,
        sticker_cost: sticker,
        handing_cost: handling,
        delivery_cost: delivery,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/add/pincode`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            getData();
            setModalShow(false);
            toast.success("New pincode added successfully");
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };
    return (
      <ThemeProvider theme={theme}>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add New Pincode
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <form
                className="py-4 px-3"
                onSubmit={(e) => handelSubmit(e)}
                autoComplete="off"
              >
                <Row className="g-2">
                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Pincode *</label>
                    <TextField
                      color="warning"
                      id="standard-basic"
                      placeholder="Pincode"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={pincode}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => setpincode(e.target.value)}
                    />
                    {pincodeErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {pincodeErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">B&W Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="B&W Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={BandW}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setBandW(e.target.value)}
                    />
                    {BandWErrMessage && (
                      <p className="text-danger m-0 fs-xs">{BandWErrMessage}</p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Colour Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Colour Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={colour}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setcolour(e.target.value)}
                    />
                    {colourErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {colourErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Sticker Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Sticker Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={sticker}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setsticker(e.target.value)}
                    />
                    {stickerErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {stickerErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Handling Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Handling Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={handling}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => sethandling(e.target.value)}
                    />
                    {handlingErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {handlingErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Delivery Cost *</label>
                    <TextField
                      color="warning"
                      id="standard-basic"
                      placeholder="Delivery Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={delivery}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setdelivery(e.target.value)}
                    />
                    {deliveryErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {deliveryErrMessage}
                      </p>
                    )}
                  </Col>
                </Row>

                <div className="pt-3">
                  <Button
                    variant="contained"
                    className="bg-primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Container>
          </Modal.Body>
        </Modal>
      </ThemeProvider>
    );
  }

  const deletePincode = (e, id) => {
    e.preventDefault();

    const data = {
      id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/delete/pincode`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          getData();
          toast.success("Pincode deleted successfilly.");
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  // update pincode model
  function MyVerticallyCenteredModal2(props) {
    const [pincode, setpincode] = useState("");
    const [BandW, setBandW] = useState("");
    const [colour, setcolour] = useState("");
    const [sticker, setsticker] = useState("");
    const [handling, sethandling] = useState("");
    const [delivery, setdelivery] = useState("");

    const [BandWErrMessage, setBandWErrMessage] = useState("");
    const [colourErrMessage, setcolourErrMessage] = useState("");
    const [stickerErrMessage, setstickerErrMessage] = useState("");
    const [handlingErrMessage, sethandlingErrMessage] = useState("");
    const [deliveryErrMessage, setdeliveryErrMessage] = useState("");

    useEffect(() => {
      if (id !== undefined) {
        const data = pincodeData.filter((element) => {
          return id === element.pincode_id;
        });
        console.log(data);
        setBandW(data[0].xerox_cost);
        setcolour(data[0].color_cost);
        setsticker(data[0].sticker_cost);
        sethandling(data[0].handling_cost);
        setdelivery(data[0].delivery_cost);
        setpincode(data[0].pincode_number);
      }
    }, [id]);

    const handelSubmit = (e) => {
      e.preventDefault();

      if (BandW === undefined) {
        setBandWErrMessage("Pincode is required");
        return;
      } else {
        setBandWErrMessage("");
      }

      if (colour === undefined) {
        setcolourErrMessage("Pincode is required");
        return;
      } else {
        setcolourErrMessage("");
      }

      if (sticker === undefined) {
        setstickerErrMessage("Pincode is required");
        return;
      } else {
        setstickerErrMessage("");
      }

      if (handling === undefined) {
        sethandlingErrMessage("Pincode is required");
        return;
      } else {
        sethandlingErrMessage("");
      }

      if (delivery === undefined) {
        setdeliveryErrMessage("Pincode is required");
        return;
      } else {
        setdeliveryErrMessage("");
      }

      const data = {
        pincode,
        xerox_cost: BandW,
        colour_cost: colour,
        sticker_cost: sticker,
        handing_cost: handling,
        delivery_cost: delivery,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/update/pincode`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            getData();
            setModalShow2(false);
            toast.success("Pincode updated successfully.");
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };
    return (
      <ThemeProvider theme={theme}>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update Pincode Costs
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <form
                className="py-4 px-3"
                onSubmit={(e) => handelSubmit(e)}
                autoComplete="off"
              >
                <Row className="g-2">
                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Pincode</label>
                    <TextField
                      placeholder="pincode"
                      variant="standard"
                      className="w-100"
                      value={pincode}
                      disabled
                    />
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">B&W Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="B&W Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={BandW}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setBandW(e.target.value)}
                    />
                    {BandWErrMessage && (
                      <p className="text-danger m-0 fs-xs">{BandWErrMessage}</p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Colour Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Colour Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={colour}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setcolour(e.target.value)}
                    />
                    {colourErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {colourErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Sticker Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Sticker Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={sticker}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setsticker(e.target.value)}
                    />
                    {stickerErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {stickerErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Handling Cost *</label>
                    <TextField
                      color="warning"
                      placeholder="Handling Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={handling}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => sethandling(e.target.value)}
                    />
                    {handlingErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {handlingErrMessage}
                      </p>
                    )}
                  </Col>

                  <Col lg={6} className="mb-3">
                    <label htmlFor="username">Delivery Cost *</label>
                    <TextField
                      color="warning"
                      id="standard-basic"
                      placeholder="Delivery Cost"
                      variant="standard"
                      className="w-100"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={delivery}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => setdelivery(e.target.value)}
                    />
                    {deliveryErrMessage && (
                      <p className="text-danger m-0 fs-xs">
                        {deliveryErrMessage}
                      </p>
                    )}
                  </Col>
                </Row>

                <div className="pt-3">
                  <Button
                    variant="contained"
                    className="bg-primary"
                    type="submit"
                  >
                    update
                  </Button>
                </div>
              </form>
            </Container>
          </Modal.Body>
        </Modal>
      </ThemeProvider>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex" }} className="bg-main px-0">
        <AdminSideNav />
        <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
          <Container>
            <Row>
              <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
                <div className="card p-3 rounded-5 shadow">
                  <div className="card-body">
                    <h5 className="m-0 pb-3">Pincode List</h5>
                    <div className="text-end">
                      <Button
                        variant="contained"
                        className="bg-primary"
                        onClick={() => setModalShow(true)}
                      >
                        Add New Pincode
                      </Button>
                    </div>
                    {pincodeData === undefined ? (
                      <>
                        <h6 className="text-center">No Data Found</h6>
                      </>
                    ) : (
                      <>
                        <div className="overflow-auto">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Pincode</th>
                                <th>Vendor Count</th>
                                <th>B&W Cost</th>
                                <th>Colour Cost</th>
                                <th>Sticker Cost</th>
                                <th>Handling Cost</th>
                                <th>Delivery Cost</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pincodeData.map((val, ind) => (
                                <>
                                  <tr key={ind}>
                                    <td>{ind + 1}</td>
                                    <td>{val.pincode_number}</td>
                                    <td>{val.vendor_count}</td>
                                    <td>₹ {val.xerox_cost}</td>
                                    <td>₹ {val.color_cost}</td>
                                    <td>₹ {val.sticker_cost}</td>
                                    <td>₹ {val.handling_cost}</td>
                                    <td>₹ {val.delivery_cost}</td>
                                    <td>
                                      <Tooltip title="Update">
                                        <IconButton
                                          aria-label="delete"
                                          size="large"
                                          color="success"
                                          onClick={() => {
                                            setModalShow2(true);
                                            setid(val.pincode_id);
                                          }}
                                        >
                                          <EditRoundedIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          aria-label="delete"
                                          size="large"
                                          color="error"
                                          onClick={(e) =>
                                            deletePincode(e, val.pincode_id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Box>
      </Box>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />
    </>
  );
};

export default Adminpincode;
