import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Addpage from "./pages/Preview";
import MyOrders from "./pages/OrdersList";
import MyAddress from "./pages/AddressList";
import OrderDetails from "./pages/OrderDetails";
import VendorLogin from "./pages/vendor/VendorLogin";
import Vendordashbord from "./pages/vendor/Vendordashbord";
import VendorOrders from "./pages/vendor/VendorOrders";
import VendororderDetails from "./pages/vendor/VendororderDetails";
import VendorOrderHistory from "./pages/vendor/VendorOrderHistory";
import VendorPayments from "./pages/vendor/VendorPayments";
import AdminLogin from "./pages/admin/AdminLogin";
import Admindashbord from "./pages/admin/AdminDashbord";
import Adminorders from "./pages/admin/Adminorders";
import AdminOrderDetails from "./pages/admin/AdminOrderDetails";
import AdminPayment from "./pages/admin/AdminPayment";
import AdminVendorUpdatePassword from "./pages/admin/adminvendor/AdminVendorUpdatePassword";
import AdminUpdateVendor from "./pages/admin/adminvendor/AdminUpdateVendor";
import AdminAddNewVendor from "./pages/admin/adminvendor/AdminAddNewVendor";
import AdminVendorList from "./pages/admin/adminvendor/AdminVendorList";
import AdminRiderList from "./pages/admin/adminrider/AdminRiderList";
import AdminNewRider from "./pages/admin/adminrider/AdminNewRider";
import AdminUpdateRider from "./pages/admin/adminrider/AdminUpdateRider";
import AdminUpdatePassword from "./pages/admin/adminrider/AdminUpdatePassword";
import PrivacyandPolicy from "./pages/PrivacyandPolicy";
import Terms from "./pages/Terms";
import Refund from "./pages/Refund";
import Contactus from "./pages/Contactus";
import Adminpincode from "./pages/admin/Adminpincode";
import PaymentDetails from "./pages/vendor/PaymentDetails";
import AdminPaymentDetails from "./pages/admin/AdminPaymentDetails";
import Error404 from "./pages/Error404";
import ReactGA from "react-ga4";
import Loadtest from "./pages/Loadtest";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GTM_ID);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/preview" element={<Addpage />} />
          <Route path="/account/orders" element={<MyOrders />} />
          <Route path="account/orders/details/:id" element={<OrderDetails />} />
          <Route path="/account/address" element={<MyAddress />} />
          <Route path="/vendor/login" element={<VendorLogin />} />
          <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/test" element={<Loadtest />} />

          <Route path="/vendor/dashbord" element={<Vendordashbord />} />

          <Route path="/vendor/orders" element={<VendorOrders />} />

          <Route
            path="/vendor/orders/details/:id"
            element={<VendororderDetails />}
          />

          <Route
            path="/vendor/orders/history"
            element={<VendorOrderHistory />}
          />

          <Route path="/vendor/payments" element={<VendorPayments />} />

          <Route path="/vendor/payments/:date" element={<PaymentDetails />} />

          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashbord" element={<Admindashbord />} />
          <Route path="/admin/orders" element={<Adminorders />} />
          <Route
            path="/admin/orders/details/:id"
            element={<AdminOrderDetails />}
          />
          <Route path="/admin/payments" element={<AdminPayment />} />
          <Route path="/admin/vendor/list" element={<AdminVendorList />} />
          <Route path="/admin/vendor/new" element={<AdminAddNewVendor />} />
          <Route
            path="/admin/vendor/update/:id"
            element={<AdminUpdateVendor />}
          />
          <Route
            path="/admin/vendor/update/password/:id"
            element={<AdminVendorUpdatePassword />}
          />

          <Route path="/admin/rider/list" element={<AdminRiderList />} />
          <Route path="/admin/rider/new" element={<AdminNewRider />} />
          <Route
            path="/admin/rider/update/:id"
            element={<AdminUpdateRider />}
          />
          <Route
            path="/admin/rider/update/password/:id"
            element={<AdminUpdatePassword />}
          />
          <Route path="/admin/pincode" element={<Adminpincode />} />
          <Route
            path="/admin/payments/:date"
            element={<AdminPaymentDetails />}
          />

          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
