import React, { useEffect, useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  createTheme,
  IconButton,
  Switch,
  ThemeProvider,
  Tooltip,
  Pagination,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Modal from "react-bootstrap/Modal";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useCookies } from "react-cookie";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminVendorList = () => {
  const [cookies, ,] = useCookies([]);
  const navigate = useNavigate();
  const [vendor, setvendor] = useState();
  const [autoCloseToast, setAutoCloseToast] = useState(false);
  const [dis, setDis] = useState(false);

  const [activepage, setactivepage] = useState(1);
  const [pagenum, setpagenum] = useState(1);
  const [selectResult, setselectResult] = useState(10);
  const [len, setlen] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [id, setid] = useState();

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getVendorList = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendors/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setvendor(res.data.data);
          setlen(res.data.len);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const handelUpdateVendorStates = (id, status) => {
    const data = {
      vendor_id: id,
      vendor_status: status === "Y" ? "N" : "Y",
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/status/update`,
        data,
        config
      )
      .then((res) => {
        setDis(false);
        if (res.data.status === "success") {
          const data = {
            limit: selectResult,
            skip: 0,
          };
          getVendorList(data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };
  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
    };
    getVendorList(data);
  }, []);

  const handelDeleteUser = (e, id) => {
    e.preventDefault();
    const data = {
      vendor_id: id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/delete`,
        data,
        config
      )
      .then((res) => {
        setDis(false);
        if (res.data.status === "success") {
          const data = {
            limit: selectResult,
            skip: 0,
          };
          getVendorList(data);
          toast.success("Vendor Deleted Successfuly");
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const Msg = ({ props }) => (
    <div className="toast-body">
      Are you sure want to Delete Vendor?
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => setFixDelete(e, props)}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={(e) => setCancelDelete(e)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const setFixDelete = (e, id) => {
    e.preventDefault();
    handelDeleteUser(e, id);
  };

  const setCancelDelete = (e) => {
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const handleDeleteRider = (e, id) => {
    e.preventDefault();
    setDis(true);
    toast(<Msg props={id} />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const Msg2 = ({ props, status }) => (
    <div className="toast-body">
      Are you sure want to Update Vendor Status?
      <div className="mt-2 pt-2 border-top">
        <button
          onClick={(e) => setFixDelete2(e, props, status)}
          type="button"
          className="btn btn-primary border-0 bg-primary btn-sm me-1"
        >
          Confirm
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm ms-1"
          onClick={(e) => setCancelDelete2(e)}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const setFixDelete2 = (e, id, status) => {
    e.preventDefault();
    // handelDeleteUser(e, id);
    handelUpdateVendorStates(id, status);
  };

  const setCancelDelete2 = (e) => {
    setAutoCloseToast(100);
    setDis(false);
    setTimeout(() => {
      setAutoCloseToast(false);
    }, 500);
  };

  const handleUpdateVendor2 = (e, id, status) => {
    e.preventDefault();
    setDis(true);
    toast(<Msg2 props={id} status={status} />, {
      position: "top-center",
      autoClose: autoCloseToast,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // pagination
  const clickpage = (number) => {
    setactivepage(number);

    const data = {
      limit: selectResult,
      skip: selectResult * (number - 1),
    };
    getVendorList(data);
  };

  useEffect(() => {
    if (vendor && vendor.length > 0) {
      const resultParPage = len / selectResult;
      const roundedValue = Math.ceil(resultParPage);
      setpagenum(roundedValue);
    }
  }, [vendor, selectResult, len]);

  const onPageChange = (e, num) => {
    e.preventDefault();
    setselectResult(num);

    const data = {
      limit: num,
      skip: num * (activepage - 1),
    };
    getVendorList(data);
  };

  const handlePageChange = (event, value) => {
    setactivepage(value);

    const data = {
      limit: selectResult,
      skip: selectResult * (value - 1),
    };
    getVendorList(data);
  };

  // srvice model
  function MyVerticallyCenteredModal(props) {
    const [xerox, setXerox] = useState();
    const [colour, setColour] = useState();
    const [sticker, setSticker] = useState();

    const getVendorServices = (data) => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/vendor/service`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            setXerox(res.data.data[0].xerox);
            setColour(res.data.data[0].colour);
            setSticker(res.data.data[0].sticker);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };
    useEffect(() => {
      if (id !== undefined && modalShow === true) {
        const data = {
          vendorId: id,
        };
        getVendorServices(data);
      }
    }, [id]);

    const handelUpdateService = (e) => {
      e.preventDefault();
      const data = {
        vendorId: id,
        xerox,
        colour,
        sticker,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/vendor/update/service`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("Service updated successfully");
            const resualt = {
              vendorId: id,
            };
            getVendorServices(resualt);
            const data2 = {
              limit: selectResult,
              skip: 0,
            };
            getVendorList(data2);
            setModalShow(false);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    };

    return (
      <ThemeProvider theme={theme}>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Vendor Service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => handelUpdateService(e)}>
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Black & White</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={xerox}
                      checked={xerox === "Y" ? true : false}
                      color="warning"
                      onChange={() => setXerox(xerox === "Y" ? "N" : "Y")}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Colour</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={colour}
                      checked={colour === "Y" ? true : false}
                      color="warning"
                      onChange={() => setColour(colour === "Y" ? "N" : "Y")}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="blackandwhite">Sticker</label>
                  </div>
                  <div className="col-6 text-end">
                    <Switch
                      value={sticker}
                      checked={sticker === "Y" ? true : false}
                      color="warning"
                      onChange={() => setSticker(sticker === "Y" ? "N" : "Y")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 text-end">
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  className="me-2"
                >
                  Submit
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </ThemeProvider>
    );
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }} className="bg-main px-0">
          <AdminSideNav />
          <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
            <Container>
              <Row>
                <div className=" py-5 pe-5 rounded-3 my-4 overflow-auto">
                  <div className="card p-3 rounded-5 shadow">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h5 className="pb-3">Vendor List</h5>
                        <div>
                          <Button
                            variant="contained"
                            className="bg-primary"
                            onClick={() => navigate("/admin/vendor/new")}
                            startIcon={<AddBusinessRoundedIcon />}
                          >
                            Add New Vendor
                          </Button>
                        </div>
                      </div>
                      <Row className="g-3">
                        {len === 0 ? (
                          <>
                            <h6 className="m-0 text-center">No Vendor Found</h6>
                          </>
                        ) : (
                          <>
                            <Col lg={12} className="pt-3">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th className="text-center">
                                      Working Status
                                    </th>
                                    <th>Vendor Name</th>
                                    <th>Username</th>
                                    <th>Shop Name</th>
                                    <th>Status</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendor &&
                                    vendor.map((val, ind) => (
                                      <>
                                        <tr key={ind}>
                                          <td>{ind + 1}</td>
                                          <td className="text-center">
                                            {val.vendor_working_status ===
                                            "Y" ? (
                                              <>
                                                <Tooltip
                                                  title="Online"
                                                  placement="right"
                                                >
                                                  <WifiIcon />
                                                </Tooltip>
                                              </>
                                            ) : (
                                              <>
                                                <Tooltip
                                                  title="Offline"
                                                  placement="right"
                                                >
                                                  <WifiOffIcon />
                                                </Tooltip>
                                              </>
                                            )}
                                          </td>
                                          <td>{val.vendor_name}</td>
                                          <td>{val.vendor_username}</td>
                                          <td>{val.vendor_shop_name}</td>
                                          <td>
                                            <div>
                                              <Switch
                                                color="warning"
                                                disabled={
                                                  dis === true ? true : false
                                                }
                                                checked={
                                                  val.vendor_status === "Y"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  handleUpdateVendor2(
                                                    e,
                                                    val.vendor_id,
                                                    val.vendor_status
                                                  );
                                                }}
                                              />{" "}
                                            </div>
                                          </td>
                                          <td className="text-center">
                                            <Tooltip title="Update">
                                              <IconButton
                                                aria-label="edit"
                                                color="success"
                                                className="text-success me-1"
                                                onClick={() =>
                                                  navigate(
                                                    `/admin/vendor/update/${val.vendor_id}`
                                                  )
                                                }
                                                disabled={
                                                  dis === true ? true : false
                                                }
                                              >
                                                <EditRoundedIcon />
                                              </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Change Password">
                                              <IconButton
                                                aria-label="update password"
                                                color="inherit"
                                                className="text-dark me-1"
                                                onClick={() =>
                                                  navigate(
                                                    `/admin/vendor/update/password/${val.vendor_id}`
                                                  )
                                                }
                                                disabled={
                                                  dis === true ? true : false
                                                }
                                              >
                                                <LockResetIcon />
                                              </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Update Services">
                                              <IconButton
                                                aria-label="Update Services"
                                                color="inherit"
                                                className="text-dark me-1"
                                                onClick={() => {
                                                  setModalShow(true);
                                                  setid(val.vendor_id);
                                                }}
                                                disabled={
                                                  dis === true ? true : false
                                                }
                                              >
                                                <ManageAccountsIcon />
                                              </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Delete">
                                              <IconButton
                                                aria-label="delete"
                                                color="error"
                                                className="text-danger ms-1"
                                                onClick={(e) =>
                                                  handleDeleteRider(
                                                    e,
                                                    val.vendor_id
                                                  )
                                                }
                                                disabled={
                                                  dis === true ? true : false
                                                }
                                              >
                                                <DeleteRoundedIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </Col>
                          </>
                        )}
                      </Row>
                      {len === 0 ? (
                        <></>
                      ) : (
                        <>
                          <Row>
                            <div className="col-12 col-sm-3 col-12 px-2">
                              <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                <label
                                  htmlFor="select-input"
                                  className="form-label fs-xs px-1"
                                >
                                  Rows per page
                                </label>
                                <select
                                  className="form-select w-50 form-select-sm px-1"
                                  id="select-input"
                                  onChange={(e) =>
                                    onPageChange(e, e.target.value)
                                  }
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-sm-9 col-12 overflow-auto">
                              <div className="text-end px-2">
                                <Pagination
                                  count={pagenum}
                                  page={activepage}
                                  onChange={handlePageChange}
                                  showFirstButton
                                  showLastButton
                                />
                                <br />
                              </div>
                            </div>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AdminVendorList;
