import React, { useEffect, useState } from "react";
import AdminSideNav from "../../../component/AdminSideNav";
import {
  Box,
  Button,
  createTheme,
  IconButton,
  MenuItem,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import Select from "react-select";

const AdminUpdateVendor = () => {
  const [cookies, ,] = useCookies([]);
  const navigate = useNavigate();
  const params = useParams();

  const vendor_id = params.id;

  const [username, setusername] = useState("");
  const [name, setname] = useState("");
  const [shopName, setshopName] = useState("");
  const [shopaddress, setshopaddress] = useState("");
  const [serviceType, setserviceType] = useState("");
  const [pincode, setpincode] = useState("");

  const [usernameErrMessage, setusernameErrMessage] = useState("");
  const [shopNameErrMessage, setshopNameErrMessage] = useState("");
  const [shopAddressErrMessage, setshopAddressErrMessage] = useState("");
  const [pincodeData, setpincodeData] = useState();
  const [pincodeErrMessage, setPincodeErrMessage] = useState("");
  const [nameErrMessage, setNameErrMessage] = useState("");

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const theme = createTheme({
    palette: {
      warning: {
        main: "#133b62",
      },
    },
  });

  const getData = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/get/pincode`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setpincodeData(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (vendor_id !== undefined) {
      const data = {
        vendor_id: vendor_id,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/admin/vendor/details`,
          data,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            setusername(res.data.data[0].vendor_username);
            setname(res.data.data[0].vendor_name);
            setshopName(res.data.data[0].vendor_shop_name);
            setshopaddress(res.data.data[0].vendor_shop_address);
            setserviceType(res.data.data[0].vendor_service_type);
            setpincode(res.data.data[0].vendor_pincode_id);
          }
          if (res.data.status === "error") {
            toast.error(res.data.message);
          }
        });
    }
  }, [vendor_id]);

  const validateForm = () => {
    let isValid = true;
    setusernameErrMessage("");
    setshopNameErrMessage("");
    setshopAddressErrMessage("");
    setNameErrMessage("");

    const invalidCharRegex = /[+=!@#$%^&*(){}\[\]\.,?/”“‘-]/;

    if (username.trim() === "") {
      setusernameErrMessage("Username is required");
      isValid = false;
    } else if (/\s/.test(username)) {
      setusernameErrMessage("Username must not contain spaces");
      isValid = false;
    } else if (invalidCharRegex.test(username)) {
      setusernameErrMessage("Username must not contain special characters");
      isValid = false;
    } else if (username.length <= 3) {
      setusernameErrMessage("Username should have a minimum of 4 characters");
      isValid = false;
    } else {
      setusernameErrMessage("");
    }

    if (name.trim() === "") {
      setNameErrMessage("Name is required");
      isValid = false;
    } else if (name.length <= 2) {
      setNameErrMessage("Name should have a minimum of 3 characters");
      isValid = false;
    } else if (invalidCharRegex.test(name)) {
      setNameErrMessage("Name must not contain special characters");
    } else {
      setNameErrMessage("");
    }

    if (shopName.trim() === "") {
      setshopNameErrMessage("Shop Name is required");
      isValid = false;
    }

    if (shopaddress.trim() === "") {
      setshopAddressErrMessage("Shop Address is required");
      isValid = false;
    }

    if (pincode.value === "NONE") {
      setPincodeErrMessage("Please Select pincode");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const data = {
      vendor_id: vendor_id,
      vendor_username: username,
      vendor_name: name,
      vendor_shop_name: shopName,
      vendor_shop_address: shopaddress,
      vendor_service_type: serviceType,
      vendor_pincode_id: pincode.value,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/update`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success("Vendor Updated Successfully");
          navigate("/admin/vendor/list");
        } else {
          toast.error(res.data.message);
          if (res.data.status === "error") {
            setusernameErrMessage(res.data.message);
          }
        }
      });
  };

  let options = [{ value: "NONE", label: "None" }];

  pincodeData &&
    pincodeData.forEach((element) => {
      options.push({
        value: element.pincode_id,
        label: element.pincode_number,
      });
    });

  useEffect(() => {
    const pin =
      pincodeData &&
      pincodeData.filter((element) => element.pincode_id === pincode);

    if (pin && pin.length > 0) {
      setpincode({ value: pin[0].pincode_id, label: pin[0].pincode_number });
    }
  }, [pincodeData, pincode]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }} className="bg-main px-0">
          <AdminSideNav />
          <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
            <Container>
              <Row>
                <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
                  <div className="card p-3 rounded-5 shadow">
                    <div className="card-body">
                      <div>
                        <IconButton
                          aria-label="goback"
                          size="large"
                          color="warning"
                          onClick={() => navigate("/admin/vendor/list")}
                        >
                          <KeyboardBackspaceIcon />
                        </IconButton>
                      </div>

                      <h6 className="text-center fs-5 pb-1">
                        Please Enter Details Of Vendor
                      </h6>
                      <form onSubmit={handleSubmit}>
                        <Row className="g-3">
                          <Col lg={6}>
                            <label htmlFor="username">Username *</label>
                            <input
                              placeholder="Username"
                              className="w-100 mb-4 admin-login-input"
                              autoComplete="off"
                              name="username"
                              value={username}
                              onChange={(e) => setusername(e.target.value)}
                              required
                            />
                            {usernameErrMessage && (
                              <p className="text-danger fs-xs">
                                {usernameErrMessage}
                              </p>
                            )}
                          </Col>

                          <Col lg={6}>
                            <label htmlFor="name">Name *</label>
                            <input
                              placeholder="Name"
                              className="w-100 mb-4 admin-login-input"
                              name="name"
                              value={name}
                              autoComplete="off"
                              onChange={(e) => setname(e.target.value)}
                              required
                            />
                            {nameErrMessage && (
                              <p className="text-danger fs-xs">
                                {nameErrMessage}
                              </p>
                            )}
                          </Col>
                          <Col lg={6}>
                            <label htmlFor="shopname">Shop Name *</label>
                            <input
                              placeholder="Shop Name"
                              className="w-100 mb-4 admin-login-input"
                              name="shopname"
                              value={shopName}
                              autoComplete="off"
                              onChange={(e) => setshopName(e.target.value)}
                              required
                            />
                            {shopNameErrMessage && (
                              <p className="text-danger fs-xs">
                                {shopNameErrMessage}
                              </p>
                            )}
                          </Col>
                          <Col lg={6}>
                            <label htmlFor="shopaddress">Shop Address *</label>
                            <input
                              placeholder="Shop Address"
                              className="w-100 mb-4 admin-login-input"
                              name="shopaddress"
                              value={shopaddress}
                              autoComplete="off"
                              onChange={(e) => setshopaddress(e.target.value)}
                              required
                            />
                            {shopAddressErrMessage && (
                              <p className="text-danger fs-xs">
                                {shopAddressErrMessage}
                              </p>
                            )}
                          </Col>
                          <Col lg={6}>
                            <label htmlFor="shopaddress" className="fs-xs">
                              Service Type *
                            </label>
                            <TextField
                              color="warning"
                              select
                              helperText="Please select service type"
                              value={serviceType}
                              variant="standard"
                              className="w-100 mb-4"
                              onChange={(e) => setserviceType(e.target.value)}
                            >
                              <MenuItem value="PICKUP">PICKUP</MenuItem>
                              <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                              <MenuItem value="BOTH">BOTH</MenuItem>
                            </TextField>
                          </Col>
                          <Col lg={6}>
                            <label htmlFor="shopaddress" className="fs-xs">
                              Pincode *
                            </label>
                            <Select
                              className="w-100 mb-4"
                              value={pincode}
                              onChange={(e) => {
                                setPincodeErrMessage("");
                                setpincode(e);
                              }}
                              options={options && options}
                            />
                            {pincodeErrMessage && (
                              <p className="text-danger fs-xs">
                                {pincodeErrMessage}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <div className="text-end">
                          <Button
                            variant="contained"
                            className="px-4 bg-primary me-1"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            className="px-4 text-black ms-1"
                            type="button"
                            color="inherit"
                            onClick={() => navigate("/admin/vendor/list")}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminUpdateVendor;
