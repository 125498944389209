import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  createTheme,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import pdficon from "../../assets/images/pdf-icon.png";
import CustomizedSteppers from "../../component/CustomizedSteppers";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AdminSideNav from "../../component/AdminSideNav";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminOrderDetails = () => {
  const navigate = useNavigate();
  const [cookies, ,] = useCookies([]);
  const token = cookies.token;
  const params = useParams();

  const [orderdata, setorderdata] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [dis, setdis] = useState(false);
  const [addressData, setaddressData] = useState([]);
  const [loading, setloading] = useState(false);
  const [dis3, setdis3] = useState(false);
  const [stepss, setstepss] = useState();
  const [mrp, setmrp] = useState();

  const id = params.id;

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    const data = {
      id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/order/details`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setorderdata(res.data.data);
          setaddressData(res.data.result);
          const currentStatus = res.data.data[0]?.order_status;
          const steps = [];
          if (res.data.data[0].order_delivery_type === "STORE") {
            if (res.data.data[0].order_status === "CANCELLED") {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.eventData[0]?.event_created_at,
                },
                {
                  step: `CANCELLED`,
                  date: res.data.eventData[1]?.event_created_at,
                }
              );
            } else {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.eventData[0]?.event_created_at,
                },
                {
                  step: `PRINTING`,
                  date: res.data.eventData[1]?.event_created_at,
                },
                {
                  step: `PACKED`,
                  date: res.data.eventData[2]?.event_created_at,
                },
                {
                  step: `PICKEDUP`,
                  date: res.data.eventData[3]?.event_created_at,
                }
              );
            }
          } else {
            if (res.data.data[0].order_status === "CANCELLED") {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.eventData[0]?.event_created_at,
                },
                {
                  step: `CANCELLED`,
                  date: res.data.eventData[1]?.event_created_at,
                }
              );
            } else {
              steps.push(
                {
                  step: `PLACED`,
                  date: res.data.eventData[0]?.event_created_at,
                },
                {
                  step: `PRINTING`,
                  date: res.data.eventData[1]?.event_created_at,
                },
                {
                  step: `PACKED`,
                  date: res.data.eventData[2]?.event_created_at,
                },
                {
                  step: `DISPATCHED`,
                  date: res.data.eventData[3]?.event_created_at,
                },
                {
                  step: `DELIVERED`,
                  date: res.data.eventData[4]?.event_created_at,
                }
              );
            }
          }
          setstepss(steps);

          const stepIndex = steps.findIndex(
            (step) => step.step === currentStatus
          );

          if (stepIndex !== -1) {
            setActiveStep(stepIndex);
          }

          if (res.data.data[0]?.order_status === "DELIVERED") {
            setdis(true);
          }

          if (res.data.data[0]?.order_status === "CANCELLED") {
            if (res.data.data[0]?.order_status === "CANCELLED") {
              setdis3(true);
              setdis(true);
            }
          }
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  }, [token]);

  const downloadFiles = async (e) => {
    e.preventDefault();
    const data = {
      orderId: id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/order/files/download`,
        data,
        {
          headers: {
            "X-AUTH": cookies.token,
          },
          responseType: "blob",
        }
      )
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Order-#${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (res.data.status === "success") {
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const handelRefundPayment = (e, id) => {
    e.preventDefault();
    setloading(true);

    const data = {
      orderId: id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/return/payment`,
        data,
        config
      )
      .then((res) => {
        setloading(false);
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }

        if (res.data.status === "success") {
          toast.success("Refund Successfully");

          const datas = {
            id,
          };
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_API}/admin/order/details`,
              datas,
              config
            )
            .then((res) => {
              if (res.data.status === "success") {
                setorderdata(res.data.data);
                const currentStatus = res.data.data[0]?.order_status;
                const steps = [
                  "PLACED",
                  "PRINTING",
                  "PACKED",
                  "DISPATCHED",
                  "DELIVERED",
                ];
                const stepIndex = steps.indexOf(currentStatus);
                if (stepIndex !== -1) {
                  setActiveStep(stepIndex);
                }

                if (res.data.data[0]?.order_status === "DELIVERED") {
                  setdis(true);
                }

                if (res.data.data[0]?.order_status === "CANCELLED") {
                  setdis(true);
                }
              }
              if (res.data.status === "error") {
                toast.error(res.data.message);
              }
            });
        }
      });
  };

  useEffect(() => {
    if (orderdata[0] !== undefined) {
      const totalamount =
        orderdata[0].order_total_amount - orderdata[0].order_handling_charges;

      setmrp(totalamount - orderdata[0].order_delivery_charges);
    }
  }, [orderdata]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }} className="bg-main px-0">
          <AdminSideNav />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            className="px-0 py-3"
          >
            <Container>
              <Row>
                <Col lg={12}>
                  {orderdata.length === 0 ? (
                    <div className="py-4 vh-100 pe-5">
                      <div className="card mt-5 rounded-4 shadow">
                        <div className=" card-body">
                          <h6 className="m-0 text-center">No order Found</h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className=" py-5 pe-5 rounded-3 my-4 ">
                        <div className="card  rounded-4 shadow">
                          <div className="card-body">
                            <h5 className=" m-0 pb-3">Order Details</h5>

                            <Row className="p-0 m-0">
                              <Col lg={12} className="col-md-8 col-sm-12">
                                <Row className="g-4 px-0 py-4">
                                  <div className="col-6">
                                    {activeStep === 4 ? (
                                      <>
                                        <IconButton
                                          aria-label="goback"
                                          size="large"
                                          color="warning"
                                          onClick={() => navigate(-1)}
                                        >
                                          <KeyboardBackspaceIcon />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="goback"
                                          onClick={() =>
                                            navigate("/admin/orders")
                                          }
                                        >
                                          <ArrowBackIcon />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                  <div className="col-6 text-end">
                                    {orderdata[0].order_refund_status ===
                                    "Y" ? (
                                      <></>
                                    ) : (
                                      <>
                                        {loading === true ? (
                                          <>
                                            <Button
                                              variant="outlined"
                                              color="inherit"
                                              className={
                                                dis3 === false
                                                  ? "me-1 d-none"
                                                  : "me-1 "
                                              }
                                              disabled
                                            >
                                              Loading ...
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              variant="contained"
                                              color="error"
                                              className={
                                                dis3 === false
                                                  ? "me-1 d-none"
                                                  : "me-1 "
                                              }
                                              startIcon={<ReplayRoundedIcon />}
                                              onClick={(e) =>
                                                handelRefundPayment(e, id)
                                              }
                                              disabled={
                                                activeStep >= 3 ? true : false
                                              }
                                            >
                                              refund payment
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}

                                    <Button
                                      variant="contained"
                                      color="success"
                                      className={
                                        activeStep >= 3 ? " d-none" : ""
                                      }
                                      startIcon={<DownloadRoundedIcon />}
                                      onClick={(e) => downloadFiles(e)}
                                      disabled={dis === true ? true : false}
                                    >
                                      Download
                                    </Button>
                                  </div>

                                  <div className="col-12">
                                    <h5>Order Summary</h5>
                                    <p className="m-0">
                                      Customer Mobile No. :{" "}
                                      <span className="fw-bold">
                                        +91-
                                        {orderdata &&
                                          orderdata[0].user_mobile_no}
                                      </span>
                                    </p>
                                    <Row className="py-3">
                                      <Col xs={1}>
                                        <div className="text-end">
                                          <img
                                            alt=""
                                            src={pdficon}
                                            className="img-fluid w-50"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        xs={11}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <div>
                                          <p
                                            className="m-0"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {orderdata &&
                                              orderdata[0]
                                                .order_print_type}{" "}
                                            Single Side{" "}
                                            {orderdata &&
                                              orderdata[0]
                                                .order_orientation_type}
                                          </p>
                                          <p className="m-0 fs-xs text-secondary">
                                            {orderdata &&
                                              orderdata[0].order_pages}{" "}
                                            pc x{" "}
                                            {orderdata &&
                                              orderdata[0].order_copies_count}
                                          </p>
                                        </div>
                                        <div>
                                          <h6 className="m0">
                                            ₹
                                            {orderdata &&
                                              orderdata[0].order_total_amount}
                                          </h6>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="overflow-auto py-3">
                                      <CustomizedSteppers
                                        activeStep={activeStep}
                                        steps={stepss}
                                      />
                                    </div>
                                    <div className="py-3">
                                      <h6>Bill Details</h6>
                                      <hr className="my-2" />
                                      <Row>
                                        <div className="col-6">
                                          <p className="m-0">MRP</p>
                                        </div>
                                        <div className="col-6">
                                          <p className="text-end m-0">₹{mrp}</p>
                                        </div>
                                        <div className="col-6">
                                          <p className="m-0">Delivery Charge</p>
                                        </div>
                                        <div className="col-6">
                                          {orderdata &&
                                          orderdata[0]
                                            .order_delivery_charges === 0 ? (
                                            <>
                                              <p className="text-end m-0 green">
                                                FREE
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p className="text-end m-0">
                                                ₹
                                                {orderdata &&
                                                  orderdata[0]
                                                    .order_delivery_charges}
                                              </p>
                                            </>
                                          )}
                                        </div>
                                        <div className="col-6">
                                          <p className="m-0">Handling Charge</p>
                                        </div>
                                        <div className="col-6">
                                          <p className="text-end m-0">
                                            ₹
                                            {orderdata &&
                                              orderdata[0]
                                                .order_handling_charges}
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p className="m-0 fw-bold">
                                            Bill Total
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <p className="text-end m-0 fw-bold">
                                            ₹
                                            {orderdata &&
                                              orderdata[0].order_total_amount}
                                          </p>
                                        </div>
                                      </Row>
                                    </div>
                                    {orderdata &&
                                    orderdata[0].order_delivery_type ===
                                      "STORE" ? (
                                      <>
                                        <div className="py-3">
                                          <h6>Vendor Details</h6>
                                          <hr className="my-2" />
                                          <Row className="g-2">
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Vendor Name
                                              </p>
                                              <p className=" m-0 ">
                                                {orderdata &&
                                                  orderdata[0].vendor_name}
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Shop Name
                                              </p>
                                              <p className=" m-0 ">
                                                {orderdata &&
                                                  orderdata[0].vendor_shop_name}
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Shop Address
                                              </p>
                                              <p className=" m-0 ">
                                                {orderdata &&
                                                  orderdata[0]
                                                    .vendor_shop_address}
                                              </p>
                                            </div>
                                          </Row>
                                        </div>

                                        <div className="py-3">
                                          <h6>Order Details</h6>
                                          <hr className="my-2" />
                                          <Row className="g-2">
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Order Id
                                              </p>
                                              <p className=" m-0 ">
                                                #
                                                {orderdata &&
                                                  orderdata[0].order_id}
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Payment Detail
                                              </p>
                                              <p className=" m-0 ">
                                                Paid Online{" "}
                                                <span className="ps-2 user-select-none">
                                                  {" "}
                                                  #
                                                  {orderdata &&
                                                    orderdata[0]
                                                      .order_payment_ref}
                                                </span>
                                              </p>
                                            </div>
                                          </Row>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="py-3">
                                          <h6>Order Details</h6>
                                          <hr className="my-2" />
                                          <Row className="g-2">
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Order Id
                                              </p>
                                              <p className=" m-0 ">
                                                #
                                                {orderdata &&
                                                  orderdata[0].order_id}
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                Payment Detail
                                              </p>
                                              <p className=" m-0 ">
                                                Paid Online{" "}
                                                <span className="ps-2 user-select-none">
                                                  {" "}
                                                  #
                                                  {orderdata &&
                                                    orderdata[0]
                                                      .order_payment_ref}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="col-12">
                                              <p className="text-secondary fs-xs m-0">
                                                {orderdata[0].order_status ===
                                                "PICKEDUP" ? (
                                                  <>Delivered To</>
                                                ) : orderdata[0]
                                                    .order_status ===
                                                  "DELIVERED" ? (
                                                  <>Delivered To</>
                                                ) : (
                                                  <>Deliver To</>
                                                )}
                                              </p>
                                              <p className=" m-0 ">
                                                {addressData[0].address_line},{" "}
                                                {
                                                  addressData[0]
                                                    .address_landmark
                                                }
                                                ,{" "}
                                                {addressData[0].address_pincode}
                                              </p>
                                            </div>
                                          </Row>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AdminOrderDetails;
