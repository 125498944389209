import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import MiniDrawer from "../../component/MiniDrawer";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Pagination from "@mui/material/Pagination"; // MUI Pagination

const headers = [
  { label: "Order Id", key: "order_id" },
  { label: "Order Amount", key: "order_total_amount" },
];

const VendorOrderHistory = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const token = cookies.token;
  const [orders, setOrders] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("TODAY");

  const [activePage, setActivePage] = useState(1); // Current page
  const [pageNum, setPageNum] = useState(1); // Total pages
  const [selectResult, setSelectResult] = useState(10); // Items per page
  const [len, setLen] = useState(0);

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/orders/history`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrders(res.data.data);
          setLen(res.data.len); // Total items
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getHistory = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/orders/history/export`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setHistory(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
      dateRange: selectedStatus,
    };
    getOrderData(data);
    getHistory(data);
  }, [selectResult, selectedStatus]);

  // Pagination logic for MUI
  const handlePageChange = (event, value) => {
    setActivePage(value); // Update current page
    const data = {
      limit: selectResult,
      skip: selectResult * (value - 1),
      dateRange: selectedStatus,
    };
    getOrderData(data);
  };

  useEffect(() => {
    if (orders.length > 0) {
      const resultPerPage = Math.ceil(len / selectResult); // Calculate total pages
      setPageNum(resultPerPage);
    }
  }, [orders, selectResult, len]);

  const onPageSizeChange = (e) => {
    setSelectResult(parseInt(e.target.value)); // Update rows per page
    const data = {
      limit: parseInt(e.target.value),
      skip: parseInt(e.target.value) * (activePage - 1),
      dateRange: selectedStatus,
    };
    getOrderData(data);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Update filter by status
  };

  return (
    <div className="w-100 bg-main px-0">
      <MiniDrawer />
      <Box className="px-0 ps-4 ps-md-5">
        <Container className="ps-5">
          <Row>
            <div className="rounded-3">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <h5 className="m-0 pb-3">Orders History</h5>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-1">
                          <div className="m-2 w-100 ms-0 ms-md-auto">
                            {history[0]?.order_total_amount === 0 ? (
                              <></>
                            ) : (
                              <CSVLink
                                data={history}
                                headers={headers}
                                filename={`order-history.csv`}
                                className="text-decoration-none text-white bg-primary px-3 py-2 rounded-1 shadow-sm"
                              >
                                Export Order History
                              </CSVLink>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 p-1">
                          <div className="m-2 w-100 ms-0 ms-md-auto">
                            <Form.Select
                              size="sm"
                              className="filter-select w-100"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                            >
                              <option value="TODAY">Today</option>
                              <option value="YESTERDAY">Yesterday</option>
                              <option value="THISWEEK">This Week</option>
                              <option value="THISMONTH">This Month</option>
                              <option value="THISYEAR">This Year</option>
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {orders.length === 0 ? (
                    <h6 className="m-0 text-center">No History Found</h6>
                  ) : (
                    <div className="overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="ps-3">Order Id</th>
                            <th>Order Amount</th>
                            <th>Order Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((val, ind) => (
                            <tr key={ind}>
                              <td>
                                {ind +
                                  1 +
                                  selectResult * activePage -
                                  selectResult}
                              </td>
                              <td className="ps-3">
                                #{val.order_id}{" "}
                                {val.order_delivery_charges === 10 ? (
                                  <StarRateRoundedIcon className="text-primary" />
                                ) : null}
                              </td>
                              <td>{val.order_total_amount}</td>
                              <td>{val.order_status}</td>
                              <td>
                                <VisibilityIcon
                                  onClick={() =>
                                    navigate(
                                      `/vendor/orders/details/${val.order_id}`
                                    )
                                  }
                                  className="fs-3"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {orders.length === 0 ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="col-12 col-sm-3 col-12 px-2">
                      <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                        <label
                          htmlFor="select-input"
                          className="form-label fs-xs px-1"
                        >
                          Rows per page
                        </label>
                        <select
                          className="form-select w-75 form-select-sm px-1"
                          id="select-input"
                          value={selectResult}
                          onChange={onPageSizeChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-9 col-12 overflow-auto">
                      <div className="text-center px-2">
                        <Pagination
                          count={pageNum}
                          page={activePage}
                          onChange={handlePageChange}
                          showFirstButton
                          showLastButton
                          className="mx-auto justify-content-center justify-content-md-end"
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </div>
  );
};

export default VendorOrderHistory;
