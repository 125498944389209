import React, { useEffect, useState } from "react";
import { Box, createTheme, IconButton, ThemeProvider } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Modal from "react-bootstrap/Modal";
import pdficon from "../../assets/images/pdf-icon.png";
import CustomizedSteppers from "../../component/CustomizedSteppers";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AdminSideNav from "../../component/AdminSideNav";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const theme = createTheme({
  palette: {
    warning: {
      main: "#133b62",
    },
  },
});

const AdminPaymentDetails = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const authToken = cookies.token;
  const parms = useParams();
  const date = parms.date;

  console.log(date);

  const [payments, setpayments] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [id, setid] = useState();
  const [total, settotal] = useState();

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/payment/singel/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setpayments(res.data.data);
          settotal(res.data.totals);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    if (date) {
      const data = {
        date: date,
      };
      getData(data);
    }
  }, [date]);

  function MyVerticallyCenteredModal(props) {
    const [orderdata, setorderdata] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [dis3, setdis3] = useState(false);
    const [addressData, setaddressData] = useState([]);
    const [stepss, setstepss] = useState();
    const [mrp, setmrp] = useState();

    useEffect(() => {
      if (id) {
        console.log(id);

        const data = {
          id,
        };
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_API}/admin/order/details`,
            data,
            config
          )
          .then((res) => {
            if (res.data.status === "success") {
              setorderdata(res.data.data);
              setaddressData(res.data.result);
              const currentStatus = res.data.data[0]?.order_status;
              const steps = [];
              if (res.data.data[0].order_delivery_type === "STORE") {
                if (res.data.data[0].order_status === "CANCELLED") {
                  steps.push(
                    {
                      step: `PLACED`,
                      date: res.daeventData[0]?.event_created_at,
                    },
                    {
                      step: `CANCELLED`,
                      date: res.data.eventData[1]?.event_created_at,
                    }
                  );
                } else {
                  steps.push(
                    {
                      step: `PLACED`,
                      date: res.data.eventData[0]?.event_created_at,
                    },
                    {
                      step: `PRINTING`,
                      date: res.data.eventData[1]?.event_created_at,
                    },
                    {
                      step: `PACKED`,
                      date: res.data.eventData[2]?.event_created_at,
                    },
                    {
                      step: `PICKEDUP`,
                      date: res.data.eventData[3]?.event_created_at,
                    }
                  );
                }
              } else {
                if (res.data.data[0].order_status === "CANCELLED") {
                  steps.push(
                    {
                      step: `PLACED`,
                      date: res.data.eventData[0]?.event_created_at,
                    },
                    {
                      step: `CANCELLED`,
                      date: res.data.eventData[1]?.event_created_at,
                    }
                  );
                } else {
                  steps.push(
                    {
                      step: `PLACED`,
                      date: res.data.eventData[0]?.event_created_at,
                    },
                    {
                      step: `PRINTING`,
                      date: res.data.eventData[1]?.event_created_at,
                    },
                    {
                      step: `PACKED`,
                      date: res.data.eventData[2]?.event_created_at,
                    },
                    {
                      step: `DISPATCHED`,
                      date: res.data.eventData[3]?.event_created_at,
                    },
                    {
                      step: `DELIVERED`,
                      date: res.data.eventData[4]?.event_created_at,
                    }
                  );
                }
              }
              setstepss(steps);
              const stepIndex = steps.findIndex(
                (step) => step.step === currentStatus
              );
              if (stepIndex !== -1) {
                setActiveStep(stepIndex);
              }
              if (res.data.data[0]?.order_status === "CANCELLED") {
                setdis3(true);
              }
            }
            if (res.data.status === "error") {
              toast.error(res.data.message);
            }
          });
      }
    }, [id]);

    useEffect(() => {
      if (orderdata[0] !== undefined) {
        const totalamount =
          orderdata[0].order_total_amount - orderdata[0].order_handling_charges;

        setmrp(totalamount - orderdata[0].order_delivery_charges);
      }
    }, [orderdata]);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="my-5"
      >
        <Modal.Body>
          <button className="goback" onClick={props.onHide}>
            <CloseRoundedIcon />
          </button>
          <Container className="ps-5">
            <Row>
              <Col lg={12}>
                {orderdata.length === 0 ? (
                  <div className="card  rounded-4 border-0">
                    <div className=" card-body">
                      <h6 className="m-0 text-center">No orders Found</h6>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="card  rounded-4 border-0">
                      <div className="card-body">
                        <h5 className=" m-0 pb-3">Order Details</h5>
                        <Row className="p-0 m-0">
                          <Col lg={12} className="col-md-8 col-sm-12">
                            <Row className="g-4 px-0 py-4">
                              <div className="col-12">
                                <h5>Order Summary</h5>
                                <p className="m-0">
                                  Customer Mobile No. :{" "}
                                  <span className="fw-bold">
                                    +91-
                                    {orderdata && orderdata[0].user_mobile_no}
                                  </span>
                                </p>
                                <Row className="py-3">
                                  <Col xs={1}>
                                    <div className="text-end">
                                      <img
                                        alt=""
                                        src={pdficon}
                                        className="img-fluid w-50"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    xs={11}
                                    className="d-flex justify-content-between align-items-center"
                                  >
                                    <div>
                                      <p
                                        className="m-0"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {orderdata &&
                                          orderdata[0].order_print_type}{" "}
                                        Single Side{" "}
                                        {orderdata &&
                                          orderdata[0].order_orientation_type}
                                      </p>
                                      <p className="m-0 fs-xs text-secondary">
                                        {orderdata && orderdata[0].order_pages}{" "}
                                        pc x{" "}
                                        {orderdata &&
                                          orderdata[0].order_copies_count}
                                      </p>
                                    </div>
                                    <div>
                                      <h6 className="m0">
                                        ₹
                                        {orderdata &&
                                          orderdata[0].order_total_amount}
                                      </h6>
                                    </div>
                                  </Col>
                                </Row>
                                <div className="overflow-auto py-3">
                                  {dis3 === true ? (
                                    <>
                                      <h5>
                                        Order Status :{" "}
                                        <span className="rad">CANCELLED</span>
                                      </h5>
                                    </>
                                  ) : (
                                    <>
                                      <CustomizedSteppers
                                        activeStep={activeStep}
                                        steps={stepss}
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="py-3">
                                  <h6>Bill Details</h6>
                                  <hr className="my-2" />
                                  <Row>
                                    <div className="col-6">
                                      <p className="m-0">MRP</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="text-end m-0">₹{mrp}</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="m-0">Delivery Charge</p>
                                    </div>
                                    <div className="col-6">
                                      {orderdata &&
                                      orderdata[0].order_delivery_charges ===
                                        0 ? (
                                        <>
                                          <p className="text-end m-0 green">
                                            FREE
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="text-end m-0">
                                            ₹
                                            {orderdata &&
                                              orderdata[0]
                                                .order_delivery_charges}
                                          </p>
                                        </>
                                      )}
                                    </div>
                                    <div className="col-6">
                                      <p className="m-0">Handling Charge</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="text-end m-0">
                                        ₹
                                        {orderdata &&
                                          orderdata[0].order_handling_charges}
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p className="m-0 fw-bold">Bill Total</p>
                                    </div>
                                    <div className="col-6">
                                      <p className="text-end m-0 fw-bold">
                                        ₹
                                        {orderdata &&
                                          orderdata[0].order_total_amount}
                                      </p>
                                    </div>
                                  </Row>
                                </div>
                                {orderdata &&
                                orderdata[0].order_delivery_type === "STORE" ? (
                                  <>
                                    <div className="py-3">
                                      <h6>Vendor Details</h6>
                                      <hr className="my-2" />
                                      <Row className="g-2">
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Vendor Name
                                          </p>
                                          <p className=" m-0 ">
                                            {orderdata &&
                                              orderdata[0].vendor_name}
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Shop Name
                                          </p>
                                          <p className=" m-0 ">
                                            {orderdata &&
                                              orderdata[0].vendor_shop_name}
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Shop Address
                                          </p>
                                          <p className=" m-0 ">
                                            {orderdata &&
                                              orderdata[0].vendor_shop_address}
                                          </p>
                                        </div>
                                      </Row>
                                    </div>
                                    <div className="py-3">
                                      <h6>Order Details</h6>
                                      <hr className="my-2" />
                                      <Row className="g-2">
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Order Id
                                          </p>
                                          <p className=" m-0 ">
                                            #
                                            {orderdata && orderdata[0].order_id}
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Payment
                                          </p>
                                          <p className=" m-0 ">
                                            Paid Online{" "}
                                            <span className="ps-2 user-select-none">
                                              {" "}
                                              #
                                              {orderdata &&
                                                orderdata[0].order_payment_ref}
                                            </span>
                                          </p>
                                        </div>
                                      </Row>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="py-3">
                                      <h6>Order Details</h6>
                                      <hr className="my-2" />
                                      <Row className="g-2">
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Order Id
                                          </p>
                                          <p className=" m-0 ">
                                            #
                                            {orderdata && orderdata[0].order_id}
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Payment
                                          </p>
                                          <p className=" m-0 ">
                                            Paid Online{" "}
                                            <span className="ps-2 user-select-none">
                                              {" "}
                                              #
                                              {orderdata &&
                                                orderdata[0].order_payment_ref}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          <p className="text-secondary fs-xs m-0">
                                            Delivered To
                                          </p>
                                          <p className=" m-0 ">
                                            {addressData[0].address_line},{" "}
                                            {addressData[0].address_landmark},{" "}
                                            {addressData[0].address_pincode}
                                          </p>
                                        </div>
                                      </Row>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="w-100 bg-main px-0">
          <AdminSideNav />
          <Box className="p-0 ps-4 ps-md-5">
            <Container className="ps-5">
              <Row>
                <div className=" rounded-3">
                  <div className="card p-3 rounded-5 shadow">
                    <div className="card-body">
                      <Row>
                        <Col xs={12} className="d-flex">
                          <IconButton
                            aria-label="goback"
                            size="large"
                            color="warning"
                            onClick={() => navigate(-1)}
                          >
                            <KeyboardBackspaceIcon />
                          </IconButton>
                          <h5 className="m-0 mx-auto pb-3">
                            Payment History For {date && date}
                          </h5>
                        </Col>
                      </Row>
                      {payments && payments[0] === undefined ? (
                        <>
                          <div className="pt-3 pb-2">
                            <h6 className="m-0 text-center">
                              No History Found
                            </h6>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="overflow-auto pt-3">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th className="bg-light">
                                    Total Order Amount
                                  </th>
                                  <th className="bg-light">
                                    Total Gateway Charge
                                  </th>
                                  <th className="bg-light">
                                    Total Platform Charge
                                  </th>
                                  <th className="bg-light">
                                    Total Delivery Charges
                                  </th>
                                  <th className="bg-light">Total Payment</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>₹ {total && total.total_order_amount}</td>
                                  <td>
                                    ₹ {total && total.total_gateway_charge}
                                  </td>
                                  <td>
                                    ₹ {total && total.total_platform_charge}
                                  </td>
                                  <td>
                                    ₹ {total && total.total_delivery_charges}
                                  </td>
                                  <td>₹ {total && total.total_payment}</td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="pt-4 ">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th className="ps-3">Order Id</th>
                                    <th className="ps-3">Payment Id</th>
                                    <th className="text-end">Order Amount</th>
                                    <th className="text-end">Gateway Charge</th>
                                    <th className="text-end">
                                      Platform Charge
                                    </th>
                                    <th className="text-end">
                                      Delivery Charges
                                    </th>
                                    <th className="text-end">Total Payment</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payments &&
                                    payments.map((val, ind) => (
                                      <>
                                        <tr key={ind}>
                                          <td>{ind + 1}</td>
                                          <td>{val.order_id}</td>
                                          <td>
                                            {val.order_razorpay_payment_id}
                                          </td>
                                          <td className="text-end">
                                            ₹ {val.order_total_amount}
                                          </td>
                                          <td className="text-end">
                                            ₹ {val.gateway_charge}
                                          </td>
                                          <td className="text-end">
                                            ₹ {val.platform_charge}
                                          </td>
                                          <td className="text-end">
                                            ₹ {val.order_delivery_charges}
                                          </td>
                                          <td className="text-end">
                                            ₹ {val.total_payment}
                                          </td>
                                          <td>
                                            <RemoveRedEyeRoundedIcon
                                              onClick={() => {
                                                setModalShow(true);
                                                setid(val.order_id);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Box>
        </div>
      </ThemeProvider>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
export default AdminPaymentDetails;
