import React, { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import MiniDrawer from "../../component/MiniDrawer";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { FormControlLabel, Switch, Tooltip, Pagination } from "@mui/material";
import { toast } from "react-toastify";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";

const VendorOrders = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [selectResult, setSelectResult] = useState(10);
  const [len, setLen] = useState(0);
  const [chack, setChack] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("PLACED");

  const authToken = cookies.token;

  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/vendor/order/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrders(res.data.data);
          setFilteredOrder(res.data.data);
          setLen(res.data.len);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching order data: ", err);
      });
  };

  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
      status: selectedStatus,
      order_type: chack === true ? "10" : "0",
    };
    getOrderData(data);
  }, [selectResult, selectedStatus, chack]);

  useEffect(() => {
    if (orders.length > 0) {
      const resultPerPage = len / selectResult;
      const roundedValue = Math.ceil(resultPerPage);
      setPageNum(roundedValue);
    }
  }, [orders, selectResult, len]);

  const handlePageChange = (event, page) => {
    setActivePage(page);

    const data = {
      limit: selectResult,
      skip: selectResult * (page - 1),
      status: selectedStatus,
      order_type: chack === true ? "10" : "0",
    };
    getOrderData(data);
  };

  const handleSelectChange = (e) => {
    setSelectResult(parseInt(e.target.value, 10));

    const data = {
      limit: e.target.value,
      skip: e.target.value * (activePage - 1),
      status: selectedStatus,
      order_type: chack === true ? "10" : "0",
    };
    getOrderData(data);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <div className="w-100 bg-main px-0">
      <MiniDrawer />
      <Box className="px-0 ps-4 ps-md-5">
        <Container className="ps-5">
          <Row>
            <div className="rounded-3">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <h5 className="m-0 pb-3">Order List</h5>
                  <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-12 col-12">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={chack}
                            onChange={() => setChack(!chack)}
                          />
                        }
                        label="Priority Orders"
                      />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-12 col-12">
                      <div className="w-50 w-md-25">
                        <Form.Select
                          size="sm"
                          className="filter-select m-0 ms-auto w-100"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                        >
                          <option value="ALL">All Orders</option>
                          <option value="PLACED">Placed Orders</option>
                          <option value="PRINTING">Printing Orders</option>
                          <option value="PACKED">Packed Orders</option>
                          <option value="DISPATCHED">Dispatched Orders</option>
                          <option value="DELIVERED">Delivered Orders</option>
                          <option value="PICKEDUP">Pickedup Orders</option>
                          <option value="CANCELLED">Cancelled Orders</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                  {orders.length === 0 ? (
                    <h6 className="m-0 text-center pb-3">No orders Found</h6>
                  ) : (
                    <div className="overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="ps-3">Order Id</th>
                            <th>Order Amount</th>
                            <th>Order Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredOrder.map((order, index) => (
                            <tr key={index}>
                              <td>
                                {order.order_delivery_type === "HOME" ? (
                                  <Tooltip title="Home Delivery">
                                    <MapsHomeWorkRoundedIcon className="text-primary" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Pickup From Store">
                                    <StoreRoundedIcon className="text-success" />
                                  </Tooltip>
                                )}{" "}
                              </td>
                              <td className="ps-3">
                                #{order.order_id}{" "}
                                {order.order_delivery_charges === 10 && (
                                  <StarRateRoundedIcon className="text-primary" />
                                )}
                              </td>
                              <td>{order.order_total_amount}</td>
                              <td>{order.order_status}</td>
                              <td>
                                <Tooltip title="View Details">
                                  <VisibilityIcon
                                    onClick={() =>
                                      navigate(
                                        `/vendor/orders/details/${order.order_id}`
                                      )
                                    }
                                    className="fs-3"
                                  />
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {len === 0 ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="col-12 col-sm-3 px-2">
                      <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                        <label
                          htmlFor="select-input"
                          className="form-label fs-xs px-1"
                        >
                          Rows per page
                        </label>
                        <select
                          className="form-select w-50 form-select-sm px-1"
                          id="select-input"
                          onChange={handleSelectChange}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-9 overflow-auto">
                      <div className="text-end px-2">
                        <Pagination
                          count={pageNum}
                          page={activePage}
                          onChange={handlePageChange}
                          showFirstButton
                          showLastButton
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </div>
  );
};

export default VendorOrders;
