import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import printWeb from "../assets/images/print-web.png";

const Progressbar = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 99) {
          return 99;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 99);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="dark-bg">
        <div className="progress-bar-box">
          <div className="text-center py-5">
            <img src={printWeb} alt="" className="img-fluid w-25" />
          </div>
          <div className="text-center">
            <h6>Uploading your file on secure network</h6>
            <p className="text-secondary fs-xs">
              We delete your uploaded files once printed
            </p>
          </div>
          <div className="w-80 mx-auto py-2">
            <LinearProgress
              variant="determinate"
              value={progress}
              className="progress-line"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Progressbar;
