import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <hr />
          <p className=" fs-xs text-center text-secondary">
            &copy; {currentYear} Xeroxmart. Xerox is a Registered Trademark of
            Xerox Corporation. We are not affiliated with them.
          </p>
          <ul className="d-flex list-unstyled justify-content-center">
            <li
              className="px-2 px-md-3 pointer"
              onClick={() => navigate("/privacyandpolicy")}
            >
              Privacy & Policy
            </li>
            <li
              className="px-2 px-md-3 pointer"
              onClick={() => navigate("/terms")}
            >
              Terms and Conditions
            </li>
            <li
              className="px-2 px-md-3 pointer"
              onClick={() => navigate("/refund")}
            >
              Refund and Cancellations
            </li>
            <li
              className="px-2 px-md-3 pointer"
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </li>
            <li
              className="px-2 px-md-3 pointer"
              onClick={() =>
                window.open(
                  "https://merchant.razorpay.com/policy/OXj5B4ucQpyUWV/shipping",
                  "_blank"
                )
              }
            >
              Shipping and Delivery
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
