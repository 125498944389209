import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import logo from "../assets/images/logo.png";
import ReactGA from "react-ga4";

const PrivacyandPolicy = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/privacyandpolicy",
    title: "Privacy & Policy page",
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="container shadow px-md-5">
        <div className="mt-5 pt-2">
          <IconButton aria-label="Go Back" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
        <div className="mx-auto text-center">
          <img src={logo} alt="hero" className="img-fluid w-25 mx-auto" />
        </div>
        <h3 className="text-center pt-4"> Privacy & Policy </h3>
        <p className="pt-3 text-secondary">Effective Date: 15/July/2024</p>
        <p className="pt-2 fs-xs">
          Welcome to Xeroxmart. Your privacy is important to us. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you visit our website and use our services. Please
          read this Privacy Policy carefully. If you do not agree with the terms
          of this privacy policy, please do not access the site.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">1. Information We Collect </b>
          <br /> We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>
        <p className="pt-1 fs-xs">
          {" "}
          <span className="fs-6 fw-bold"> Personal Data </span> <br />{" "}
          Personally identifiable information, such as your name, shipping
          address, and telephone number, and demographic information, that you
          voluntarily give to us when you register with the Site or our mobile
          application, or when you choose to participate in various activities
          related to the Site and our mobile application, such as online chat
          and message boards. You are under no obligation to provide us with
          personal information of any kind, however, your refusal to do so may
          prevent you from using certain features of the Site and our mobile
          application.
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Derivative Data </span> <br />{" "}
          Information our servers automatically collect when you access the
          Site, such as your IP address, your browser type, your operating
          system, your access times, and the pages you have viewed directly
          before and after accessing the Site.
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Financial Data </span> <br /> Financial
          information, such as data related to your payment method (e.g., valid
          credit card number, card brand, expiration date) that we may collect
          when you purchase, order, or request information about our services
          from the Site. We store only very limited, if any, financial
          information that we collect. Otherwise, all financial information is
          stored by our payment processor, Razorpay, and you are encouraged to
          review their privacy policy and contact them directly for responses to
          your questions.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">2. Use of Your Information</b> <br /> Having
          accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Site or our mobile application
          to:
        </p>
        <p className="pt-1 fs-xs">
          <ul>
            <li>Create and manage your account.</li>
            <li>
              Process your transactions and deliver the services you request.
            </li>
            <li>Improve customer service.</li>
            <li>
              Request your feedback and contact you about your use of the Site.
              Resolve disputes and troubleshoot problems.
            </li>
            <li>Respond to product and customer service requests.</li>
            <li>Administer promotions, surveys, and other site features.</li>
          </ul>
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">3. Disclosure of Your Information</b> <br /> We
          may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">By Law or to Protect Rights</span>{" "}
          <br /> If we believe the release of information about you is necessary
          to respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation.
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Third-Party Service Providers</span>{" "}
          <br /> We may share your information with third parties that perform
          services for us or on our behalf, including payment processing, data
          analysis, customer service, and marketing assistance.
        </p>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Business Transfers</span> <br /> We may
          share or transfer your information in connection with, or during
          negotiations of, any merger, sale of company assets, financing, or
          acquisition of all or a portion of our business to another company.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">4. Security of Your Information</b> <br /> We use
          administrative, technical, and physical security measures to help
          protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">5. Policy for Children</b> <br /> We do not
          knowingly solicit information from or market to children under the age
          of 13. If we learn that we have collected personal information from a
          child under age 13 without verification of parental consent, we will
          delete that information as quickly as possible. If you believe we
          might have any information from or about a child under 13, please
          contact us at contact@xeroxmart.shop.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">6. Changes to This Privacy Policy</b> <br /> We
          may update this Privacy Policy from time to time in order to reflect,
          for example, changes to our practices or for other operational, legal,
          or regulatory reasons.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">7. Contact Us</b> <br /> If you have questions or
          comments about this Privacy Policy, please contact us at:
        </p>
        <p className="pt-1 fs-xs">
          <span className="fw-bold fs-6">Xeroxmart</span> <br /> 104, Crystal
          Plaza, Punagam, Surat - 395010 <br /> Email: contact@xeroxmart.shop{" "}
          <br /> Phone: +919510901080
        </p>
        <br />
      </div>
      <br />
    </>
  );
};

export default PrivacyandPolicy;
