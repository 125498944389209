import React, { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import AdminSideNav from "../../component/AdminSideNav";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";

const Adminorders = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const token = cookies.token;
  const [orders, setOrders] = useState([]);
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [selectResult, setSelectResult] = useState(10);
  const [len, setLen] = useState(0);
  const [chack, setChack] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("PLACED");
  const [selectVendor, setSelectVendor] = useState({
    value: "ALL",
    label: "ALL",
  });
  const [vendorId, setVendorId] = useState();

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/order/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrders(res.data.data);
          setFilteredOrder(res.data.data);
          setLen(res.data.len);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("Error fetching order data: ", err);
      });
  };

  const getVendorId = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/list`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setVendorId(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
      status: selectedStatus,
      vendor: selectVendor.value,
      order_type: chack === true ? "10" : "0",
    };
    getOrderData(data);
    getVendorId();
  }, [selectResult, selectedStatus, selectVendor, chack]);

  useEffect(() => {
    if (orders.length > 0) {
      const resultPerPage = len / selectResult;
      const roundedValue = Math.ceil(resultPerPage);
      setPageNum(roundedValue);
    }
  }, [orders, selectResult, len]);

  const handleClickPage = (event, number) => {
    setActivePage(number);
    if (token) {
      const data = {
        limit: selectResult,
        skip: selectResult * (number - 1),
        status: selectedStatus,
        vendor: selectVendor.value,
        order_type: chack === true ? "10" : "0",
      };
      getOrderData(data);
    }
  };

  const handlePageChange = (e) => {
    setSelectResult(e.target.value);
    if (token) {
      const data = {
        limit: e.target.value,
        skip: e.target.value * (activePage - 1),
        status: selectedStatus,
        vendor: selectVendor.value,
        order_type: chack === true ? "10" : "0",
      };
      getOrderData(data);
    }
  };

  useEffect(() => {
    const data = {
      limit: selectResult,
      skip: 0,
      status: selectedStatus,
      vendor: selectVendor.value,
      order_type: chack === true ? "10" : "0",
    };
    getOrderData(data);
  }, [chack]);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleChangeVendor = (e) => {
    setSelectVendor(e);
  };

  let options = [{ value: "ALL", label: "ALL" }];

  vendorId &&
    vendorId.forEach((element) => {
      const temp = `${element.vendor_id} - ${element.vendor_name}`;
      options.push({ value: element.vendor_id, label: temp });
    });

  return (
    <Box sx={{ display: "flex" }} className="bg-main px-0">
      <AdminSideNav />
      <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
        <Container>
          <Row>
            <div className="py-5 pe-5 rounded-3 my-4 overflow-auto">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <h5 className="m-0 pb-3">Order List</h5>
                  <div className="d-flex">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={chack}
                          onChange={() => setChack(!chack)}
                        />
                      }
                      label={chack === true ? "Priority Orders" : "All Orders"}
                    />
                    <Form.Select
                      size="sm"
                      className="filter-select1 mx-2"
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    >
                      <option value="ALL">All Orders</option>
                      <option value="PLACED">Placed Orders</option>
                      <option value="PRINTING">Printing Orders</option>
                      <option value="PACKED">Packed Orders</option>
                      <option value="DISPATCHED">Dispatched Orders</option>
                      <option value="DELIVERED">Delivered Orders</option>
                      <option value="PICKEDUP">Pickedup Orders</option>
                      <option value="CANCELLED">Cancelled Orders</option>
                    </Form.Select>

                    <Select
                      className="mx-2 w-25 text-start"
                      value={selectVendor}
                      onChange={(e) => handleChangeVendor(e)}
                      options={options}
                    />
                  </div>
                  {len === 0 ? (
                    <p className="text-center pt-3">No Order Found</p>
                  ) : (
                    <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="ps-3">Order Id</th>
                            <th>Vendor Name</th>
                            <th>Order Amount</th>
                            <th>Order Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredOrder.map((order, index) => (
                            <tr key={index}>
                              <td>
                                {order.order_delivery_type === "HOME" ? (
                                  <Tooltip title="Home Delivery">
                                    <MapsHomeWorkRoundedIcon className="text-primary" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Pickup From Store">
                                    <StoreRoundedIcon className="text-success" />
                                  </Tooltip>
                                )}{" "}
                                {index + 1 + selectResult * (activePage - 1)}
                              </td>
                              <td className="ps-3">
                                #{order.order_id}{" "}
                                {order.order_delivery_charges === 10 && (
                                  <StarRateRoundedIcon className="text-primary" />
                                )}
                              </td>
                              <td>{order.vendor_name}</td>
                              <td>{order.order_total_amount}</td>
                              <td>{order.order_status}</td>
                              <td>
                                <Tooltip title="View Details">
                                  <VisibilityIcon
                                    onClick={() =>
                                      navigate(
                                        `/admin/orders/details/${order.order_id}`
                                      )
                                    }
                                    className="fs-3"
                                  />
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                {len > 0 && (
                  <div className="row">
                    <div className="col-12 col-sm-3 px-2">
                      <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                        <label
                          htmlFor="select-input"
                          className="form-label fs-xs px-1"
                        >
                          Rows per page
                        </label>
                        <select
                          className="form-select w-50 form-select-sm px-1"
                          id="select-input"
                          onChange={(e) => handlePageChange(e)}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-9 overflow-auto">
                      <div className="text-end px-2">
                        <Pagination
                          count={pageNum}
                          page={activePage}
                          onChange={handleClickPage}
                          showFirstButton
                          showLastButton
                        />
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};

export default Adminorders;
