import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import logo from "../assets/images/logo.png";
import ReactGA from "react-ga4";

const Refund = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/refund",
    title: "Refund and Cancellations page",
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="container shadow px-0 px-md-5">
        <div className="mt-5 pt-2">
          <IconButton aria-label="Go Back" onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
        <div className="mx-auto text-center">
          <img src={logo} alt="hero" className="img-fluid w-25 mx-auto" />
        </div>
        <h3 className="text-center pt-4"> Refund and Cancellations </h3>
        <p className="pt-3 text-secondary">Effective Date: 15/July/2024</p>
        <p className="pt-2 fs-xs">
          At Xeroxmart, we strive to provide the highest quality xerox printing
          services to our customers. We understand that there may be times when
          you need to cancel an order or request a refund. Please review our
          policy below to understand how we handle these situations.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">1. Cancellation Policy</b> <br /> Order
          Cancellation by Customer
        </p>
        <ul>
          <li>
            <span className=" fw-bold">Before Processing: </span>If you wish to
            cancel your order, you must do so before we start processing it. To
            cancel an order, please contact our customer service team
            immediately at [Insert Contact Information]. If the cancellation
            request is received before processing begins, we will cancel your
            order and issue a full refund.
          </li>
          <li>
            {" "}
            <span className=" fw-bold">After Processing: </span> Once an order
            has been processed or printed, it cannot be canceled. In such cases,
            you may still be eligible for a refund under certain conditions (see
            Refund Policy below).
          </li>
        </ul>
        <p>
          <span className="fs-6 fw-bold">Order Cancellation by Xeroxmart</span>
          Xeroxmart reserves the right to cancel any order at our discretion.
          Reasons for cancellation may include but are not limited to, issues
          with the submitted documents, payment issues, or unforeseen
          operational challenges. If we cancel your order, you will receive a
          full refund.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">2. Refund Policy</b> <br />
          <span className="fs-6 fw-bold"> Eligibility for Refunds</span>
          <br /> We offer refunds under the following conditions:
        </p>
        <ul>
          <li>
            {" "}
            <span className=" fw-bold"> Defective Prints: </span>
            If the printed materials are defective or of poor quality (e.g.,
            smudged ink, incorrect formatting), you may request a refund or a
            reprint. Please contact us within 7 days of receiving your order.
          </li>
          <li>
            {" "}
            <span className=" fw-bold"> Incorrect Order:Incorrect Order: </span>
            If you receive an order that does not match the specifications you
            provided, please contact us within 7 days of receiving your order.
            We will verify the issue and, if confirmed, issue a refund or
            reprint the order at no additional cost.
          </li>
        </ul>
        <p>
          <span className="fs-6 fw-bold">Non-Refundable Situations:</span>
          <br />
          We do not offer refunds in the following situations:
        </p>
        <ul>
          <li>
            <span className=" fw-bold"> Customer Errors: </span>
            Errors in the content provided by the customer (e.g., typos,
            incorrect information) are not eligible for refunds.
          </li>
          <li>
            <span className=" fw-bold">Late Cancellations: </span>
            Orders that have already been processed or printed cannot be
            canceled or refunded, except in cases of defects or incorrect orders
            as described above.
          </li>
          <li>
            <span className="fw-bold"> Change of Mind: </span>
            We do not offer refunds for orders canceled due to a change of mind
            once the order has been processed or printed.
          </li>
        </ul>
        <p className="pt-1 fs-xs">
          <span className="fs-6 fw-bold">Refund Process</span>
          <br /> To request a refund, please contact our customer service team
          at support@xeroxmart.shop with your order details and the reason for
          the refund request. We may ask for photographic evidence of any
          defects or issues. Once your request is reviewed and approved, we will
          process your refund. Refunds will be issued to the original payment
          method within 7-10 business days.
        </p>
        <p className="pt-1 fs-xs">
          <b className="fs-5">3. Contact Information</b> <br /> For any
          questions or concerns regarding cancellations or refunds, please
          contact us at:
        </p>

        <p className="pt-1 fs-xs">
          <span className="fw-bold fs-6">Xeroxmart</span> <br /> 104, Crystal
          Plaza, Punagam, Surat - 395010 <br /> Email: contact@xeroxmart.shop{" "}
          <br /> Phone: +919510901080
        </p>
        <br />
      </div>
      <br />
    </>
  );
};

export default Refund;
