import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import logo from "../assets/images/logo.png";
import ReactGA from "react-ga4";

const Contactus = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/contact",
    title: "Contact Us page",
  });

  const navigate = useNavigate();
  return (
    <>
      <div className="center-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-8 col-10 p-2 card mx-auto">
              <div>
                <IconButton aria-label="Go Back" onClick={() => navigate(-1)}>
                  <KeyboardBackspaceIcon />
                </IconButton>
              </div>
              <div className="mx-auto text-center">
                <img src={logo} alt="hero" className="img-fluid w-50 mx-auto" />
              </div>
              <div className="px-3 px-md-5 pb-3 py-md-5">
                <h3 className="text-center"> Contact Us </h3>
                <p className="m-0 fs-xs text-center">
                  We at Xeroxmart are here to assist you with any questions,
                  concerns, or feedback you may have regarding our on-demand
                  xerox printing services. Feel free to reach out to us through
                  any of the following methods:
                </p>

                <h4 className="pt-4 text-center">Customer Service</h4>
                <p>
                  <span className="fw-bold">Email:</span> <br /> For general
                  inquiries, order status, and support, please email us at:{" "}
                  support@xeroxmart.shop
                </p>

                <p>
                  <span className="fw-bold"> Phone:</span> <br /> You can reach
                  our customer service team by calling: +919510901080 Our
                  customer service hours are 10AM-6PM IST.
                </p>

                <p>
                  <span className="fw-bold"> Mailing Address</span> <br /> If
                  you prefer to contact us by mail, please send your
                  correspondence to: <br />
                  <span className="fw-bold fs-6">Xeroxmart</span> <br /> 104,
                  Crystal Plaza, <br /> Punagam <br />
                  Surat, Gujarat, <br /> India - 395010.
                </p>
                <p className="m-0">
                  <span className="fw-bold"> Feedback and Suggestions</span>{" "}
                  <br /> We value your feedback and suggestions. If you have any
                  ideas on how we can improve our services, please let us know.
                  You can email your feedback to: feedback@xeroxmart.shop
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
