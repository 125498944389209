import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import DeliveryDiningRoundedIcon from "@mui/icons-material/DeliveryDiningRounded";
import PrintDisabledRoundedIcon from "@mui/icons-material/PrintDisabledRounded";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#5b68a6",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#5b68a6",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#5b68a6",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#5b68a6",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-icon": {
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">{icon}</div>
      ) : (
        <div className="QontoStepIcon-icon">{icon}</div>
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

// const steps = ["PLACED", "PRINTING", "PACKED", "DISPATCHED", "DELIVERED"];

const icons = {};

export default function CustomizedSteppers({ activeStep, steps }) {
  if (steps.length === 4) {
    Object.assign(icons, {
      0: <ShoppingCartCheckoutIcon />,
      1: <PrintRoundedIcon />,
      2: <ShoppingBasketRoundedIcon />,
      3: <AssignmentTurnedInRoundedIcon />,
    });
  } else {
    if (steps.length === 2) {
      Object.assign(icons, {
        0: <ShoppingCartCheckoutIcon />,
        1: <PrintDisabledRoundedIcon />,
      });
    } else {
      Object.assign(icons, {
        0: <ShoppingCartCheckoutIcon />,
        1: <PrintRoundedIcon />,
        2: <ShoppingBasketRoundedIcon />,
        3: <DeliveryDiningRoundedIcon />,
        4: <AssignmentTurnedInRoundedIcon />,
      });
    }
  }

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <QontoStepIcon {...props} icon={icons[index]} />
              )}
            >
              <p className="m-0">{label.step}</p>
              <p className="m-0 fs-xs text-secondary">{label.date}</p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

CustomizedSteppers.propTypes = {
  activeStep: PropTypes.number.isRequired,
};
