import React, { useEffect, useState } from "react";
import { Col, Container, Form, Pagination, Row } from "react-bootstrap";
import axios from "axios";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import AdminSideNav from "../../component/AdminSideNav";
import { Button, Tooltip } from "@mui/material";
import { useCookies } from "react-cookie";
import Select from "react-select";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { useNavigate } from "react-router-dom";

const AdminPayment = () => {
  const [cookies, ,] = useCookies([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const [len, setlen] = useState(0);
  const [vendorId, setvendorId] = useState();
  const [selectVendor, setselectVendor] = useState("ALL");
  const [sDate, setsDate] = useState();
  const [eDate, seteDate] = useState();

  const authToken = cookies.token;
  const config = {
    headers: {
      "X-AUTH": authToken,
    },
  };

  useEffect(() => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];
    const end = new Date().toISOString().split("T")[0];

    setsDate(start);
    seteDate(end);
  }, []);

  const getOrderData = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/payments/list`,
        data,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setOrders(res.data.data);
          setlen(res.data.len);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const getVendorId = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/admin/vendor/list`,
        "",
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setvendorId(res.data.data);
        }
        if (res.data.status === "error") {
          toast.error(res.data.message);
        }
      });
  };

  const exportPaymentHistory = () => {
    axios({
      url: `${process.env.REACT_APP_BACKEND_API}/admin/payment/history/export`,
      method: "POST",
      headers: {
        "X-AUTH": authToken,
      },
      data: { sDate, eDate },
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "xeroxmart_payment_history.zip");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error("Error exporting payment history.");
        console.error(error);
      });
  };

  useEffect(() => {
    if (sDate && eDate) {
      const data = {
        vendor: selectVendor,
        sDate,
        eDate,
      };
      getOrderData(data);
      getVendorId();
    }
  }, [sDate, eDate]);

  const handleChangeVendor = (e) => {
    setselectVendor(e);
    const data = {
      vendor: e.value,
      sDate,
      eDate,
    };
    getOrderData(data);
  };

  const startDate = (e) => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);

    var start = currentDate.toISOString().split("T")[0];

    if (e.target.value > new Date().toISOString().split("T")[0]) {
      setsDate(start);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value > eDate) {
      seteDate(start);
      toast.error("Start date should be before end date");
      return;
    }
    setsDate(e.target.value);
  };

  const endDate = (e) => {
    const end = new Date().toISOString().split("T")[0];
    if (e.target.value > end) {
      seteDate(end);
      toast.error("Future date not allowed");
      return;
    }
    if (e.target.value < sDate) {
      seteDate(end);
      toast.error("End date should be after start date");
      return;
    }
    seteDate(e.target.value);
  };

  const handelDateSelect = (e) => {
    e.preventDefault();
  };

  let options = [{ value: "ALL", label: "ALL" }];

  vendorId &&
    vendorId.forEach((element) => {
      const temp = `${element.vendor_id} ${element.vendor_name}`;
      options.push({ value: element.vendor_id, label: temp });
    });

  return (
    <Box sx={{ display: "flex" }} className="bg-main px-0">
      <AdminSideNav />
      <Box component="main" sx={{ flexGrow: 1 }} className="px-0 py-3">
        <Container>
          <Row>
            <div className="py-5 pe-5 mih-100 rounded-3 my-4 overflow-auto">
              <div className="card p-3 rounded-5 shadow">
                <div className="card-body">
                  <Row>
                    <Col lg={6}>
                      <h5 className="m-0 pb-3">Payment History</h5>
                    </Col>
                    <Col lg={6}>
                      <div className="text-end d-flex">
                        <Select
                          className=" me-2 ms-auto w-25 text-start"
                          value={selectVendor}
                          onChange={(e) => handleChangeVendor(e)}
                          options={options}
                        />

                        <Button
                          onClick={exportPaymentHistory}
                          className="text-white bg-primary px-3 py-2 rounded-1 shadow ms-2"
                        >
                          Export Payment History
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="w-100 text-center mb-3">
                    <form
                    // onSubmit={(e) => handelDateSelect(e)}
                    >
                      <p>Select Date Range</p>
                      <input
                        type="date"
                        name="startDate"
                        value={sDate || ""}
                        className="daterangePick w-100 w-md-25"
                        onChange={(e) => startDate(e)}
                        id=""
                      />

                      <input
                        type="date"
                        name="endDare"
                        value={eDate || ""}
                        className="daterangePick w-100 w-md-25"
                        onChange={(e) => endDate(e)}
                        id=""
                      />
                    </form>
                  </div>

                  {orders[0] === undefined ? (
                    <h6 className="m-0 text-center pt-2">No History Found</h6>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="ps-3">Date</th>
                          <th className="text-end">Order Amount</th>
                          <th className="text-end">Gateway Charge</th>
                          <th className="text-end">Platform Charge</th>
                          <th className="text-end">Delivery Charges</th>
                          <th className="text-end">Total Payment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((val, ind) => (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td className="ps-3">{val.date}</td>
                            <td className="text-end">₹ {val.order_price}</td>
                            <td className="text-end">₹ {val.gatewayCharge}</td>
                            <td className="text-end">₹ {val.platformCharge}</td>
                            <td className="text-end">
                              ₹ {val.deliveryCharges}
                            </td>
                            <td className="text-end">₹ {val.total_payment}</td>
                            <td>
                              <Tooltip title="View Details">
                                <RemoveRedEyeRoundedIcon
                                  onClick={() =>
                                    navigate(`/admin/payments/${val.date}`)
                                  }
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminPayment;
